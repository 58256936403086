import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchType,
    updateType
} from '../../../actions/product-types'

const Form = React.lazy(() => import('../../forms/TypeEditForm'))

class DashboardTypeEdit extends React.Component {

    componentDidMount(){
        this.props.fetchType(this.props.token, this.props.match.params.typeId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateType(this.props.token, this.props.match.params.typeId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/types" />
        }

        return(
            <DashboardLayout 
                heading="Edit Type" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/types">Types</Link> / {this.props.match.params.typeId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/types" className='_button _button-3'>Back</Link>
                </div>

                {
                    this.props.initialValues && 
                    <Suspense fallback={<></>}>
                        <Form 
                            onSubmit={this.onSubmit} 
                            errors={this.props.data.errors ? this.props.data.errors : null} 
                            success={this.props.data.success ? this.props.data.success : null}
                            initialValues={this.props.initialValues}
                        />
                    </Suspense>
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.typesState[ownProps.match.params.typeId],
        data: state.typeUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchType,
    updateType,
    resetForms
})(DashboardTypeEdit)