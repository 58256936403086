import React from 'react'
import ReactDOM from 'react-dom'

class Popup extends React.Component {

  render(){
    return(
      ReactDOM.createPortal(
        <div className={`_popup ${this.props.show ? '' : '_hide'}`}>
          <div className='_inner'>
            <div className='_flex'>
              <button className='_close-popup-button' onClick={this.props.onClick}>
                <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" fill="#82828a"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
              </button>
            </div>
            {this.props.heading && (
              <>
                <h3 className='_page-heading'>{this.props.heading}</h3>
                <div className='_border' />
              </>
            )}
            {this.props.children}
          </div>
          <div className='_overlay' onClick={this.props.onClick} />
        </div>,
        document.querySelector("#popup")
      )
    )
  }
}

export default Popup