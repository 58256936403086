import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "./Filter";

const Table = props => {

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const customStyles = {
    headCells: {    
        style: {    
            color: '#306191',    
            fontSize: '12px',
            fontWeight: '600'    
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',   
            outline: '1px solid #FFFFFF',   
        },   
    },    
    pagination: {
        style: {
            border: 'none',
        },
    },
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={props.columns}
      data={filteredItems}
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      customStyles={customStyles}
    />
  );
};

export default Table;
