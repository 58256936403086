import {Link} from 'react-router-dom'

export const ViewTermsLink = () => {
  return (
    <p className='_view-terms'>View <Link to="/terms-and-conditions" style={{textDecoration: 'underline'}}><strong>Terms & Conditions</strong></Link>.</p>
  )
}

export const AcceptTermsLink = () => {
  return (
    <span className='_accept-terms'>"Accept <Link to="/terms-and-conditions" style={{textDecoration: 'underline'}}><strong>Terms & Conditions</strong></Link>.*"</span>
  )
}

export const ViewShippingPolicyLink = () => {
  return (
    <p className='_view-terms'>View <Link to="/shipping-policy" style={{textDecoration: 'underline'}}><strong>Shipping Policy</strong></Link>.</p>
  )
}