import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchBrand,
    updateBrand
} from '../../../actions/brands'

const Form = React.lazy(() => import('../../forms/BrandEditForm'))

class DashboardBrandEdit extends React.Component {

    componentDidMount(){
        this.props.fetchBrand(this.props.token, this.props.match.params.brandId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateBrand(this.props.token, this.props.match.params.brandId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/brands" />
        }

        return(
            <DashboardLayout 
                heading="Edit Brand" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/brands">Brands</Link> / {this.props.match.params.brandId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/brands" className='_button _button-3'>Back</Link>
                </div>

                {
                    this.props.initialValues && 
                    <Suspense fallback={<></>}>
                        <Form 
                            onSubmit={this.onSubmit} 
                            errors={this.props.data.errors ? this.props.data.errors : null} 
                            success={this.props.data.success ? this.props.data.success : null}
                            initialValues={this.props.initialValues}
                        />
                    </Suspense>
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.brandsState[ownProps.match.params.brandId],
        data: state.brandUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchBrand,
    updateBrand,
    resetForms
})(DashboardBrandEdit)