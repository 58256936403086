import React from 'react'
import Footer from './Footer'
import Header from './Header'
import MobileMenu from './MobileMenu'
import Cookie from '../ui/Cookie'
import Loader from '../ui/Loader'
import {
    clearCartIfExpired
} from '../../utils'

class Layout extends React.Component {

    state = {
        menuOpen: false,
        loading: true
    }

    componentDidMount(){
        clearCartIfExpired()
        setTimeout(() => {
            this.setState({loading: false})
        }, 500);

    }

    onMenuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    render(){

        return(
            <>
                <Loader loading={this.state.loading} />
                <Header onMenuToggle={this.onMenuToggle} menuOpen={this.state.menuOpen} />
                <MobileMenu menuOpen={this.state.menuOpen} onMenuToggle={this.onMenuToggle} />
                <div className='_page-content' id="page-content">
                    {this.props.children}
                </div>
                <Footer />
                <Cookie />
            </>
        )
    }
}

export default Layout