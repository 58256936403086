import {
  UPDATE_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS_FAILES,
  RESET_FORMS
} from '../../actions/types'

const initialState = {}

const accountUpdateReducer = (state = initialState, action) => {
  switch(action.type){
      case UPDATE_ACCOUNT_DETAILS:
          return {
            details: action.payload,
            success: 'Account updated successfully',
          }
      case UPDATE_ACCOUNT_DETAILS_FAILES:
          return {
              ...state,
              errors: action.payload,
              success: null
          }
      case RESET_FORMS:
          return initialState
      default:
          return state
  }
}

export default accountUpdateReducer