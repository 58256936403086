import {
    BOOKING,
    BOOKING_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOKING:
            return {
                success: action.payload
            }
        case BOOKING_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default bookingReducer