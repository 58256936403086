import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchProduct,
    updateProduct,
    deleteProductVideo
} from '../../../actions/products'
import { fetchBrands } from '../../../actions/brands'
import { fetchCategories } from '../../../actions/categories'
import { fetchTypes } from '../../../actions/product-types'
import { fetchDeliveryOptions } from '../../../actions/delivery-options'

const Form = React.lazy(() => import('../../forms/ProductEditForm'))

class DashboardProductEdit extends React.Component {

    componentDidMount(){
        this.props.fetchProduct(this.props.token, this.props.match.params.productId)
        this.props.fetchBrands(this.props.token)
        this.props.fetchCategories(this.props.token)
        this.props.fetchTypes(this.props.token)
        this.props.fetchDeliveryOptions(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateProduct(this.props.token, this.props.match.params.productId, formValues)
    }

    onDeleteVideo = () => {
        this.props.deleteProductVideo(this.props.token, this.props.match.params.productId)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/products" />
        }

        return(
            <DashboardLayout 
                heading="Edit Product" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/products">Products</Link> / {this.props.match.params.productId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/products" className='_button _button-3'>Back</Link>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/variations`} className='_button _button-2'>Variations</Link>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/images`} className='_button _button-2'>Images</Link>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/documents`} className='_button _button-2'>Documents</Link>
                </div>

                {
                    this.props.initialValues && this.props.types &&
                    <Suspense fallback={<></>}>
                        <Form 
                            onSubmit={this.onSubmit} 
                            onDeleteVideo={this.onDeleteVideo}
                            errors={this.props.data.errors ? this.props.data.errors : null} 
                            success={this.props.data.success ? this.props.data.success : null}
                            initialValues={this.props.initialValues}
                            brands={Object.values(this.props.brands)}
                            categories={Object.values(this.props.categories)}
                            types={Object.values(this.props.types)}
                            deliveryOptions={Object.values(this.props.deliveryOptions)}
                        />
                    </Suspense>
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.productsState[ownProps.match.params.productId],
        data: state.productUpdateState,
        brands: state.brandsState,
        categories: state.categoriesState,
        types: state.typesState,
        deliveryOptions: state.deliveryOptionsState
    }
}

export default connect(mapStateToProps, {
    fetchProduct,
    updateProduct,
    fetchBrands,
    fetchCategories,
    fetchTypes,
    fetchDeliveryOptions,
    deleteProductVideo,
    resetForms
})(DashboardProductEdit)