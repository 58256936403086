import admin from '../apis/admin'

import {
  FETCH_CATEGORIES,
  CREATE_CATEGORY,
  CREATE_CATEGORY_FAILED,
  FETCH_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAILED,
  DELETE_CATEGORY,
  RESTORE_CATEGORY,
  FETCH_CATEGORIES_BIN,
  FORCE_DELETE_CATEGORY,
} from './types'

export const fetchCategories = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/categories', config)

      dispatch({
          type: FETCH_CATEGORIES,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch categories failed')
  }
}

export const createCategory = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/categories/create', formValues, config)

      dispatch({
          type: CREATE_CATEGORY,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_CATEGORY_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchCategory = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/categories/' + id, config)

      dispatch({
          type: FETCH_CATEGORY,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch category failed')
  }
}

export const updateCategory = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/categories/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_CATEGORY,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_CATEGORY_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteCategory = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/categories/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_CATEGORY,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete category failed')        
  }
}

export const fetchCategoriesBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/categories/bin', config)

      dispatch({
          type: FETCH_CATEGORIES_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch categories bin failed')
  }
}

export const restoreCategory = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/categories/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_CATEGORY,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Category restore failed')
  }
}

export const forceDeleteCategory = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/categories/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_CATEGORY,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete category failed')        
  }
}