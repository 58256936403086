import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    createDeliveryOption
} from '../../../actions/delivery-options'
import Form from '../../forms/DeliveryOptionCreateForm'

class DashboardDeliveryOptionCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createDeliveryOption(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/delivery-options" />
        }

        return(
            <DashboardLayout 
                heading="Create Delivery Option" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/delivery-options">Delivery Options</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/delivery-options" className='_button _button-3'>Back</Link>
                </div>

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.deliveryOptionCreateState
    }
}

export default connect(mapStateToProps, {
    createDeliveryOption,
    resetForms
})(DashboardDeliveryOptionCreate)