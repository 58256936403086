import admin from '../apis/admin'

import {
  FETCH_IMAGES,
  CREATE_IMAGE,
  CREATE_IMAGE_FAILED,
  FETCH_IMAGE,
  UPDATE_IMAGE,
  UPDATE_IMAGE_FAILED,
  DELETE_IMAGE,
  RESTORE_IMAGE,
  FETCH_IMAGES_BIN,
  FORCE_DELETE_IMAGE,
} from './types'

export const fetchImages = (token, productId) => async dispatch => {
    
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/images`, config)

      dispatch({
          type: FETCH_IMAGES,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch images failed')
  }
}

export const createImage = (token, productId, formValues) => async dispatch => {
  
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/images/create`, formValues, config)

      dispatch({
          type: CREATE_IMAGE,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_IMAGE_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchImage = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/images/${id}`, config)

      dispatch({
          type: FETCH_IMAGE,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Fetch image failed')
  }
}

export const updateImage = (token,  productId, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/images/${id}/update`, formValues, config)

      dispatch({
          type: UPDATE_IMAGE,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_IMAGE_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteImage = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/images/${id}/delete`, {}, config)

      dispatch({
          type: DELETE_IMAGE,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Delete image failed')        
  }
}

export const fetchImagesBin = (token, productId) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/images/bin`, config)

      dispatch({
          type: FETCH_IMAGES_BIN,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch images bin failed')
  }
}

export const restoreImage = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/images/${id}/restore`, {}, config)

      dispatch({
          type: RESTORE_IMAGE,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Image restore failed')
  }
}

export const forceDeleteImage = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/images/${id}/delete`, {}, config)

      dispatch({
          type: FORCE_DELETE_IMAGE,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Force delete image failed')        
  }
}