import {
  FETCH_CUSTOMER_ACCOUNT_DETAILS,
  CUSTOMER_UPDATE,
  CUSTOMER_LOGOUT
} from '../../actions/types'

const initialState = {
  details: null
}

const customerAccountReducer = (state = initialState, action) => {
  switch(action.type){
      case FETCH_CUSTOMER_ACCOUNT_DETAILS:
          return {
              details: action.payload
          }
        case CUSTOMER_UPDATE:
          return {
              details: action.payload
          }
      case CUSTOMER_LOGOUT:
        return initialState
      default:
          return state
  }
}

export default customerAccountReducer