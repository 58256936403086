import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchCoupons,
    deleteCoupon
} from '../../../actions/coupons'
import CustomDataTable from '../ui/CustomDataTable'
import {readableDate} from '../../../utils'

class DashboardCoupons extends React.Component {

    componentDidMount(){
        this.props.fetchCoupons(this.props.token)
    }

    onDelete = id => {
        this.props.deleteCoupon(this.props.token, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/coupons/${id}/edit`} >Edit</Link>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Archive</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Code',
                selector: row => row.code,
                sortable: true,
            },
            {
                name: 'Type',
                selector: row => row.type,
                sortable: true,
            },
            {
                name: 'Fixed Amount',
                selector: row => row.value,
                sortable: true,
            },
            {
                name: 'Percentage Off',
                selector: row => row.percent_off,
                sortable: true,
            },
            {
                name: 'Expiry Date',
                selector: row => readableDate(row.expires),
                sortable: true,
            },
            {
                name: 'Validity',
                selector: row => row.instore && row.instore === 'yes' ? 'In Store' : 'Online',
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        return(
            <DashboardLayout 
                heading="Coupons" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/coupons">Coupons</Link>
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/coupons/create" className='_button _button-2'>+ Create New</Link>
                    <Link to="/dashboard/coupons/bin" className='_button _button-3'>Go to Archive</Link>
                </div>
                
                <CustomDataTable 
                    data={this.props.data}
                    columns={columns}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.couponsState)
    }
}

export default connect(mapStateToProps, {
    fetchCoupons,
    deleteCoupon
})(DashboardCoupons)