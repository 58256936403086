import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {connect} from 'react-redux'
import {
  getPaymentStatus,
  getSwitchPaymentStatus,
  clearCart,
  clearCheckout
} from '../actions'
import {BASE_PATH} from '../paths'
import {formatPrice} from '../utils'
import Loader from './ui/Loader'
import {Link} from 'react-router-dom'
import history from '../history'

class CheckoutPaymentStatus extends React.Component {

  componentDidMount(){
    const urlSearchParams = new URLSearchParams(window.location.search)
    const paymentId = urlSearchParams.get('id')
    const resourcePath = urlSearchParams.get('resourcePath')
    
    const orderId = this.props.checkout.order_id
    
    const paymentMethod = this.props.checkout.payment_method

    if(orderId){
      switch (paymentMethod) {

        case 'pay-with-card':
          this.props.getPaymentStatus(orderId, paymentId)
          break;
          
        case 'secure-eft':
          this.props.getSwitchPaymentStatus(orderId, resourcePath)
          break;

        case 'masterpass':
          this.props.getSwitchPaymentStatus(orderId, resourcePath)
          break;

        default:
          break;
      }
    }
  }

  componentDidUpdate(){
    if(this.props.order){
      if(this.props.order.status === 'complete'){
        this.props.clearCart()
      }
      if(this.props.order.status === 'cancelled'){
        this.props.clearCheckout()
      }
    }
  }

  renderOrderItems = () => {
    if(!this.props.order.order_products)
    return <></>

    if(this.props.order.order_products.length === 0)
    return <></>

    return this.props.order.order_products.map(({title, price, total, quantity, preview_image}, index) => {
      return (
        <tr key={index}>
          <td className='_product-name'><p style={{
            display: 'flex', alignItems: 'center'
          }}><img src={BASE_PATH+preview_image} alt="" style={{width: '100px', marginRight: '20px'}} />{title}</p></td>
          <td className='_price'><p style={{whiteSpace:'nowrap'}}>R {formatPrice(price)}</p></td>
          <td className='_qty'><p>{quantity}</p></td>
          <td className='_subtotal'><p style={{whiteSpace:'nowrap'}}><strong>R {formatPrice(total)}</strong></p></td>
        </tr>
      )
    })
  }

  render(){

    if(!this.props.response)
    return <Loader loading={true} />

    if(!this.props.order)
    return (
      <>
        <Seo
          title={`Order`}
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
        <Breadcrums>
          <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
          Order {this.props.response ? 'CANCELLED' : 'UNKNOWN'}
        </Breadcrums>
        <SimpleHeading>ORDER {this.props.response ? 'CANCELLED' : 'UNKNOWN'}</SimpleHeading>
        
        <section className='_section _section-cart'>
            <div className='_grid'>
              <div className='_column' style={{textAlign: 'center'}}>
                {this.props.response && <p className='_transaction-message'>{this.props.response.result && this.props.response.result.description}</p>}
                <br />
                <br />
                <Link to="/products" className='_button'>Shop Now</Link>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )

    if(this.props.order.status === 'complete'){
      localStorage.setItem('order', JSON.stringify(this.props.order))
      history.push("/checkout/complete")
    }
    

    const {status, billing_name, billing_surname, billing_email, billing_phone, billing_province, billing_city, billing_address, billing_postal_code, billing_subtotal, billing_delivery_cost, billing_total, billing_discount} = this.props.order

    return (
      <>
        <Seo
          title={`Order ${status}`}
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
        <Breadcrums>
          <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
          Order {status}
        </Breadcrums>
        <SimpleHeading>ORDER {status}</SimpleHeading>
        
        <section className='_section _section-cart'>
            <div className='_grid'>
              <div className='_column'>
                {this.props.response && <p className='_transaction-message'>{this.props.response.result && this.props.response.result.description}</p>}
                <h3 style={{ textTransform: 'uppercase' }}> ORDER SUMMARY</h3>
                <table className='_table _table-shopping-cart'>
                  <thead>
                    <tr>
                      <th className='_product-image'>Product</th>
                      <th className='_price'>Price</th>
                      <th className='_qty'>Quantity</th>
                      <th className='_subtotal'>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderOrderItems()}
                  </tbody>
                </table>
                <div className='_flex-box'>
                  <div />
                  <div className='_cart-totals'>
                    <table className='_table-details'>
                      <tbody>
                        {billing_subtotal && (
                          <tr>
                            <td>
                              <p><strong>Sub Total:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_subtotal)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_delivery_cost && (
                          <tr>
                            <td>
                              <p><strong>Delivery:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_delivery_cost)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_discount && (
                          <tr>
                            <td>
                              <p><strong>Discount:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_discount)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_total && (
                          <tr>
                            <td>
                              <p><strong>Grand Total:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_total)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_name && (
                          <tr>
                            <td>
                              <p><strong>Name:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_name} {billing_surname && billing_surname}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_phone && (
                          <tr>
                            <td>
                              <p><strong>Mobile:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_phone}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_email && (
                          <tr>
                            <td>
                              <p><strong>Email:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_email}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_address && (
                          <tr>
                            <td>
                              <p><strong>Address:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_address},{billing_city && <><br />{billing_city}</>},{billing_province && <><br />{billing_province}</>},<br />South Africa,{billing_postal_code && <><br />{billing_postal_code}</>}</i></p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Link to="/checkout" className='_button _button-2 _checkout'>Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    checkout: state.checkoutState,
    order: state.paymentStatusState.order ? state.paymentStatusState.order : null,
    response: state.paymentStatusState.response ? state.paymentStatusState.response : null
  }
}

export default connect(mapStateToProps, {
  getPaymentStatus,
  getSwitchPaymentStatus,
  clearCart,
  clearCheckout
})(CheckoutPaymentStatus)