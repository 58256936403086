import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {connect} from 'react-redux'
import {BASE_PATH} from '../paths'
import {formatPrice} from '../utils'
import Loader from './ui/Loader'

class CheckoutThankyou extends React.Component {

  renderOrderItems = () => {
    if(!this.props.paymentStatus.order_products)
    return <></>

    if(this.props.paymentStatus.order_products.length === 0)
    return <></>

    return this.props.paymentStatus.order_products.map(({title, price, total, quantity, preview_image}, index) => {
      return (
        <tr key={index}>
          <td className='_product-name'><p style={{
            display: 'flex', alignItems: 'center'
          }}><img src={BASE_PATH+preview_image} alt="" style={{width: '100px', marginRight: '20px'}} />{title}</p></td>
          <td className='_price'><p style={{whiteSpace:'nowrap'}}>R {formatPrice(price)}</p></td>
          <td className='_qty'><p>{quantity}</p></td>
          <td className='_subtotal'><p style={{whiteSpace:'nowrap'}}><strong>R {formatPrice(total)}</strong></p></td>
        </tr>
      )
    })
  }

  render(){

    if(!this.props.paymentStatus)
    return <Loader loading={true} />

    const {status, billing_name, billing_surname, billing_email, billing_phone, billing_province, billing_city, billing_address, billing_postal_code, billing_subtotal, billing_delivery_cost, billing_total, billing_discount} = this.props.paymentStatus

    return (
      <>
        <Seo
          title={`Order ${status}`}
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
        <Breadcrums>
          <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
          Order {status}
        </Breadcrums>
        <SimpleHeading>ORDER {status}</SimpleHeading>
        <section className='_section _section-cart'>
            <div className='_grid'>
              <div className='_column'>
                <h3>ORDER SUMMARY</h3>
                <table className='_table _table-shopping-cart'>
                  <thead>
                    <tr>
                      <th className='_product-image'>Product</th>
                      <th className='_price'>Price</th>
                      <th className='_qty'>Quantity</th>
                      <th className='_subtotal'>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderOrderItems()}
                  </tbody>
                </table>
                <div className='_flex-box'>
                  <div />
                  <div className='_cart-totals'>
                    <table className='_table-details'>
                      <tbody>
                        {billing_subtotal && (
                          <tr>
                            <td>
                              <p><strong>Sub Total:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_subtotal)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_delivery_cost && (
                          <tr>
                            <td>
                              <p><strong>Delivery:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_delivery_cost)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_discount && (
                          <tr>
                            <td>
                              <p><strong>Discount:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_discount)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_total && (
                          <tr>
                            <td>
                              <p><strong>Grand Total:</strong></p>
                            </td>
                            <td className='_total'>
                              <p style={{whiteSpace:'nowrap'}}>R {formatPrice(billing_total)}</p>
                            </td>
                          </tr>
                        )}
                        {billing_name && (
                          <tr>
                            <td>
                              <p><strong>Name:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_name} {billing_surname && billing_surname}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_phone && (
                          <tr>
                            <td>
                              <p><strong>Mobile:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_phone}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_email && (
                          <tr>
                            <td>
                              <p><strong>Email:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_email}</i></p>
                            </td>
                          </tr>
                        )}
                        {billing_address && (
                          <tr>
                            <td>
                              <p><strong>Address:</strong></p>
                            </td>
                            <td className='_total'>
                              <p><i>{billing_address},{billing_city && <><br />{billing_city}</>},{billing_province && <><br />{billing_province}</>},<br />South Africa,{billing_postal_code && <><br />{billing_postal_code}</>}</i></p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    checkout: state.checkoutState,
    paymentStatus: state.paymentStatusState.order ? state.paymentStatusState.order : null
  }
}

export default connect(mapStateToProps)(CheckoutThankyou)