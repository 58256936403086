import _ from 'lodash'
import {
    FETCH_VARIATIONS,
    CREATE_VARIATION,
    FETCH_VARIATION,
    UPDATE_VARIATION,
    DELETE_VARIATION,
    RESTORE_VARIATION
} from '../../actions/types'

const initialState = {}

const variationsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_VARIATIONS:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_VARIATION:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case FETCH_VARIATION:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case UPDATE_VARIATION:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }   
        case RESTORE_VARIATION:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            } 
        case DELETE_VARIATION:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default variationsReducer