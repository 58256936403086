import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select
} from './fields'

import Recaptcha from 'react-recaptcha'

class BillingUpdateForm extends React.Component {

  state= {
    capchaLoaded: null,
    capchaVerified: null
  }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {   
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
      console.log('recapcha has loaded')
      this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {
      if(!this.state.capchaLoaded)
      return

      if(!this.state.capchaVerified)
      console.log('Robot filter denied')

      else 
      this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="billing_phone" type="text" component={Input} label="Phone Number*" />
                </div>

                <div className="_form-row">
                    <Field 
                        name="billing_province" 
                        label="Province*" 
                        component={Select} 
                    >
                        <option value="">Please select a province</option>
                        <option>Western Cape</option>
                        <option>Garden Route</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                    </Field>
                </div>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="billing_city" 
                        label="City / Town*" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field name="billing_street_address" type="text" component={Input} label="Address*" />
                </div>

                <div className="_form-row">
                    <Field name="billing_postal_code" type="text" component={Input} label="Postal Code*" />
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6LfwzfEhAAAAAI2NdIoq-L9mOVUml-1aWGbn9mgg"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>
                <br />
                 
                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>Update</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    return errors
}

export default reduxForm({
    form: 'billingupdate',
    validate
})(BillingUpdateForm)