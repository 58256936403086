import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'

class Dashboard extends React.Component {

    render(){
        return(
            <DashboardLayout 
                heading="Welcome To Dashboard"
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link>
                </>)}
            >

                <nav className='_dashboard-primary-navigation'>
                    <ul>
                        <li>
                            <Link to="/dashboard/posts" className='_button-2-large'>Posts</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/products" className='_button-2-large'>Products</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/brands" className='_button-2-large'>Brands</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/categories" className='_button-2-large'>Product Categories</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/types" className='_button-2-large'>Product Types</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/orders" className='_button-2-large'>Orders</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/coupons" className='_button-2-large'>Coupons</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/delivery-options" className='_button-2-large'>Delivery Options</Link>
                        </li>
                        <li>
                            <Link to="/dashboard/users" className='_button-2-large'>Users</Link>
                        </li>
                    </ul>
                </nav>

            </DashboardLayout>
        )
    }
}

export default Dashboard