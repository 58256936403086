import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchBrands,
    deleteBrand
} from '../../../actions/brands'
import DataTable from 'react-data-table-component';
import {BASE_PATH} from '../../../paths'
import CustomDataTable from '../ui/CustomDataTable'

class DashboardBrands extends React.Component {

    componentDidMount(){
        this.props.fetchBrands(this.props.token)
    }

    onDelete = id => {
        this.props.deleteBrand(this.props.token, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/brands/${id}/edit`} >Edit</Link>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Archive</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Logo',
                selector: row => row.logo,
                cell: row => {
                    return (
                        <img src={BASE_PATH+row.logo} alt="" width="100" style={{margin: '10px 0', maxWidth: '100px', backgroundColor: '#181818'}} />
                    )
                }
            },
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Slug',
                selector: row => row.slug,
                sortable: true,
            },
            {
                name: 'Priority',
                selector: row => row.order_number,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Brands" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/brands">Brands</Link>
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/brands/create" className='_button _button-2'>+ Create New</Link>
                    <Link to="/dashboard/brands/bin" className='_button _button-3'>Go to Archive</Link>
                </div>
                
                <CustomDataTable 
                    data={this.props.data}
                    columns={columns}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.brandsState)
    }
}

export default connect(mapStateToProps, {
    fetchBrands,
    deleteBrand
})(DashboardBrands)