import React from 'react'
import bestProduct from '../../assets/img/Icons/best-product-icon.svg'
import bestService from '../../assets/img/Icons/best-service-icon.svg'
import bestPrice from '../../assets/img/Icons/best-price-icon.svg'

class OurBrandPhilosophy extends React.Component {

  render(){

    return(
      <section className='_section _section-our-brand-philosophy' id="philosophy">
        <div className='_grid'>
          <div className='_column'>
            <div className='_flex-box'>
              <div className='_item'>
                <h3 className='_pre-heading'>BEAUTYFIRES</h3>
                <h2 className='_heading'><span className='_gold'>Our Brand</span> <span className='_light-italic'><br />Philosophy</span></h2>
              </div>
              <div className='_item'>
                <p>Beauty Fires was conceived more than a decade ago to provide our customers with the highest quality fireplaces and lifestyle cooking products. We offer personalised service from our three showrooms in Cape Town, Garden Route and Johannesburg.</p>
                <p>Our collection includes closed combustion fireplaces, gas fireplaces, bioethanol fireplaces, unique suspended wood fireplaces, braais and spit braais. We look forward to welcoming you to one of our showrooms for a personal consultation, or if you are far away, we can assist you anywhere in the world.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='_grid _grid-width-seperator' style={{marginTop: "60px"}}>
          <div className='_column'>
            <div className='_flex-box'>
              <img src={bestProduct} alt="" className='_icon' width="44" height="50" />
              <div className='_copy'>
                <h3 className='_secondary-heading'>Best Product</h3>
                <p>Beauty Fires works closely with architects and designers to create iconic indoor/outdoor products that are of the highest quality and become the centrepiece of your home.</p>
              </div>
            </div>
          </div>
          <div className='_seperator' />
          <div className='_column'>
            <div className='_flex-box'>
              <img src={bestService} alt="" className='_icon' width="42" height="50" />
              <div className='_copy'>
                <h3 className='_secondary-heading'>Best Service</h3>
                <p>Our attention to detail, superior service and customer-centric approach set us apart. We pride ourselves on delivering excellent service to our customers worldwide.</p>
              </div>
            </div>
          </div>
          <div className='_seperator' />
          <div className='_column'>
            <div className='_flex-box'>
              <img src={bestPrice} alt="" className='_icon' width="42" height="50" />
              <div className='_copy'>
                <h3 className='_secondary-heading'>Best Price</h3>
                <p>We offer the best prices on our range of world-class, internationally and locally manufactured, indoor and outdoor fireplaces and lifestyle cooking products.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurBrandPhilosophy