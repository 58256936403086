import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {
  fetchPublicPosts
} from '../actions'
import {connect} from 'react-redux'
// import {BASE_PATH} from '../paths'
import {Link} from 'react-router-dom'
import Breadcrums from './ui/Breadcrums'
import PageBanner from './ui/PageBanner'
import BlogItem from './views/BlogItem'
import {BASE_PATH} from '../paths'

class Blog extends React.Component {

  componentDidMount(){
    this.props.fetchPublicPosts()
  }

  renderBlogItems = () => {
    return this.props.data.map(({headline,slug,preview_image, summary}, index) => {
      if(index === 0)
      return <React.Fragment key={index}></React.Fragment>     

      return(
        <BlogItem image={BASE_PATH+preview_image} headline={headline} summary={summary} slug={slug} key={index} />
      )
    })
  }

  render(){

    if(!this.props.data[0])
    return <></>

    const {headline, preview_image, summary, slug} = this.props.data[0]

    return (
      <>
        <Seo
          title={`${headline} | Blog`}
          description={summary}
          image={BASE_PATH+preview_image}
          keywords="fireplace blog, fireplace news, fireplace article, fireplace discount voucher, home décor blog, braai
          blog"
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            Blog
          </Breadcrums>
          {this.props.data.length > 0  && (
            <PageBanner 
              image={BASE_PATH+preview_image}
              heading={headline}
            >
              <div dangerouslySetInnerHTML={{__html: summary}} />
              <Link to={`/blog/${slug}`} className="_button">READ MORE</Link>
            </PageBanner>
          )}
          
          <section className='_section _section-blog'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-panel'>
                  
                  {this.props.data.length > 0 && this.renderBlogItems()}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: Object.values(state.publicPostsState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicPosts
})(Blog)