import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchDeliveryOptionsBin,
    forceDeleteDeliveryOption,
    restoreDeliveryOption
} from '../../../actions/delivery-options'
import DataTable from 'react-data-table-component';
import {formatPrice} from '../../../utils'

class DashboardDeliveryOptionsBin extends React.Component {

    componentDidMount(){
        this.props.fetchDeliveryOptionsBin(this.props.token)
    }

    onDelete = id => {
        this.props.forceDeleteDeliveryOption(this.props.token, id)
    }

    onRestore = id => {
        this.props.restoreDeliveryOption(this.props.token, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <button className="_table-button _table-button-edit" onClick={() => this.onRestore(id)} >Restore</button>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Delete</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Price',
                selector: row => 'R ' + formatPrice(row.price),
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Delivery Options Archive" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/delivery-options">Delivery Options</Link> / Bin
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/delivery-options" className='_button _button-3'>Back</Link>
                </div>
                
                <DataTable
                    columns={columns}
                    data={this.props.data}
                    pagination
                    customStyles={customStyles}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.deliveryOptionsBinState)
    }
}

export default connect(mapStateToProps, {
    fetchDeliveryOptionsBin,
    forceDeleteDeliveryOption,
    restoreDeliveryOption
})(DashboardDeliveryOptionsBin)