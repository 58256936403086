import React from 'react'
import Helmet from 'react-helmet'
import ogimage from './assets/img/Hero/aero-suspended-fireplace.jpg'
import {strip} from './utils'

class Seo extends React.Component {
  render(){

    const {title, description, image, keywords, jsonLD} = this.props

    return (
      <Helmet>
          <title>{`${title && title + ' | '} Beauty Fires`}</title>
          <meta name="description" content={strip(description ? description : '')} />
          <meta name="keywords" content={keywords ? keywords : 'Modern Fireplaces, Fireplaces South Africa'} />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content={`${title && title + ' | '} Beauty Fires`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={image ? image : window.location.host+ogimage} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:description" content={strip(description ? description : '')} />
          <meta name="twitter:image:alt" content="Beauty Fires" />
          {jsonLD && (
            <script type="application/ld+json">
              {JSON.stringify(jsonLD)}
            </script>
          )}
          {this.props.children}
      </Helmet>
    )
  }
}

export default Seo