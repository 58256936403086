import React from 'react'
import Logo from './Logo'
import LeftNav from './LeftNav'
import RightNav from './RightNav'
import HamburgerIcon from './HamburgerIcon'
import UserCartNav from './UserCartNav'

class Header extends React.Component {

  componentDidMount(){

    const milliseconds = 300
    const nodeList = document.querySelectorAll("._widescreen ._with-dropdown-arrow")

    for(let i = 0; i < nodeList.length; i++){

      let area = nodeList[i].parentElement
      let trigger = nodeList[i]
      let dropdown = nodeList[i].nextElementSibling

      if(!dropdown)
      return

      setTimeout(function(){
        
        area.addEventListener("mouseenter", function(){

          const mouseEntered = setTimeout(function(){
            dropdown.classList.add("_show")
            trigger.classList.add("_open")
          }, milliseconds)

          area.addEventListener("mouseleave", function(){

            const mouseLeft = setTimeout(function(){
              dropdown.classList.remove("_show")
              trigger.classList.remove("_open")
            }, milliseconds)

            clearTimeout(mouseEntered);

            area.addEventListener("mouseenter", function(){
              clearTimeout(mouseLeft);
            })
          })

          area.addEventListener("click", e => {
            if(e.target.classList.contains("_nav-link-item")){
              dropdown.classList.remove("_show")
              trigger.classList.remove("_open")
            }
          },)
        })
      })
    }
  }

  render(){
    return (
      <header className='_header'>
        <div className='_header-grid'>
          <div className='_column'>
            <div className='_flex-panel'>
              <HamburgerIcon onMenuToggle={this.props.onMenuToggle} menuOpen={this.props.menuOpen} />
              <LeftNav />
              <Logo />
              <div className='_nav-group'>
                <RightNav />
                <UserCartNav />
              </div>
                
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header