import React, {useState, useEffect} from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'

import history from '../history'

//public
import Home from './Home'
import Products from './Products'
import Product from './Product'
import ShoppingCart from './ShoppingCart'
import Checkout from './Checkout'
import CheckoutThankyou from './CheckoutThankyou'
import Signin from './Signin'
import Contact from './Contact'
import Booking from './Booking'
import BookingThankyou from './BookingThankyou'
import Blog from './Blog'
import BlogArticle from './BlogArticle'
import Account from './Account'
import AccountDashboard from './account/AccountDashboard'
import AccountDashboardDetails from './account/AccountDashboardDetails'
import AccountDashboardBilling from './account/AccountDashboardBilling'
import AccountDashboardOrders from './account/AccountDashboardOrders'
import AccountDashboardOrderDetails from './account/AccountDashboardOrderDetails'
import ContactThankyou from './ContactThankyou'
import SubscribedThankyou from './SubscribedThankyou'
import TermsAndConditions from './TermsAndConditions'
import PrivacyPolicy from './PrivacyPolicy'
import PaymentCopyAndPay from './PaymentCopyAndPay'
import PaymentSwitch from './PaymentSwitch'
import Loader from './ui/Loader'
import PageNotFound from './PageNotFound'
import ProductEnquiry from './ProductEnquiry'
import ProductEnquiryThankyou from './ProductEnquiryThankyou'
import VirtualTour from './VirtualTour'

import Dashboard from './dashboard/Dashboard'
import DashboardAccount from './dashboard/account/DashboardAccount'
import DashboardUsers from './dashboard/users/DashboardUsers'
import DashboardUserCreate from './dashboard/users/DashboardUserCreate'
import DashboardUserEdit from './dashboard/users/DashboardUserEdit'
import DashboardProducts from './dashboard/products/DashboardProducts'
import DashboardProductCreate from './dashboard/products/DashboardProductCreate'
import DashboardProductEdit from './dashboard/products/DashboardProductEdit'
import DashboardProductsBin from './dashboard/products/DashboardProductsBin'
import DashboardVariations from './dashboard/variations/DashboardVariations'
import DashboardVariationCreate from './dashboard/variations/DashboardVariationCreate'
import DashboardVariationEdit from './dashboard/variations/DashboardVariationEdit'
import DashboardVariationsBin from './dashboard/variations/DashboardVariationsBin'
import DashboardImages from './dashboard/images/DashboardImages'
import DashboardImageCreate from './dashboard/images/DashboardImageCreate'
import DashboardImageEdit from './dashboard/images/DashboardImageEdit'
import DashboardImagesBin from './dashboard/images/DashboardImagesBin'
import DashboardDocuments from './dashboard/documents/DashboardDocuments'
import DashboardDocumentCreate from './dashboard/documents/DashboardDocumentCreate'
import DashboardDocumentEdit from './dashboard/documents/DashboardDocumentEdit'
import DashboardDocumentsBin from './dashboard/documents/DashboardDocumentsBin'
import DashboardBrands from './dashboard/brands/DashboardBrands'
import DashboardBrandCreate from './dashboard/brands/DashboardBrandCreate'
import DashboardBrandEdit from './dashboard/brands/DashboardBrandEdit'
import DashboardBrandsBin from './dashboard/brands/DashboardBrandsBin'
import DashboardTypes from './dashboard/product-types/DashboardTypes'
import DashboardTypeCreate from './dashboard/product-types/DashboardTypeCreate'
import DashboardTypeEdit from './dashboard/product-types/DashboardTypeEdit'
import DashboardTypesBin from './dashboard/product-types/DashboardTypesBin'
import DashboardPosts from './dashboard/posts/DashboardPosts'
import DashboardPostCreate from './dashboard/posts/DashboardPostCreate'
import DashboardPostEdit from './dashboard/posts/DashboardPostEdit'
import DashboardPostsBin from './dashboard/posts/DashboardPostsBin'
import DashboardCategories from './dashboard/categories/DashboardCategories'
import DashboardCategoryCreate from './dashboard/categories/DashboardCategoryCreate'
import DashboardCategoryEdit from './dashboard/categories/DashboardCategoryEdit'
import DashboardCategoriesBin from './dashboard/categories/DashboardCategoriesBin'
import DashboardOrders from './dashboard/orders/DashboardOrders'
import DashboardOrder from './dashboard/orders/DashboardOrder'
import DashboardCoupons from './dashboard/coupons/DashboardCoupons'
import DashboardCouponCreate from './dashboard/coupons/DashboardCouponCreate'
import DashboardCouponEdit from './dashboard/coupons/DashboardCouponEdit'
import DashboardCouponsBin from './dashboard/coupons/DashboardCouponsBin'
import DashboardDeliveryOptions from './dashboard/delivery-options/DashboardDeliveryOptions'
import DashboardDeliveryOptionCreate from './dashboard/delivery-options/DashboardDeliveryOptionCreate'
import DashboardDeliveryOptionEdit from './dashboard/delivery-options/DashboardDeliveryOptionEdit'
import DashboardDeliveryOptionsBin from './dashboard/delivery-options/DashboardDeliveryOptionsBin'
import ForgotPassword from './ForgotPassword'
import ForgotPasswordOtp from './ForgotPasswordOtp'
import ForgotPasswordReset from './ForgotPasswordReset'
import ShippingPolicy from './ShippingPolicy'
import CheckoutPaymentStatus from './CheckoutPaymentStatus'
import PageTracker from '../PageTracker';

const App = () => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2600);
    }, [])

    return(<>
        <Loader loading={loading} />
        <Router history={history}>
            <PageTracker />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/product-category" component={Products} />
                <Route exact path="/products/:slug" component={Product} />
                <Route exact path="/product-category/:slug" component={Product} />
                <Route exact path="/products/:slug/enquire" component={ProductEnquiry} />
                <Route exact path="/products/:slug/enquire/thankyou" component={ProductEnquiryThankyou} />
                
                <Route exact path="/cart" component={ShoppingCart} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/checkout/payment/status" component={CheckoutPaymentStatus} />
                <Route exact path="/checkout/payment/pay-with-card" component={PaymentCopyAndPay} />
                <Route exact path="/checkout/payment/:paymentMethod" component={PaymentSwitch} />
                <Route exact path="/checkout/complete" component={CheckoutThankyou} />
                <Route exact path="/contact/thankyou" component={ContactThankyou} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/contact/:branch" component={Contact} />
                <Route exact path="/booking" component={Booking} />
                <Route exact path="/fireplace-quote" component={Booking} />
                <Route exact path="/product-enquiry" component={Booking} />
                <Route exact path="/quote-request" component={Booking} />
                <Route exact path="/booking/thankyou" component={BookingThankyou} />
                <Route exact path="/news" component={Blog} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/:slug" component={BlogArticle} />
                <Route exact path="/account" component={Account} />
                <Route exact path="/account/dashboard" component={AccountDashboard} />
                <Route exact path="/account/details" component={AccountDashboardDetails} />
                <Route exact path="/account/billing" component={AccountDashboardBilling} />
                <Route exact path="/account/my-orders" component={AccountDashboardOrders} />
                <Route exact path="/account/my-orders/:orderId" component={AccountDashboardOrderDetails} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/forgot-password/otp" component={ForgotPasswordOtp} />
                <Route exact path="/forgot-password/reset" component={ForgotPasswordReset} />
                <Route exact path="/newsletter-subscription/thankyou" component={SubscribedThankyou} />
                <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                <Route exact path="/terms-conditions" component={TermsAndConditions} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/shipping-policy" component={ShippingPolicy} />
                <Route exact path={["/admin/signin", "/admin/login", "/admin"]} component={Signin} />
                <Route exact path="/virtual-tour" component={VirtualTour} />

                {/* dashboard */}
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/dashboard/account" component={DashboardAccount} />

                <Route exact path="/dashboard/users" component={DashboardUsers} />
                <Route exact path="/dashboard/users/create" component={DashboardUserCreate} />
                <Route exact path="/dashboard/users/:userId/edit" component={DashboardUserEdit} />

                <Route exact path="/dashboard/products" component={DashboardProducts} />
                <Route exact path="/dashboard/products/create" component={DashboardProductCreate} />
                <Route exact path="/dashboard/products/bin" component={DashboardProductsBin} />
                <Route exact path="/dashboard/products/:productId/edit" component={DashboardProductEdit} />

                <Route exact path="/dashboard/products/:productId/variations" component={DashboardVariations} />
                <Route exact path="/dashboard/products/:productId/variations/create" component={DashboardVariationCreate} />
                <Route exact path="/dashboard/products/:productId/variations/bin" component={DashboardVariationsBin} />
                <Route exact path="/dashboard/products/:productId/variations/:variationId/edit" component={DashboardVariationEdit} />

                <Route exact path="/dashboard/products/:productId/images" component={DashboardImages} />
                <Route exact path="/dashboard/products/:productId/images/create" component={DashboardImageCreate} />
                <Route exact path="/dashboard/products/:productId/images/bin" component={DashboardImagesBin} />
                <Route exact path="/dashboard/products/:productId/images/:imageId/edit" component={DashboardImageEdit} />

                <Route exact path="/dashboard/products/:productId/documents" component={DashboardDocuments} />
                <Route exact path="/dashboard/products/:productId/documents/create" component={DashboardDocumentCreate} />
                <Route exact path="/dashboard/products/:productId/documents/bin" component={DashboardDocumentsBin} />
                <Route exact path="/dashboard/products/:productId/documents/:documentId/edit" component={DashboardDocumentEdit} />

                <Route exact path="/dashboard/categories" component={DashboardCategories} />
                <Route exact path="/dashboard/categories/create" component={DashboardCategoryCreate} />
                <Route exact path="/dashboard/categories/bin" component={DashboardCategoriesBin} />
                <Route exact path="/dashboard/categories/:categoryId/edit" component={DashboardCategoryEdit} />

                <Route exact path="/dashboard/brands" component={DashboardBrands} />
                <Route exact path="/dashboard/brands/create" component={DashboardBrandCreate} />
                <Route exact path="/dashboard/brands/bin" component={DashboardBrandsBin} />
                <Route exact path="/dashboard/brands/:brandId/edit" component={DashboardBrandEdit} />

                <Route exact path="/dashboard/types" component={DashboardTypes} />
                <Route exact path="/dashboard/types/create" component={DashboardTypeCreate} />
                <Route exact path="/dashboard/types/bin" component={DashboardTypesBin} />
                <Route exact path="/dashboard/types/:typeId/edit" component={DashboardTypeEdit} />

                <Route exact path="/dashboard/posts" component={DashboardPosts} />
                <Route exact path="/dashboard/posts/create" component={DashboardPostCreate} />
                <Route exact path="/dashboard/posts/bin" component={DashboardPostsBin} />
                <Route exact path="/dashboard/posts/:postId/edit" component={DashboardPostEdit} />

                <Route exact path="/dashboard/orders" component={DashboardOrders} />
                <Route exact path="/dashboard/orders/:orderId" component={DashboardOrder} />

                <Route exact path="/dashboard/coupons" component={DashboardCoupons} />
                <Route exact path="/dashboard/coupons/create" component={DashboardCouponCreate} />
                <Route exact path="/dashboard/coupons/bin" component={DashboardCouponsBin} />
                <Route exact path="/dashboard/coupons/:couponId/edit" component={DashboardCouponEdit} />

                <Route exact path="/dashboard/delivery-options" component={DashboardDeliveryOptions} />
                <Route exact path="/dashboard/delivery-options/create" component={DashboardDeliveryOptionCreate} />
                <Route exact path="/dashboard/delivery-options/bin" component={DashboardDeliveryOptionsBin} />
                <Route exact path="/dashboard/delivery-options/:deliveryOptionId/edit" component={DashboardDeliveryOptionEdit} />

                <Route exact path="/*" component={PageNotFound} />
                    
            </Switch>
        </Router>
    </>)
}

export default App