import {Link} from 'react-router-dom'

const CategoryItem = props => {
  let webpImage = props.image
  let jpegImage = props.image.replace(".webp", ".jpg")
  return (
    <Link to={props.link} className='_category-item'>
      <div className='_bg-image' 
        // style={{backgroundImage: `url(${props.image})`}} 
      >
        <picture>
          <source srcSet={webpImage} type="image/webp" />
          <source srcSet={jpegImage} type="image/jpeg" /> 
          <img src={jpegImage} alt={props.name} width="270" height="270" />
        </picture>
      </div>
      <h3>{props.name}</h3>
      <div className='_overlay' />
    </Link>
  )
}

export default CategoryItem