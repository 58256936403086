import admin from '../apis/admin'

import {
  FETCH_ORDERS,
  FETCH_ORDER,
} from './types'

export const fetchOrders = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/orders', config)

      dispatch({
          type: FETCH_ORDERS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch orders failed')
  }
}

export const fetchOrder = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/orders/' + id, config)

      dispatch({
          type: FETCH_ORDER,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch order failed')
  }
}