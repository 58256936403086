import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchCategoriesBin,
    forceDeleteCategory,
    restoreCategory
} from '../../../actions/categories'
import DataTable from 'react-data-table-component'

class DashboardCategoriesBin extends React.Component {

    componentDidMount(){
        this.props.fetchCategoriesBin(this.props.token)
    }

    onDelete = id => {
        this.props.forceDeleteCategory(this.props.token, id)
    }

    onRestore = id => {
        this.props.restoreCategory(this.props.token, id)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <button className="_table-button _table-button-edit" onClick={() => this.onRestore(id)} >Restore</button>
                <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Delete</button>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Title',
                selector: row => row.title,
                sortable: true,
            },
            {
                name: 'Slug',
                selector: row => row.slug,
                sortable: true,
            },
            {
                name: 'Priority',
                selector: row => row.order_number,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Categories Archive" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/categories">Categories</Link> / Bin
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/categories" className='_button _button-3'>Back</Link>
                </div>
                
                <DataTable
                    columns={columns}
                    data={this.props.data}
                    pagination
                    customStyles={customStyles}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.categoriesBinState)
    }
}

export default connect(mapStateToProps, {
    fetchCategoriesBin,
    forceDeleteCategory,
    restoreCategory
})(DashboardCategoriesBin)