import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input,
    Select
} from './fields'

class CouponEditForm extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            couponType: this.props.initialValues ? this.props.initialValues.type : null
        }
    }

    onChange = e => {
        this.setState({couponType: e.target.value})
    }

    renderCouponType(){
        if(!this.state.couponType)
        return<></>

        switch (this.state.couponType) {
            case 'fixed':
            return (
                <Field 
                    type="number"
                    name="value" 
                    label="Discount*" 
                    component={Input} 
                />
            )
            case 'percentage':
            return (
                <Field 
                    type="number"
                    name="percent_off" 
                    label="Percentage Off*" 
                    component={Input} 
                />
            )
            default:
            return <></>
        }
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="code" 
                        label="Code*" 
                        component={Input} 
                    />
                    <Field 
                        name="type" 
                        label="Type of discount" 
                        component={Select}
                        onChange={this.onChange}
                    >
                        <option value="">Please select the type of discount</option>
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                    </Field>
                </div>

                <div className="_form-row">
                    {this.renderCouponType()}
                </div>

                <div className="_form-row">
                    <Field 
                        type="date"
                        name="expires" 
                        label="Expires(optional)" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Update</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.code){
        errors.code = "you must enter the code"
    }

    if(!formValues.type){
      errors.type = "you must select a type"
    }
    
    return errors
}

export default reduxForm({
    form: 'couponedit',
    validate
})(CouponEditForm)