import React from 'react'
import {
  removeVariableItem
} from '../../actions'
import {connect} from 'react-redux'
import {BASE_PATH} from '../../paths'
import QuantityForm from './VariableQuantityForm'
import {formatPrice} from '../../utils'
import Popup from '../ui/Popup'
import {Link} from 'react-router-dom'

class VariableCartItem extends React.Component {

  state= {
    popupVisible: false
  }

  removeItem = id => {
    this.togglePopup()
    setTimeout(() => {
      this.props.removeVariableItem(id)
    }, 2000);
  }

  togglePopup = () => {
    this.setState({popupVisible: !this.state.popupVisible})
  }

  render(){

    const {id, title, price, quantity, preview_image, total, slug} = this.props.data

    return (
      <>
        <Popup show={this.state.popupVisible} onClick={this.togglePopup} heading="Message">
          <p className='_secondary-heading'>Item removed from cart</p>
          <div className='_popup-buttons'>
            <button className='_button' onClick={this.togglePopup}>OK</button>
          </div>
        </Popup>
        <tr key={id}>
          <td className='_remove'>
            <button className='_remove-button' onClick={() => this.removeItem(id)}>
              <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="20" fill="#82828a"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>
            </button>
          </td>
          <td className='_product-image'><img src={BASE_PATH+preview_image} alt="" style={{width: '100px'}} width="200" height="160" /></td>
          <td className='_product-name'><p><Link to={`/products/${slug}`}>{title}</Link></p></td>
          <td className='_price'><p>R{formatPrice(price)}</p></td>
          <td className='_qty'>
            <QuantityForm 
              id={id}
              quantity={quantity}
            />
          </td>
          <td className='_subtotal'><p><strong>R{formatPrice(total)}</strong></p></td>
        </tr>
      </>
    )
  }
}

export default connect(null ,{
  removeVariableItem
})(VariableCartItem)