import {
  GET_PAYMENT_STATUS,
  CHECKOUT
} from '../actions/types'

const paymentStatusReducer = (state = {}, action) => {
    switch(action.type){
        case GET_PAYMENT_STATUS:
            return action.payload
        case CHECKOUT:
            return {}
        default:
            return state
    }
}

export default paymentStatusReducer