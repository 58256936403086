import _ from 'lodash'
import {
    FETCH_IMAGES,
    CREATE_IMAGE,
    FETCH_IMAGE,
    UPDATE_IMAGE,
    DELETE_IMAGE,
    RESTORE_IMAGE
} from '../../actions/types'

const initialState = {}

const imagesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_IMAGES:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_IMAGE:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case FETCH_IMAGE:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case UPDATE_IMAGE:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }   
        case RESTORE_IMAGE:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            } 
        case DELETE_IMAGE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default imagesReducer