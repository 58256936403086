import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {
  fetchPublicProduct,
  addItem,
  addVariableItem,
  downloadDocument,
} from '../actions'
import {connect} from 'react-redux'
import Breadcrums from './ui/Breadcrums'
import {Link} from 'react-router-dom'
import {BASE_PATH} from '../paths'
import Popup from './ui/Popup'
import DownloadDocumentForm from './forms/DownloadDocumentForm'
import {formatPrice} from '../utils'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class Product extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      popupVisible: false,
      newsletterFormVisible: false,
      photoIndex: 0,
      isOpen: false,
      download: {
        url: null,
        fileName: null
      },
      variations: null,
      selectedVariationId: null,
      selectedVariationPrice: null,
      selectedVariationTitle: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.addToCart = this.addToCart.bind(this)
  }

  componentDidMount(){
    this.props.fetchPublicProduct(this.props.match.params.slug)
  }

  handleChange(e) {
    this.setState({qty: parseInt(e.target.value)})
  }

  handleSelectedVariation = e => {
    let {id,price,title} = e.target.options[e.target.selectedIndex].dataset
    this.setState({
      selectedVariationId: id ? id : null,
      selectedVariationPrice: price ? price : null,
      selectedVariationTitle: title ? title : null
    })
  }

  renderPrice = () => {
    return this.state.selectedVariationPrice ? this.state.selectedVariationPrice : this.props.data.price
  }

  renderVariations = () => {
    return this.props.data.variations.map(({id,title,price}) => {
      return (
        <option 
          key={id} 
          data-id={id} 
          data-price={price} 
          data-title={title}
        >
          {title}
        </option>
      )
    })
  }

  addToCart = e => {
    setTimeout(() => {
      if(this.state.selectedVariationId){
        this.props.addVariableItem(this.props.data.slug, this.state.qty, this.state.selectedVariationId)
      } else {
        this.props.addItem(this.props.data.slug, this.state.qty)
      }
      
      this.togglePopup()
    }, 300);
  }

  renderSlickItems = () => {

    if(!this.props.data.images)
    return
    
    return this.props.data.images.map(({path, alt}, index) => {
      return (
        <div className='_img' key={index}>
          <img src={BASE_PATH+path} alt={alt ? alt : 'Gallery Item ' + index} onClick={() => this.setState({ isOpen: true, photoIndex: index + 1 })} width="370" height="520" />
        </div>
      )
    })
  }

  renderProductTypes = () => {
    if(!this.props.data.product_types)
    return

    return this.props.data.product_types.map(({id, title, icon}) => {
      return (
        <div key={id} className="_type-flexbox">
          <img src={BASE_PATH+icon} alt="" width="25" height="25" />
          {title}
        </div>
      )
    })
  }

  youtubeEmbed = iframe => {
    return (
      <div className='_video _youtube' dangerouslySetInnerHTML={{__html: iframe}} />
    )
  }

  showNewsletterForm = e => {

    if(this.props.submitted.success)
    return

    e.preventDefault()
    setTimeout(() => {
      this.setState({
        newsletterFormVisible:true,
        download: {
          url: e.target.href,
          fileName: e.target.download
        }
      })
    }, 300);
  }

  downloadFile = (url, fileName) => {
    if(!url && !fileName)
    return 

    const element = document.createElement('a')
    element.setAttribute('download', fileName);
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.classList.add('_hidden-download-element')
    element.click()
  };

  renderDocuments = () => {

    return this.props.data.documents.map(({id, title, path}) => {

      return (
        <div key={id}>
          <p>
            <a href={BASE_PATH+path} className='_download-link' download={title + ' Technical Specifications.pdf'} target="_blank" rel="noreferrer" onClick={e => this.showNewsletterForm(e)}>
              <svg fill="#FC9602" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z"/></svg>
              &nbsp;
              {title}
            </a>
          </p>
        </div>
      )
    })
  }

  togglePopup = () => {
    this.setState({popupVisible: !this.state.popupVisible})
  }

  onSubmit = formValues => {
    this.props.downloadDocument(formValues, this.props.match.params.slug)
    setTimeout(() => {
      this.setState({
        newsletterFormVisible: false
      })
      this.downloadFile(this.state.download.url, this.state.download.fileName)
    }, 500);
  }

  render(){

    if(!this.props.data)
    return <></>

    const { brand, title, summary, description, price, banner_image, documents, meta_description, meta_keywords, youtube_1, youtube_2, youtube_3, slug } = this.props.data

    const { photoIndex, isOpen } = this.state;

    let images = [
      {
        path: banner_image,
        caption: title
      }
    ]

    this.props.data.images.forEach(image => {
      images = [...images, image]
    });

    const settings = {
      infinite: images.length > 4 ? true : false,
      autoplaySpeed: 5000,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      easing: 'cubic-bezier(0.85, 0, 0.15, 1)',
      pauseOnHover: false,
      dots: false,
      arrows: false,
      // centerMode: true,
      responsive: [
        {
            breakpoint: 1000,
            settings: {
                infinite: images.length > 3 ? true : false,
                slidesToShow: 3
            }
        },
        {
          breakpoint: 700,
          settings: {
              infinite: images.length > 2 ? true : false,
              slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
              infinite: images.length > 1 ? true : false,
              slidesToShow: 1
          }
        }
      ],
    }

    return (
      <>
        <Popup show={this.state.popupVisible} onClick={this.togglePopup} heading="Message">
          <p className='_secondary-heading'>Item added to cart</p>
          <div className='_popup-buttons'>
            <button className='_button' onClick={this.togglePopup}>OK</button>
            <Link to="/cart" className='_button _button-2'>Go To Cart</Link>
          </div>
        </Popup>
        <Seo
          title={`${title} | Products`}
          description={meta_description ? meta_description : summary}
          image={banner_image ? BASE_PATH+banner_image : null}
          keywords={meta_keywords ? meta_keywords : null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/products/" rel="up up">Products</Link>&nbsp;/&nbsp;
            {title}
          </Breadcrums>
          <section className='_section _section-banner-product-overview'>
            <div className='_flex-box'>
              <div 
                className='_item _image' 
                style={{
                  backgroundImage: banner_image ? `url(${BASE_PATH+banner_image})` : 'none',
                  cursor: 'zoom-in'
                }}
                onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}
              />
              <div className='_item _intro'>
                <h1 className='_page-heading'>{title}</h1>
                {summary && (
                  <div className='_summary' dangerouslySetInnerHTML={{__html: summary}} />
                )}
                {/* <table className='_table-type'>
                  <tbody>
                    {this.renderProductTypes()}
                  </tbody>
                </table> */}

                <div className='_types-flexbox'>
                  {this.renderProductTypes()}
                </div>

                {this.props.data.variations.length > 0 && (
                  <>
                    <p className='_product-options-label'><strong>Product Options</strong></p>
                    <select className='_form-element _product-options' onChange={this.handleSelectedVariation}>
                      <option value="">Default</option>
                      {this.renderVariations()}
                    </select>
                  </>
                )}

                {price && (
                  <p className='_price'>R {formatPrice(this.renderPrice())}</p>
                )}
                <div className='_flex _product-page-buttons'>
                  <div className='_group'>
                    {price && (
                      <>
                        {/* <input type="number" value={this.state.qty} onChange={this.handleChange} className="_qty" min="1" /> */}
                        <button className='_button _buy-now' onClick={e => this.addToCart(e)}>BUY NOW</button>
                      </>
                    )}
                    <Link to={`/products/${slug}/enquire`} className='_button _button-4 enquire-now'>Enquire Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='_section _section-product-information'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  <h3>INFORMATION</h3>
                  <div className='_description' dangerouslySetInnerHTML={{__html: description}} />
                  {youtube_1 && this.youtubeEmbed(youtube_1)}
                  {youtube_2 && this.youtubeEmbed(youtube_2)}
                  {youtube_3 && this.youtubeEmbed(youtube_3)}
                </div>
              </div>
            </div>
          </section>

          {this.props.data.video && (
            <section className='_section _section-product-video'>
              <div className='_grid'>
                <div className='_column'>
                  <div className='_panel'>
                    <div className='_video'>
                        <video title="video" playsInline autoPlay loop controls muted>
                          <source src={BASE_PATH+this.props.data.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                    </div>
                  </div>
                </div>
              </div>
            </section>  
          )}
          <section className='_section _section-gallery-slider'>
            <div className='_grid _grid-fluid'>
              <div className='_column'>
                <div className='_gallery-slider'>
                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.renderSlickItems(images)}
                  </Slider>
                  {isOpen && (
                    <Lightbox
                      mainSrc={BASE_PATH + images[photoIndex].path}
                      nextSrc={BASE_PATH + images[(photoIndex + 1) % images.length].path}
                      prevSrc={BASE_PATH + images[(photoIndex + images.length - 1) % images.length].path}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % images.length,
                        })
                      }
                      animationOnKeyInput
                      imageTitle={images[photoIndex].caption ? images[photoIndex].caption : `Beauty Fires ${photoIndex}`}
                      clickOutsideToClose
                      wrapperClassName="_ligtbox-wrapper"
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className='_section _section-product-information-2'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  {this.state.newsletterFormVisible && (
                    <div className='_newsletter-wrapper'>
                      <p><span className='_gold'><strong>ENTER YOUR DETAILS</strong></span> to download product specifications.</p>
                      <DownloadDocumentForm show={true} onSubmit={this.onSubmit} />
                    </div>
                  )}
                  {!this.state.newsletterFormVisible && (
                    <div className='_flex-box'>
                      <div className='_tech-spec'>
                        
                        
                        {documents.length > 0 && (
                          <>
                            <h3>TECHNICAL SPECIFICATIONS</h3>
                            {/* {this.props.submitted.success && <p>You have successfully signed up for our newsletter.</p>} */}
                            {this.renderDocuments()}
                          </>
                        )}
                      </div>
                      <div className='_brand-logo'>
                        {brand && (
                          <Link to={`/products?brand=${brand.slug}`}>
                            <img src={BASE_PATH+brand.logo} alt={brand.title} width="200" height="160" />
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.publicProductState[ownProps.match.params.slug],
    submitted: state.downloadDocumentState,
    auth: state.customerAuthState
  }
}

export default connect(mapStateToProps, {
  fetchPublicProduct,
  addItem,
  addVariableItem,
  downloadDocument
})(Product)