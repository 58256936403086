//forms
export const RESET_FORMS = 'RESET_FORMS'
export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE'
export const SEND_CONTACT_MESSAGE_FAILED = 'SEND_CONTACT_MESSAGE_FAILED'
export const BOOKING = 'BOOKING'
export const BOOKING_FAILED = 'BOOKING_FAILED'
export const PRODUCT_ENQUIRY = 'PRODUCT_ENQUIRY'
export const PRODUCT_ENQUIRY_FAILED = 'PRODUCT_ENQUIRY_FAILED'
export const NEWSLETTER_SIGNUP = 'NEWSLETTER_SIGNUP'
export const NEWSLETTER_SIGNUP_FAILED = 'NEWSLETTER_SIGNUP_FAILED'
export const FOOTER_NEWSLETTER_SIGNUP = 'FOOTER_NEWSLETTER_SIGNUP'
export const FOOTER_NEWSLETTER_SIGNUP_FAILED = 'FOOTER_NEWSLETTER_SIGNUP_FAILED'
export const DOWNLOAD_DOCUMENT_SUBMIT = 'DOWNLOAD_DOCUMENT_SUBMIT'
export const DOWNLOAD_DOCUMENT_SUBMIT_FAILED = 'DOWNLOAD_DOCUMENT_SUBMIT_FAILED'

//auth
export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

export const ACCEPT_COOKIE = 'ACCEPT_COOKIE'

//customer account
export const CUSTOMER_REGISTRATION = 'CUSTOMER_REGISTRATION'
export const CUSTOMER_REGISTRATION_FAILED = 'CUSTOMER_REGISTRATION_FAILED'
export const CUSTOMER_LOGIN = 'CUSTOMER_LOGIN'
export const CUSTOMER_LOGIN_FAILED = 'CUSTOMER_LOGIN_FAILED'
export const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT'
export const FETCH_CUSTOMER_ACCOUNT_DETAILS = 'FETCH_CUSTOMER_ACCOUNT_DETAILS'
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE'
export const CUSTOMER_UPDATE_FAILED = 'CUSTOMER_UPDATE_FAILED'
export const FETCH_CUSTOMER_ORDERS = 'FETCH_CUSTOMER_ORDERS'
export const FETCH_CUSTOMER_ORDER = 'FETCH_CUSTOMER_ORDER'

//password reset
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'
export const FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP'
export const FORGOT_PASSWORD_OTP_FAILED = 'FORGOT_PASSWORD_OTP_FAILED'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'
export const FORGOT_PASSWORD_RESET_FAILED = 'FORGOT_PASSWORD_RESET_FAILED'

//public
export const FETCH_PUBLIC_PRODUCTS = 'FETCH_PUBLIC_PRODUCTS'
export const FETCH_PUBLIC_PRODUCT = 'FETCH_PUBLIC_PRODUCT'
export const FETCH_PUBLIC_POSTS = 'FETCH_PUBLIC_POSTS'
export const FETCH_PUBLIC_POST = 'FETCH_PUBLIC_POST'
export const FETCH_PUBLIC_CATEGORIES = 'FETCH_PUBLIC_CATEGORIES'
export const FETCH_PUBLIC_CATEGORY = 'FETCH_PUBLIC_CATEGORY'
export const FETCH_PUBLIC_BRANDS = 'FETCH_PUBLIC_BRANDS'
export const FETCH_PUBLIC_BRAND = 'FETCH_PUBLIC_BRAND'

//shopping cart
export const ADD_ITEM = 'ADD_ITEM'
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const ITEM_QTY_INCREMENT = 'ITEM_QTY_INCREMENT'
export const ITEM_QTY_DECREMENT = 'ITEM_QTY_DECREMENT'
export const CLEAR_CART = 'CLEAR_CART'

export const ADD_VARIABLE_ITEM = 'ADD_VARIABLE_ITEM'
export const UPDATE_VARIABLE_QUANTITY = 'UPDATE_VARIABLE_QUANTITY'
export const REMOVE_VARIABLE_ITEM = 'REMOVE_VARIABLE_ITEM'

//checkout
export const CHECKOUT = 'CHECKOUT'
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED'
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS'
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT'

//coupon
export const GET_COUPON = 'GET_COUPON'
export const GET_COUPON_FAILED = 'GET_COUPON_FAILED'
export const REMOVE_COUPON = 'REMOVE_COUPON'

//dashboard
export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS_FAILES = 'UPDATE_ACCOUNT_DETAILS_FAILES'

export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const FETCH_PRODUCTS_BIN = 'FETCH_PRODUCTS_BIN'
export const FORCE_DELETE_PRODUCT = 'FORCE_DELETE_PRODUCTS'
export const DELETE_PRODUCT_VIDEO = 'DELETE_PRODUCT_VIDEO'

export const FETCH_VARIATIONS = 'FETCH_VARIATIONS'
export const CREATE_VARIATION = 'CREATE_VARIATION'
export const CREATE_VARIATION_FAILED = 'CREATE_VARIATION_FAILED'
export const FETCH_VARIATION = 'FETCH_VARIATION'
export const UPDATE_VARIATION = 'UPDATE_VARIATION'
export const UPDATE_VARIATION_FAILED = 'UPDATE_VARIATION_FAILED'
export const DELETE_VARIATION = 'DELETE_VARIATION'
export const RESTORE_VARIATION = 'RESTORE_VARIATION'
export const FETCH_VARIATIONS_BIN = 'FETCH_VARIATIONS_BIN'
export const FORCE_DELETE_VARIATION = 'FORCE_DELETE_VARIATIONS'

export const FETCH_IMAGES = 'FETCH_IMAGES'
export const CREATE_IMAGE = 'CREATE_IMAGE'
export const CREATE_IMAGE_FAILED = 'CREATE_IMAGE_FAILED'
export const FETCH_IMAGE = 'FETCH_IMAGE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const UPDATE_IMAGE_FAILED = 'UPDATE_IMAGE_FAILED'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const RESTORE_IMAGE = 'RESTORE_IMAGE'
export const FETCH_IMAGES_BIN = 'FETCH_IMAGES_BIN'
export const FORCE_DELETE_IMAGE = 'FORCE_DELETE_IMAGES'

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS'
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED'
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const UPDATE_DOCUMENT_FAILED = 'UPDATE_DOCUMENT_FAILED'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const RESTORE_DOCUMENT = 'RESTORE_DOCUMENT'
export const FETCH_DOCUMENTS_BIN = 'FETCH_DOCUMENTS_BIN'
export const FORCE_DELETE_DOCUMENT = 'FORCE_DELETE_DOCUMENTS'

export const FETCH_BRANDS = 'FETCH_BRANDS'
export const CREATE_BRAND = 'CREATE_BRAND'
export const CREATE_BRAND_FAILED = 'CREATE_BRAND_FAILED'
export const FETCH_BRAND = 'FETCH_BRAND'
export const UPDATE_BRAND = 'UPDATE_BRAND'
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED'
export const DELETE_BRAND = 'DELETE_BRAND'
export const RESTORE_BRAND = 'RESTORE_BRAND'
export const FETCH_BRANDS_BIN = 'FETCH_BRANDS_BIN'
export const FORCE_DELETE_BRAND = 'FORCE_DELETE_BRANDS'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const RESTORE_CATEGORY = 'RESTORE_CATEGORY'
export const FETCH_CATEGORIES_BIN = 'FETCH_CATEGORIES_BIN'
export const FORCE_DELETE_CATEGORY = 'FORCE_DELETE_CATEGORIES'

export const FETCH_TYPES = 'FETCH_TYPES'
export const CREATE_TYPE = 'CREATE_TYPE'
export const CREATE_TYPE_FAILED = 'CREATE_TYPE_FAILED'
export const FETCH_TYPE = 'FETCH_TYPE'
export const UPDATE_TYPE = 'UPDATE_TYPE'
export const UPDATE_TYPE_FAILED = 'UPDATE_TYPE_FAILED'
export const DELETE_TYPE = 'DELETE_TYPE'
export const RESTORE_TYPE = 'RESTORE_TYPE'
export const FETCH_TYPES_BIN = 'FETCH_TYPES_BIN'
export const FORCE_DELETE_TYPE = 'FORCE_DELETE_TYPES'

export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'

export const FETCH_POSTS = 'FETCH_POSTS'
export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED'
export const FETCH_POST = 'FETCH_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_FAILED = 'UPDATE_POST_FAILED'
export const DELETE_POST = 'DELETE_POST'
export const RESTORE_POST = 'RESTORE_POST'
export const FETCH_POSTS_BIN = 'FETCH_POSTS_BIN'
export const FORCE_DELETE_POST = 'FORCE_DELETE_POSTS'

export const FETCH_COUPONS = 'FETCH_COUPONS'
export const CREATE_COUPON = 'CREATE_COUPON'
export const CREATE_COUPON_FAILED = 'CREATE_COUPON_FAILED'
export const FETCH_COUPON = 'FETCH_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const UPDATE_COUPON_FAILED = 'UPDATE_COUPON_FAILED'
export const DELETE_COUPON = 'DELETE_COUPON'
export const RESTORE_COUPON = 'RESTORE_COUPON'
export const FETCH_COUPONS_BIN = 'FETCH_COUPONS_BIN'
export const FORCE_DELETE_COUPON = 'FORCE_DELETE_COUPON'

export const FETCH_DELIVERY_OPTIONS = 'FETCH_DELIVERY_OPTIONS'
export const CREATE_DELIVERY_OPTION = 'CREATE_DELIVERY_OPTION'
export const CREATE_DELIVERY_OPTION_FAILED = 'CREATE_DELIVERY_OPTION_FAILED'
export const FETCH_DELIVERY_OPTION = 'FETCH_DELIVERY_OPTION'
export const UPDATE_DELIVERY_OPTION = 'UPDATE_DELIVERY_OPTION'
export const UPDATE_DELIVERY_OPTION_FAILED = 'UPDATE_DELIVERY_OPTION_FAILED'
export const DELETE_DELIVERY_OPTION = 'DELETE_DELIVERY_OPTION'
export const RESTORE_DELIVERY_OPTION = 'RESTORE_DELIVERY_OPTION'
export const FETCH_DELIVERY_OPTIONS_BIN = 'FETCH_DELIVERY_OPTIONS_BIN'
export const FORCE_DELETE_DELIVERY_OPTION = 'FORCE_DELETE_DELIVERY_OPTION'