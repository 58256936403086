import {
    CUSTOMER_REGISTRATION,
    CUSTOMER_REGISTRATION_FAILED,
    CUSTOMER_LOGIN,
    CUSTOMER_LOGIN_FAILED,
    CUSTOMER_LOGOUT,
    CHECKOUT,
    RESET_FORMS
} from '../actions/types'

const initialState = {
    token: null,
    errorsLogin: null,
    errorsregister: null,
    errors: null
}

const customerAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_REGISTRATION:
            return {
                token: action.payload,
                errorsLogin: null,
                errorsregister: null,
                errors: null
            }
        case CUSTOMER_REGISTRATION_FAILED:
            return {
                token: null,
                errorsLogin: null,
                errorsregister: action.payload,
                errors: null
            }
        case CUSTOMER_LOGIN:
            return {
                token: action.payload,
                errorsLogin: null,
                errorsregister: null,
                errors: null
            }
        case CUSTOMER_LOGIN_FAILED:
            return {
                token: null,
                errorsLogin: action.payload,
                errorsregister: null,
                errors: null
            }

        case CHECKOUT:

            if(!action.payload.token)
            return state

            return {
                token: action.payload.token,
                errorsLogin: null,
                errorsregister: null,
                errors: null
            }

        case RESET_FORMS:
            return{
                ...state,
                errorsLogin: null,
                errorsregister: null,
                errors: null
            }

        case CUSTOMER_LOGOUT:
            return initialState

        default:
            return state
    }
}

export default customerAuthReducer