import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchOrder
} from '../../../actions/orders'
import { formatPrice, readableDate } from '../../../utils'
import {BASE_PATH} from '../../../paths'

class DashboardOrder extends React.Component {

    componentDidMount(){
        this.props.fetchOrder(this.props.token, this.props.match.params.orderId)
    }

    renderItems = items => {
      return items.map(({id,title,preview_image,quantity,price,total}) => {
        return (
          <tr key={id}>
            <td valign="middle">
              <img src={BASE_PATH+preview_image} alt="" style={{width: '60px'}} /> <p>&nbsp;{title} x {quantity}</p>
            </td>
            <td valign="middle">R{formatPrice(total)}</td>
          </tr>
        )
      })
    }

    renderContent = () => {
      if(!this.props.data)
      return <></>

      const {status, billing_name, billing_surname, billing_email, billing_phone, billing_province, billing_city, billing_address, billing_postal_code, billing_subtotal, billing_delivery_cost, billing_total, billing_discount, created_at, order_products} = this.props.data


      return (
        <>
          <table className='_dashboard-table' >
            <tbody>
              <tr>
                <td>Full Name</td>
                <td>{billing_name} {billing_surname}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{status}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{readableDate(created_at)}</td>
              </tr>
              <tr>
                <td>Billing Email</td>
                <td>{billing_email}</td>
              </tr>
              <tr>
                <td>Billing Phone Number</td>
                <td>{billing_phone}</td>
              </tr>
              <tr>
                <td>Billing Address</td>
                <td >
                  {billing_address}< br />
                  {billing_city}< br />
                  {billing_province}< br />
                  {billing_postal_code}< br />
                </td>
              </tr>
              {order_products && this.renderItems(order_products)}
              <tr>
                <td>Billing Subtotal</td>
                <td>R{formatPrice(billing_subtotal)}</td>
              </tr>
              <tr>
                <td>Billing Delivery</td>
                <td>R{formatPrice(billing_delivery_cost)}</td>
              </tr>
              <tr>
                <td>Billing Discount</td>
                <td>R{formatPrice(billing_discount)}</td>
              </tr>
              <tr>
                <td>Billing Total</td>
                <td>R{formatPrice(billing_total)}</td>
              </tr>
            </tbody>
          </table>
        </>
      )
    }

    render(){

        return(
            <DashboardLayout 
                heading={`Order #${this.props.match.params.orderId}`} 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/orders">Orders</Link> / Order #{this.props.match.params.orderId}
                </>)}
            >
                {this.renderContent()}
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        data: state.ordersState[ownProps.match.params.orderId]
    }
}

export default connect(mapStateToProps, {
    fetchOrder
})(DashboardOrder)