import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {
  fetchPublicPost
} from '../actions'
import {connect} from 'react-redux'
// import {BASE_PATH} from '../paths'
import Breadcrums from './ui/Breadcrums'
import {Link} from 'react-router-dom'
import {BASE_PATH} from '../paths'
import {
  FacebookShareButton,
  FacebookIcon
} from "react-share";
import CustomEmailShareButton from './ui/CustomEmailShareButton'

class BlogArticle extends React.Component {

  componentDidMount(){
    this.props.fetchPublicPost(this.props.match.params.slug)
  }

  render(){

    if(!this.props.data)
    return

    const {headline,summary,body,banner_image, meta_description, meta_keywords} = this.props.data

    return (
      <>
        <Seo
          title={`${headline} | Blog`}
          description={meta_description ? meta_description : summary}
          image={banner_image ? BASE_PATH+banner_image : null}
          keywords={meta_keywords ? meta_keywords : null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/blog" rel="index up up">Blog</Link>&nbsp;/&nbsp;
            {headline}
          </Breadcrums>
          
          <section className='_section _section-blog-article'>
            <div className='_image-box'>
              <div className='_column'>
                <div className='_panel'>
                  <img src={BASE_PATH+banner_image} alt={headline} className='_blog-page-image' width="1540" height="800" />
                </div>
              </div>
            </div>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  <h1 className='_page-heading'>{headline}</h1>
                  <div className='_border' style={{marginBottom: '30px'}} />
                  <div dangerouslySetInnerHTML={{__html: body}} />
                  <div className='_border' style={{marginBottom: '30px', marginTop: '40px'}} />
                  <div className='_blog-article-info _flex-box'>
                    <div className='_share'>
                      <h4>SHARE</h4>
                      
                      <div className='_flex'>
                        <FacebookShareButton 
                            url={window.location.href}
                            quote={headline}
                            hashtag="#beautyfiressa"
                        >
                            <FacebookIcon size={40} round iconFillColor="#0F0F0F" bgStyle={{fill: '#fff'}} />
                        </FacebookShareButton>
                        <CustomEmailShareButton 
                          subject={headline}
                          body={`Check out this blog from Beauty Fires by going to the following link: ${window.location.href}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.publicPostsState[ownProps.match.params.slug]
  }
}

export default connect(mapStateToProps, {
  fetchPublicPost
})(BlogArticle)