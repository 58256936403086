import _ from 'lodash'
import {
    FETCH_DELIVERY_OPTIONS,
    CREATE_DELIVERY_OPTION,
    FETCH_DELIVERY_OPTION,
    UPDATE_DELIVERY_OPTION,
    DELETE_DELIVERY_OPTION,
    RESTORE_DELIVERY_OPTION
} from '../../actions/types'

const initialState = {}

const deliveryOptionReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_DELIVERY_OPTIONS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_DELIVERY_OPTION:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_DELIVERY_OPTION:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_DELIVERY_OPTION:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_DELIVERY_OPTION:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_DELIVERY_OPTION:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default deliveryOptionReducer