import React from 'react'
import {Link} from 'react-router-dom'

class LoadMore extends React.Component{

  renderLoadmoreButton = (current_page, last_page) => {
    if(current_page === last_page)
    return <></>

    let newQueryString = this.setPageParams(current_page + 1)
    return <Link to={`/products?${newQueryString}`} className='_button'>LOAD MORE</Link>
  }

  setPageParams = number => {
    let urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.set('page', number)
    let newQueryString = urlSearchParams.toString()
    return newQueryString
  }

  render(){

    const {current_page, last_page} = this.props.meta

    if(last_page === 1)
    return

    return(
      <div className='_load-more'>
        {this.renderLoadmoreButton(current_page, last_page)}
      </div>
    )
  }
}

export default LoadMore