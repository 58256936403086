import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchUsers,
    deleteUser
} from '../../../actions/users'
import {
    fetchAccountDetails
} from '../../../actions/account'
import CustomDataTable from '../ui/CustomDataTable'

class DashboardUsers extends React.Component {

    componentDidMount(){
        this.props.fetchUsers(this.props.token)
        this.props.fetchAccountDetails(this.props.token)
    }

    onDelete = id => {
        this.props.deleteUser(this.props.token, id)
    }

    renderButtons = id => {

        if(!this.props.account){return <></>}

        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/users/${id}/edit`} >Edit</Link>
                {this.props.account.id !== id && <button className="_table-button _table-button-delete" onClick={() => this.onDelete(id)} >Delete</button>}
                
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'email',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Role',
                selector: row => row.role,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Users" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/users">Users</Link>
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/users/create" className='_button _button-2'>+ Create</Link>
                </div>
                
                <div className='_data-table'>
                <CustomDataTable 
                    data={this.props.data}
                    columns={columns}
                />
                </div>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.usersState),
        account: state.accountState ? state.accountState.details : null,
    }
}

export default connect(mapStateToProps, {
    fetchUsers,
    deleteUser,
    fetchAccountDetails
})(DashboardUsers)