import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Link} from 'react-router-dom'

class BookingThankyou extends React.Component {
  render(){
    return (
      <>
        <Seo
          title="Schedule A Meeting"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Schedule A Meeting / Message Sent
          </Breadcrums>
          <SimpleHeading>Message Sent</SimpleHeading>
          <section className='_section _section-thankyou'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  <p>We recieved your booking.<br />A sales consultant will be in touch with you soon to confirm your appointment.</p>
                  <br />
                  <Link to="/" className="_button">Go To Home Page</Link>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

export default BookingThankyou