import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import { createProduct } from '../../../actions/products'
import { fetchBrands } from '../../../actions/brands'
import { fetchCategories } from '../../../actions/categories'
import { fetchTypes } from '../../../actions/product-types'
import { fetchDeliveryOptions } from '../../../actions/delivery-options'

const Form = React.lazy(() => import('../../forms/ProductCreateForm'))

class DashboardProductCreate extends React.Component {

    componentDidMount(){
        this.props.fetchBrands(this.props.token)
        this.props.fetchCategories(this.props.token)
        this.props.fetchTypes(this.props.token)
        this.props.fetchDeliveryOptions(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createProduct(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/products" />
        }

        return(
            <DashboardLayout 
                heading="Create Product" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/products">Product</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/products" className='_button _button-3'>Back</Link>
                </div>
                <Suspense fallback={<></>}>
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        brands={Object.values(this.props.brands)}
                        categories={Object.values(this.props.categories)}
                        types={Object.values(this.props.types)}
                        deliveryOptions={Object.values(this.props.deliveryOptions)}
                    />
                </Suspense>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.productCreateState,
        brands: state.brandsState,
        categories: state.categoriesState,
        types: state.typesState,
        deliveryOptions: state.deliveryOptionsState
    }
}

export default connect(mapStateToProps, {
    createProduct,
    fetchBrands,
    fetchCategories,
    fetchTypes,
    fetchDeliveryOptions,
    resetForms
})(DashboardProductCreate)