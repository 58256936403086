import React from 'react'
import {Link} from 'react-router-dom'

class SimpleDropdown extends React.Component {

  renderListItems = () => {
    return this.props.data.map(({link, name}) => {
      return (
        <li key={name}>
          <Link to={link} className='_nav-link _nav-link-item'>{name}</Link>
        </li>
      )
    })
  }

  render(){
    return (
      <ul className='_simple-dropdown'>
        {this.renderListItems()}
      </ul>
    )
  }
}

export default SimpleDropdown