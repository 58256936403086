import admin from '../apis/admin'

import {
  FETCH_TYPES,
  CREATE_TYPE,
  CREATE_TYPE_FAILED,
  FETCH_TYPE,
  UPDATE_TYPE,
  UPDATE_TYPE_FAILED,
  DELETE_TYPE,
  RESTORE_TYPE,
  FETCH_TYPES_BIN,
  FORCE_DELETE_TYPE,
} from './types'

export const fetchTypes = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/types', config)

      dispatch({
          type: FETCH_TYPES,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch types failed')
  }
}

export const createType = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/types/create', formValues, config)

      dispatch({
          type: CREATE_TYPE,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_TYPE_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchType = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/types/' + id, config)

      dispatch({
          type: FETCH_TYPE,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch type failed')
  }
}

export const updateType = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/types/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_TYPE,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_TYPE_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteType = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/types/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_TYPE,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete type failed')        
  }
}

export const fetchTypesBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/types/bin', config)

      dispatch({
          type: FETCH_TYPES_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch types bin failed')
  }
}

export const restoreType = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/types/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_TYPE,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Type restore failed')
  }
}

export const forceDeleteType = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/types/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_TYPE,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete type failed')        
  }
}