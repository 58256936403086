import {
    FETCH_PUBLIC_PRODUCT,
} from '../actions/types'

const initialState = {}

const publicProductReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PRODUCT:
            return {
              ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicProductReducer