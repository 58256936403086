import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
  fetchPublicBrands
} from '../../actions'
import {scrollToPosition} from '../../utils'

class AccordionMenu extends React.Component {

  componentDidMount(){
    this.props.fetchPublicBrands()
    this.collapseAll()
    this.setAccordionMagic()

    if(window.innerWidth >= 768){
      this.openFirstTier()
      // this.openAll()
    }
  }

  collapseAll = () => {
    const lists = document.querySelectorAll("._accordion-list")
    lists.forEach(list => {
      list.classList.add('_collapse')
    });
  }

  setAccordionMagic = () => {
    const buttons = document.querySelectorAll("._accordion-button")

    buttons.forEach(button => {

      let dropdown = button.nextElementSibling

      button.addEventListener("click", function(e){
        
        if(e.target.classList.contains('_arrow')){
          return
        }

        if(button.textContent === "Brands"){
          return
        }

        scrollToPosition("#pagebanner", 300)

        // buttons.forEach(elm => {
        //   elm.classList.remove('_active')
        // })
        // button.classList.add('_active')
        
      })

      if(!dropdown)
      return

      let arrow = document.createElement("div")
      arrow.classList.add("_arrow")
      button.appendChild(arrow)

      arrow.addEventListener("click", function(e){
        e.preventDefault()
        dropdown.classList.toggle('_collapse')
        arrow.classList.toggle('_open')
      })
    });
  }

  openFirstTier = () => {
    const firstTierButtons = document.querySelectorAll("._first-tier")

    firstTierButtons.forEach(firstTierButton => {

      let arrow = firstTierButton.lastChild

      if(!arrow)
      return

      if(!arrow.classList)
      return

      if(!arrow.classList.contains('_arrow'))
      return
      
      arrow.click()
    });
  }

  openAll = () => {
    const lists = document.querySelectorAll("._accordion-list")

    lists.forEach(list => {

      list.classList.remove('_collapse')
      let arrow = list.previousElementSibling.lastChild

      if(!arrow)
      return

      if(!arrow.classList)
      return

      if(!arrow.classList.contains('_arrow'))
      return
      
      arrow.classList.add('_open')
    });
  }

  renderBrands = () => {
    return this.props.brands.map(({title, slug}) => {
      return (
        <div className='_accordion-item' key={slug}>
          <Link to={`/products?brand=${slug}`} className='_accordion-button' onClick={() => scrollToPosition("#pagebanner", 300)}>{title}</Link>
        </div>
      )
    })
  }

  render(){
    return(
      <div className='_accordion'>

        <div className='_accordion-item'>
          <Link to="/products?location=indoor" className='_accordion-button _first-tier'>Indoor Living</Link>
          <div className='_accordion-list'>

            <div className='_accordion-item'>
              <Link to="/products?location=indoor&category=braais" className='_accordion-button'>Braais</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=braais&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=braais&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=indoor&category=fireplaces" className='_accordion-button'>Fireplaces</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=fireplaces&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=fireplaces&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=fireplaces&fuel=bioethanol" className='_accordion-button'>Bioethanol</Link>
                </div>
              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=indoor&category=fire-pits" className='_accordion-button'>Fire Pits</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=fire-pits&fuel=bioethanol" className='_accordion-button'>Bioethanol</Link>
                </div>

              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=indoor&category=pizza-ovens" className='_accordion-button'>Pizza Ovens</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=pizza-ovens&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=indoor&category=pizza-ovens&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className='_accordion-item'>
          <Link to="/products?location=outdoor" className='_accordion-button _first-tier'>Outdoor Living</Link>
          <div className='_accordion-list'>

            <div className='_accordion-item'>
              <Link to="/products?location=outdoor&category=braais" className='_accordion-button'>Braais</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=braais&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=braais&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=braais&fuel=charcoal" className='_accordion-button'>Charcoal</Link>
                </div>

              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=outdoor&category=fireplaces" className='_accordion-button'>Fireplaces</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fireplaces&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fireplaces&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fireplaces&fuel=bioethanol" className='_accordion-button'>Bioethanol</Link>
                </div>
              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=outdoor&category=fire-pits" className='_accordion-button'>Fire Pits</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fire-pits&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fire-pits&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fire-pits&fuel=bioethanol" className='_accordion-button'>Bioethanol</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=fire-pits&fuel=charcoal" className='_accordion-button'>Charcoal</Link>
                </div>

              </div>
            </div>

            <div className='_accordion-item'>
              <Link to="/products?location=outdoor&category=pizza-ovens" className='_accordion-button'>Pizza Ovens</Link>
              <div className='_accordion-list'>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=pizza-ovens&fuel=gas" className='_accordion-button'>Gas</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=pizza-ovens&fuel=wood" className='_accordion-button'>Wood</Link>
                </div>

                <div className='_accordion-item'>
                  <Link to="/products?location=outdoor&category=pizza-ovens&fuel=charcoal" className='_accordion-button'>Charcoal</Link>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className='_accordion-item'>
          <Link to="/brands" className='_accordion-button _first-tier' onClick={e => e.preventDefault()}>Brands</Link>
          <div className='_accordion-list'>

            {this.props.brands.length > 0 && this.renderBrands()}

          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    brands: Object.values(state.publicBrandsState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicBrands
})(AccordionMenu)