import {Link} from 'react-router-dom'
import SimpleDropdown from './SimpleDropdown'

const contacts = [
  {
    link: '/contact/cape-town',
    name: 'Cape Town'
  },
  {
    link: '/contact/johannesburg',
    name: 'Johannesburg'
  },
  {
    link: '/contact/garden-route',
    name: 'Garden Route'
  }
]

const RightNav = () => {
  return (
    <nav className='_nav _header-nav _right-nav _flex-item _widescreen'>
      <ul>
        <li>
          <Link to="/contact/cape-town" className='_nav-link _with-dropdown-arrow' onClick={e => e.preventDefault()}>Contact Us</Link>
          <SimpleDropdown data={contacts} />
        </li>
        <li>
          <Link to="/booking" className='_button book-a-site-visit'>Book a site visit</Link>
        </li>
        <li>
          <Link to="/virtual-tour" className='_button virtual-tour'>Virtual Tour</Link>
        </li>
      </ul>
    </nav>
  )
}

export default RightNav