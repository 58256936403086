import {
    DOWNLOAD_DOCUMENT_SUBMIT,
    DOWNLOAD_DOCUMENT_SUBMIT_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {
    success: null,
    errors: null
}

const downloadDocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case DOWNLOAD_DOCUMENT_SUBMIT:
            return {
                success: action.payload,
                errors: null
            }
        case DOWNLOAD_DOCUMENT_SUBMIT_FAILED:
            return {
                success: null,
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default downloadDocumentReducer