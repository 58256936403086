import _ from 'lodash'
import {
    FETCH_TYPES_BIN,
    RESTORE_TYPE,
    FORCE_DELETE_TYPE
} from '../../actions/types'

const initialState = {}

const typesBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_TYPES_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_TYPE:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_TYPE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default typesBinReducer