import admin from '../apis/admin'

import {
  FETCH_PRODUCTS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  DELETE_PRODUCT,
  RESTORE_PRODUCT,
  FETCH_PRODUCTS_BIN,
  FORCE_DELETE_PRODUCT,
  DELETE_PRODUCT_VIDEO
} from './types'

export const fetchProducts = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/products', config)

      dispatch({
          type: FETCH_PRODUCTS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch products failed')
  }
}

export const createProduct = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/products/create', formValues, config)

      dispatch({
          type: CREATE_PRODUCT,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_PRODUCT_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchProduct = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/products/' + id, config)

      dispatch({
          type: FETCH_PRODUCT,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch product failed')
  }
}

export const updateProduct = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/products/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_PRODUCT,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_PRODUCT_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteProduct = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/products/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_PRODUCT,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete product failed')        
  }
}

export const fetchProductsBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/products/bin', config)

      dispatch({
          type: FETCH_PRODUCTS_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch products bin failed')
  }
}

export const restoreProduct = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/products/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_PRODUCT,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Product restore failed')
  }
}

export const forceDeleteProduct = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/products/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_PRODUCT,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete product failed')        
  }
}

export const deleteProductVideo = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/' + id + '/video/delete', {}, config)
  
        dispatch({
            type: DELETE_PRODUCT_VIDEO,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Delete product video failed')        
    }
  }