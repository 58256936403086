import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {
  fetchPublicProducts,
  fetchPublicBrands,
  fetchPublicCategories
} from '../actions'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Breadcrums from './ui/Breadcrums'
import PageBanner from './ui/PageBanner'
import AccordionMenu from './ui/AccordionMenu'
import ProductItem from './views/ProductItem'
import SimpleHeading from './ui/SimpleHeading'
import LoadMore from './ui/LoadMore'
import {BASE_PATH} from '../paths'
import {renderFilterInfoString} from '../utils'
import SearchProducts from './ui/SearchProducts'

// banners
import indoorBanner from '../assets/img/products/IndoorLiving/indoor-living@2x.jpg'
import outdoorBanner from '../assets/img/products/OutdoorLiving/outdoor-living@2x.jpg'
import allProductsBanner from '../assets/img/products-banner@2x.jpg'
import ogImage from '../assets/img/image-divider.jpg'

class Products extends React.Component {

  state = {
    queryString: window.location.search,
    location: null,
    category: null,
    fuel: null,
    brand: null
  }

  componentDidMount(){
    this.props.fetchPublicProducts(window.location.search)
    this.props.fetchPublicBrands()
    this.props.fetchPublicCategories()
    this.setPageDataState()
  }

  componentDidUpdate() {
    if (window.location.search !== this.state.queryString) {
      this.setPageDataState()
      this.props.fetchPublicProducts(window.location.search)
    }
  }

  setPageDataState = () => {
    this.setState({queryString: window.location.search,})
  }

  renderProductItems = () => {

    if(!this.props.data.data)
    return

    return Object.values(this.props.data.data).map(({id, title, slug, preview_image, status}) => {
      if(status !== 'public'){
        return <></>
      }

      return  (
        <div className='_column' key={id}>
          <ProductItem name={title} link={`/products/${slug}`} image={BASE_PATH+preview_image} />
        </div>
      )
    })
  }

  renderNotFound = () => {
    return (
      <>
        <Seo
          title={`Products`}
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/products/" rel="up up">Products</Link>
          </Breadcrums>
          <SimpleHeading>Not Found</SimpleHeading>
          <section className='_section _section-products'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-box'>
                  <div className='_filter'>
                    <SearchProducts />
                    <AccordionMenu />
                  </div>
                  <div className='_results' id="results">
                    <div className='_product-list'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }

  renderAllProducts = () => {
    return (
      <>
        <Seo
          title={`Products`}
          description="Beauty Fires works closely with architects and designers to create iconic indoor/outdoor products that are of the highest quality and become the centrepiece of your home."
          image={ogImage}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/products/" rel="up up">Products</Link>
          </Breadcrums>
          <PageBanner 
            image={allProductsBanner}
            heading="Products"
          >
            <div>
              <p>Beauty Fires works closely with architects and designers to create iconic indoor/outdoor products that are of the highest quality and become the centrepiece of your home.</p>
            </div>
            <div className='_horline' />
          </PageBanner>
          
          <section className='_section _section-products'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-box'>
                  <div className='_filter'>
                    <SearchProducts />
                    <AccordionMenu />
                  </div>
                  <div className='_results' id="results">
                    <div className='_product-list'>
                      {this.renderProductItems()}
                    </div>
                    {this.props.data.meta && <LoadMore meta={this.props.data.meta} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }

  renderPageContent = data => {

    let {title, description, banner_image, banner_image_static, logo} = data

    let image = null

    if(banner_image)
    image = BASE_PATH+banner_image

    if(banner_image_static)
    image = banner_image_static

    return (
      <>
        <Seo
          title={`${title ? title + ' | ' : ''}Products`}
          description={description ? description : null}
          image={image ? image : ogImage}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/products/" rel="up up">Products</Link>
            {
              title && (
                <>&nbsp;/&nbsp;{title}</>
              )
            }
          </Breadcrums>
          {
            title && (
              <PageBanner 
                image={image ? image : null}
                heading={title ? title : null}
                logo={logo ? BASE_PATH+logo : null}
              >
                <div dangerouslySetInnerHTML={{__html: description}} />
                <div className='_horline' />
              </PageBanner>
            )
          }
          
          {
            !title && (
              <SimpleHeading>Products</SimpleHeading>
            )
          }
          
          <section className='_section _section-products'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_flex-box'>
                  <div className='_filter'>
                    <SearchProducts />
                    <AccordionMenu />
                  </div>
                  <div className='_results' id="results">
                    <div className='_filter-info'>
                      <p>
                        {renderFilterInfoString()} 
                      </p>
                    </div>
                    <div className='_product-list'>
                      {this.renderProductItems()}
                    </div>
                    {this.props.data.meta && <LoadMore meta={this.props.data.meta} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }

  render(){

    let urlSearchParams = new URLSearchParams(window.location.search)

    let data = null

    if(urlSearchParams.has('brand')){

      let slug = urlSearchParams.get('brand')

      if(!this.props.brands[slug])
      return this.renderNotFound()

      data = this.props.brands[slug]

      return this.renderPageContent(data)
    } 
    
    if (urlSearchParams.has('category')){

      let slug = urlSearchParams.get('category')

      if(!this.props.categories[slug])
      return this.renderNotFound()

      data = this.props.categories[slug]

      return this.renderPageContent(data)
    }

    if (urlSearchParams.has('location')){

      let slug = urlSearchParams.get('location')

      data = {
        indoor: {
          title: 'INDOOR LIVING',
          description: `<p>Transform your home with our selection of indoor fireplaces and lifestyle cooking products that will enhance your living experience and increase your home’s value.</p>`,
          banner_image_static: indoorBanner
        },
        outdoor: {
          title: 'OUTDOOR LIVING',  
          description: `<p>Our outdoor fireplaces, fire pits, braais and outdoor lifestyle cooking products make a stunning addition to any outdoor entertainment area or braai room.</p>`,
          banner_image_static: outdoorBanner
        }
      }

      if(!data[slug])
      return this.renderNotFound()

      return this.renderPageContent(data[slug])
    }

    return this.renderAllProducts()
  }
}

const mapStateToProps = state => {
  return {
    data: state.publicProductsState,
    brands: state.publicBrandsState,
    categories: state.publicCategoriesState
  }
}

export default connect(mapStateToProps, {
  fetchPublicProducts,
  fetchPublicBrands,
  fetchPublicCategories
})(Products)