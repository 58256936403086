import admin from '../apis/admin'

import {
  FETCH_VARIATIONS,
  CREATE_VARIATION,
  CREATE_VARIATION_FAILED,
  FETCH_VARIATION,
  UPDATE_VARIATION,
  UPDATE_VARIATION_FAILED,
  DELETE_VARIATION,
  RESTORE_VARIATION,
  FETCH_VARIATIONS_BIN,
  FORCE_DELETE_VARIATION,
} from './types'

export const fetchVariations = (token, productId) => async dispatch => {
    
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/variations`, config)

      dispatch({
          type: FETCH_VARIATIONS,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch variations failed')
  }
}

export const createVariation = (token, productId, formValues) => async dispatch => {
  
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/variations/create`, formValues, config)

      dispatch({
          type: CREATE_VARIATION,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_VARIATION_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchVariation = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/variations/${id}`, config)

      dispatch({
          type: FETCH_VARIATION,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Fetch variation failed')
  }
}

export const updateVariation = (token,  productId, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/variations/${id}/update`, formValues, config)

      dispatch({
          type: UPDATE_VARIATION,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_VARIATION_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteVariation = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/variations/${id}/delete`, {}, config)

      dispatch({
          type: DELETE_VARIATION,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Delete variation failed')        
  }
}

export const fetchVariationsBin = (token, productId) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/variations/bin`, config)

      dispatch({
          type: FETCH_VARIATIONS_BIN,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch variations bin failed')
  }
}

export const restoreVariation = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/variations/${id}/restore`, {}, config)

      dispatch({
          type: RESTORE_VARIATION,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Variation restore failed')
  }
}

export const forceDeleteVariation = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/variations/${id}/delete`, {}, config)

      dispatch({
          type: FORCE_DELETE_VARIATION,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Force delete variation failed')        
  }
}