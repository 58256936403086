import admin from '../apis/admin'

import {
  FETCH_POSTS,
  CREATE_POST,
  CREATE_POST_FAILED,
  FETCH_POST,
  UPDATE_POST,
  UPDATE_POST_FAILED,
  DELETE_POST,
  RESTORE_POST,
  FETCH_POSTS_BIN,
  FORCE_DELETE_POST,
} from './types'

export const fetchPosts = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/posts', config)

      dispatch({
          type: FETCH_POSTS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch posts failed')
  }
}

export const createPost = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/posts/create', formValues, config)

      dispatch({
          type: CREATE_POST,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_POST_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchPost = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/posts/' + id, config)

      dispatch({
          type: FETCH_POST,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch post failed')
  }
}

export const updatePost = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/posts/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_POST,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_POST_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deletePost = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/posts/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_POST,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete post failed')        
  }
}

export const fetchPostsBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/posts/bin', config)

      dispatch({
          type: FETCH_POSTS_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch posts bin failed')
  }
}

export const restorePost = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/posts/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_POST,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Post restore failed')
  }
}

export const forceDeletePost = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/posts/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_POST,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete post failed')        
  }
}