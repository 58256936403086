import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchAccountDetails,
    updateAccountDetails
} from '../../../actions/account'
import Form from '../../forms/AccountForm'

class DashboardAccount extends React.Component {

    componentDidMount(){
        this.props.fetchAccountDetails(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateAccountDetails(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/users" />
        }

        return(
            <DashboardLayout 
                heading="My Account" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / Account
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.authState.token,
        initialValues: state.accountState ? state.accountState.details : null,
        data: state.accountUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchAccountDetails,
    resetForms,
    updateAccountDetails
})(DashboardAccount)