import _ from 'lodash'
import {
  ADD_ITEM,
  ADD_VARIABLE_ITEM,
  UPDATE_QUANTITY,
  UPDATE_VARIABLE_QUANTITY,
  CLEAR_CART,
  REMOVE_ITEM,
  REMOVE_VARIABLE_ITEM,
  GET_COUPON,
  REMOVE_COUPON
} from '../actions/types'
import {
    setCartExp
} from '../utils'

//initial state object before any items has been added to the cart
const intialState = {
    items: {},
    variableItems: {},
    totalQuantity: 0,
    coupon: null,
    subTotalCost: 0,
    deliveryCost: 0,
    discount: 0,
    totalCost: 0,
    expired: false
}

const cartReducer = (state = intialState, action) => {

    let quantity = 0
    let diff = 0
    let price = 0
    let total = 0
    let deliveryDiff = 0
    let deliveryPrice = 0
    let deliveryTotal = 0
    let newQuantity = 0
    let subTotalCost = 0
    let discount = 0
    let totalCost = 0
    let deliveryCost = 0

    const calculateDiscount = (coupon, total) => {
        switch (coupon.type) {
            case 'fixed':
                return coupon.value
            case 'percentage':
                return Math.round((coupon.percent_off / 100) * total);
            default:
                return 0;
        }
    }

    switch(action.type){

        case ADD_ITEM:

            //checks if item exists in cart state and if so get the current item quantity
            if(state.items[action.payload.id]){
                quantity = state.items[action.payload.id].quantity
            }

            newQuantity = quantity + action.qty
            price = parseInt(action.payload.price)
            deliveryPrice = action.payload.delivery ? parseInt(action.payload.delivery.price) : 0
            total = newQuantity * price
            deliveryTotal = newQuantity * deliveryPrice
            diff = action.qty * price
            deliveryDiff = action.qty * deliveryPrice
            subTotalCost = diff + state.subTotalCost
            deliveryCost = deliveryDiff + state.deliveryCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost;
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalQuantity: state.totalQuantity + action.qty,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        id: action.payload.id,
                        title: action.payload.title,
                        slug: action.payload.slug,
                        price,
                        deliveryPrice,
                        quantity: newQuantity,
                        preview_image: action.payload.preview_image,
                        total,
                        deliveryTotal,
                    }
                },
                expired: false
            }

        case ADD_VARIABLE_ITEM:

            let {selected_variation} = action.payload

            //checks if item exists in cart state and if so get the current item quantity
            if(state.variableItems[selected_variation.id]){
                quantity = state.variableItems[selected_variation.id].quantity
            }

            newQuantity = quantity + action.qty
            price = parseInt(selected_variation.price)
            deliveryPrice = selected_variation.delivery ? parseInt(selected_variation.delivery.price) : 0
            total = newQuantity * price
            deliveryTotal = newQuantity * deliveryPrice
            diff = action.qty * price
            deliveryDiff = action.qty * deliveryPrice
            subTotalCost = diff + state.subTotalCost
            deliveryCost = deliveryDiff + state.deliveryCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost;
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalQuantity: state.totalQuantity + action.qty,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                variableItems: {
                    ...state.variableItems,
                    [selected_variation.id]: {
                        id: selected_variation.id,
                        title: `${selected_variation.title} - ${selected_variation.description}`,
                        slug: action.payload.slug,
                        price,
                        deliveryPrice,
                        quantity: newQuantity,
                        preview_image: action.payload.preview_image,
                        total,
                        deliveryTotal,
                    }
                },
                expired: false
            }
            
        case UPDATE_QUANTITY:

            if(!state.items[action.id])
            return state

            quantity = state.items[action.id].quantity
            price = state.items[action.id].price
            deliveryPrice = state.items[action.id].deliveryPrice
            diff = (action.qty - quantity) * price
            deliveryDiff = (action.qty - quantity) * deliveryPrice
            total = action.qty * price
            deliveryTotal = action.qty * deliveryPrice
            subTotalCost = diff + state.subTotalCost
            deliveryCost = deliveryDiff + state.deliveryCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                totalQuantity: state.totalQuantity + action.qty - quantity,
                items: {
                    ...state.items,
                    [action.id]: {
                        id: action.id,
                        title: state.items[action.id].title,
                        price,
                        deliveryPrice,
                        quantity: action.qty,
                        preview_image: state.items[action.id].preview_image,
                        total,
                        deliveryTotal
                    }
                },
                expired: false
            }

        case UPDATE_VARIABLE_QUANTITY:

            if(!state.variableItems[action.id])
            return state

            quantity = state.variableItems[action.id].quantity
            price = state.variableItems[action.id].price
            deliveryPrice = state.variableItems[action.id].deliveryPrice
            diff = (action.qty - quantity) * price
            deliveryDiff = (action.qty - quantity) * deliveryPrice
            total = action.qty * price
            deliveryTotal = action.qty * deliveryPrice
            subTotalCost = diff + state.subTotalCost
            deliveryCost = deliveryDiff + state.deliveryCost
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                discount,
                totalCost,
                totalQuantity: state.totalQuantity + action.qty - quantity,
                variableItems: {
                    ...state.variableItems,
                    [action.id]: {
                        id: action.id,
                        title: state.variableItems[action.id].title,
                        price,
                        deliveryPrice,
                        quantity: action.qty,
                        preview_image: state.variableItems[action.id].preview_image,
                        total,
                        deliveryTotal
                    }
                },
                expired: false
            }

        case GET_COUPON:

            discount = calculateDiscount(action.payload, state.subTotalCost)
            totalCost = state.subTotalCost - discount + state.deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                discount,
                totalCost,
                coupon: action.payload,
                expired: false
            }

        case REMOVE_COUPON:

            setCartExp()

            return{
                ...state,
                discount: 0,
                totalCost: state.subTotalCost + state.deliveryCost,
                coupon: null,
                expired: false
            }

        case REMOVE_ITEM:

            if(!state.items[action.id])
            return state

            quantity = state.items[action.id].quantity
            total = state.items[action.id].total
            deliveryTotal = state.items[action.id].deliveryTotal
            subTotalCost = state.subTotalCost - total
            deliveryCost = state.deliveryCost - deliveryTotal
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                totalCost,
                totalQuantity: state.totalQuantity - quantity, 
                items: _.omit(state.items, action.id),
                expired: false
            }

        case REMOVE_VARIABLE_ITEM:

            if(!state.variableItems[action.id])
            return state

            quantity = state.variableItems[action.id].quantity
            total = state.variableItems[action.id].total
            deliveryTotal = state.variableItems[action.id].deliveryTotal
            subTotalCost = state.subTotalCost - total
            deliveryCost = state.deliveryCost - deliveryTotal
            discount = state.coupon ? calculateDiscount(state.coupon, subTotalCost) : 0
            totalCost = subTotalCost - discount + deliveryCost
            if(totalCost < 0){
                totalCost = 0
            }

            setCartExp()

            return{
                ...state,
                subTotalCost,
                deliveryCost,
                totalCost,
                totalQuantity: state.totalQuantity - quantity, 
                variableItems: _.omit(state.variableItems, action.id),
                expired: false
            }

        case CLEAR_CART:

            if(action.expired){
                state = intialState
                return {...state, expired: true}
            }

            return intialState

        default:
            return state
    }
}

export default cartReducer