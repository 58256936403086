import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchOrders
} from '../../../actions/orders'
import CustomDataTable from '../ui/CustomDataTable'
import { formatPrice, readableDate } from '../../../utils'

class DashboardOrders extends React.Component {

    componentDidMount(){
        this.props.fetchOrders(this.props.token)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/orders/${id}`} >View</Link>
            </div>
        )
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Full Name',
                selector: row => row.billing_name + ' ' + row.billing_surname,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.billing_email,
                sortable: true,
            },
            {
                name: 'Total',
                selector: row => 'R' + formatPrice(row.billing_total),
                sortable: true,
            },
            {
                name: 'Date',
                selector: row => readableDate(row.created_at),
                sortable: true,
            },
            {
                name: 'Status',
                selector: row => row.status,
                sortable: true,
            },
            {
                name: 'Actions',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '12px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Orders" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/orders">Orders</Link>
                </>)}
            >
                
                <CustomDataTable 
                    data={this.props.data}
                    columns={columns}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.ordersState)
    }
}

export default connect(mapStateToProps, {
    fetchOrders
})(DashboardOrders)