import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Link} from 'react-router-dom'

class SubscribedThankyou extends React.Component {

  render(){

    return (
      <>
        <Seo
          title="Subscribed | Newsletter Subscription"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Newsletter Subscription / Subscibed Successfully
          </Breadcrums>
          <SimpleHeading>Subscribed</SimpleHeading>
          <section className='_section _section-thankyou'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  <p>Thank you for subscribing to Beauty Fires.<br />Stay tuned for our latest news.</p>
                  <br />
                  <Link to="/" className="_button">Go To Home Page</Link>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

export default SubscribedThankyou