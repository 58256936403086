import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchProducts
} from '../../../actions/products'
import {
    fetchVariation,
    updateVariation
} from '../../../actions/variations'
import { fetchDeliveryOptions } from '../../../actions/delivery-options'
import Form from '../../forms/VariationEditForm'

class DashboardVariationEdit extends React.Component {

    componentDidMount(){
        this.props.fetchVariation(this.props.token, this.props.match.params.productId, this.props.match.params.variationId)
        this.props.fetchProducts(this.props.token)
        this.props.fetchDeliveryOptions(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateVariation(this.props.token, this.props.match.params.productId, this.props.match.params.variationId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to={`/dashboard/products/${this.props.match.params.productId}/variations`} />
        }

        return(
            <DashboardLayout 
                heading="Edit Variation" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/variations`}>Variations</Link> / {this.props.match.params.variationId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/variations`} className='_button _button-2'>Back</Link>
                </div>

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                        products={this.props.products ? this.props.products : null}
                        deliveryOptions={Object.values(this.props.deliveryOptions)}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.variationsState[ownProps.match.params.productId] && state.variationsState[ownProps.match.params.productId][ownProps.match.params.variationId],
        data: state.variationUpdateState,
        products: Object.values(state.productsState),
        deliveryOptions: state.deliveryOptionsState
    }
}

export default connect(mapStateToProps, {
    fetchVariation,
    updateVariation,
    resetForms,
    fetchProducts,
    fetchDeliveryOptions
})(DashboardVariationEdit)