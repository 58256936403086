import _ from 'lodash'
import {
    FETCH_DELIVERY_OPTIONS_BIN,
    RESTORE_DELIVERY_OPTION,
    FORCE_DELETE_DELIVERY_OPTION
} from '../../actions/types'

const initialState = {}

const deliveryOptionsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_DELIVERY_OPTIONS_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_DELIVERY_OPTION:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_DELIVERY_OPTION:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default deliveryOptionsBinReducer