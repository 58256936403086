import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import Form from './forms/PasswordForgotOtpForm'
import {forgotPasswordOtp} from '../actions'
import {connect} from 'react-redux'

class ForgotPasswordOtp extends React.Component {

  onSubmit = formValues => {
    this.props.forgotPasswordOtp(formValues)
  }

  render(){

    return (
      <>
        <Seo
          title="Forgot Password OTP"
          description={null}
          image={null}
        ><meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Forgot Password&nbsp;/&nbsp;OTP
          </Breadcrums>
          <SimpleHeading>Forgot Password</SimpleHeading>
          <section className='_section _section-not-found'>
            <div className='_grid'>
              <div className='_column'>   
                <div className='_form-panel'>
                  <h1 className='_page-heading'>Verify it's you</h1>
                  <Form
                      onSubmit={this.onSubmit} 
                      errors={this.props.send.errors ? this.props.send.errors : null}
                  />
                </div>
              </div>    
            </div>    
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return{
    send: state.forgotPasswordOtpState
  }
}

export default connect(mapStateToProps, {
  forgotPasswordOtp
})(ForgotPasswordOtp)