import _ from 'lodash'
import {
    FETCH_VARIATIONS_BIN,
    RESTORE_VARIATION,
    FORCE_DELETE_VARIATION
} from '../../actions/types'

const initialState = {}

const variationsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_VARIATIONS_BIN:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }  
        case RESTORE_VARIATION:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload.id)
            }
        case FORCE_DELETE_VARIATION:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default variationsBinReducer