import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'

class ShippingPolicy extends React.Component {

  render(){

    return (
      <>
        <Seo
          title="Shipping Policy"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Shipping Policy
          </Breadcrums>
          <SimpleHeading>Shipping Policy</SimpleHeading>
          <section className='_section _section-terms'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel'>
                  <div classname="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-gray-100 prose">
                    <h1 classname="font-bold text-4xl text-gray-100 pb-2 border-b-2 max-w-fit mb-12">Shipping Policy</h1>
                    <p>To ensure safe arrival we take the utmost care to package all goods that leave our warehouses.</p>
                    <p>We will always do our best to get your order to you as quickly as possible. We use a number of courier services for security and speed. After receiving your order and payment we will ship as soon as possible.</p>
                    <p>Some of our products come from third party suppliers and in this there can be delays. You will receive an email within 1 business day from order detailing the lead time of your order. We will notify you ASAP if any item is out of stock. If your order is urgent, just let us know and we will do what we can to help.</p>
                    <p>PLEASE NOTE<br />Once a parcel is with the shipping company, though we will do all we can to facilitate a speedy delivery, it is no longer in our control. In regards to time frames, we can pass on the information the shipping company gives us, however unexpected delays can happen. If you have any trouble with a delivery, please contact us and we will do everything we can to help solve the problem.</p>
                    <h2 classname="font-bold text-gray-100">STANDARD DELIVERY</h2>
                    <p>Standard delivery provides ground delivery to the easiest access point on your property and does not include inside delivery, packaging removal, or assembly.</p>
                    <h2 classname="font-bold text-gray-100">SHIPPING RATES</h2>
                    <p>Are live, provide options & are calculated at check out.</p>
                    <h2 classname="font-bold text-gray-100">ORDER TIMES</h2>
                    <p>Generally, within South Africa, orders should be delivered within 5-10 business days of your order being placed if the product is in stock, unless you are notified to the contrary by our staff within 1 business day of placing your order and agree that delivery will be outside this time.</p>
                    <p>Beauty Fires will make every effort to get your items to you as quickly as possible.</p>
                    <h2 classname="font-bold text-gray-100">INTERNATIONAL DELIVERIES</h2>
                    <p>We are happy and able to ship internationally. Your orders will generally be shipped via DHL or relevant shipping agency or courier.
                      The delivery cost will be calculated at checkout when you update your location details. For large or bulky items or for deliveries outside of our normal distribution areas, the shipping costs will be calculated separately and will be communicated to you via email.</p>
                    <p>Please be ready to provide the following information when seeking international shipping quotes:<br />
                    &#x2714; Model number of item/s<br />
                    &#x2714; Quantity required of item/s<br />
                    &#x2714; Postcode<br />
                    &#x2714; Suburb<br />
                    &#x2714; Country
                    </p>
                    <h2 classname="font-bold text-gray-100">I'VE RECEIVED DAMAGED OR INCORRECT ITEMS</h2>
                    <p>IMPORTANT If there are visible signs of damage to the packaging on arrival write ‘damaged’ instead of signing for the delivery, this will make returning the product much simpler.</p>
                    <p>Please ensure that you check your order within 48 hours of receipt. If you have received a damaged, faulty, or incorrect item please contact us by email, including photographs of the item. We ask for this information and photos during this period to ensure that we can offer repairs or replacements if necessary. Warranties will remain in place and will be honoured for any covered issues.</p>
                    <p>It's recommended on taking receipt of your goods to check them before booking tradespeople for installation. This will help ensure that you have received the correct products and that they are in good order before tradespeople arrive.
                      Please feel free to contact us if we can assist in any way.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

export default ShippingPolicy