import _ from 'lodash'
import {
  FETCH_PUBLIC_BRANDS,
  FETCH_PUBLIC_BRAND
} from '../actions/types'

const initialState = {}

const publicBrandsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_BRANDS:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        case FETCH_PUBLIC_BRAND:
            return {
                ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicBrandsReducer