import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchProducts
} from '../../../actions/products'
import {
    createVariation
} from '../../../actions/variations'
import { fetchDeliveryOptions } from '../../../actions/delivery-options'
import Form from '../../forms/VariationCreateForm'

class DashboardVariationCreate extends React.Component {

    componentDidMount(){
        this.props.fetchProducts(this.props.token)
        this.props.fetchDeliveryOptions(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createVariation(this.props.token, this.props.match.params.productId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to={`/dashboard/products/${this.props.match.params.productId}/variations`} />
        }

        return(
            <DashboardLayout 
                heading="Create Variation" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/variations`}>Variations</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/variations`} className='_button _button-2'>Back</Link>
                </div>

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                    products={this.props.products ? this.props.products : null}
                    deliveryOptions={Object.values(this.props.deliveryOptions)}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.variationCreateState,
        products: Object.values(state.productsState),
        deliveryOptions: state.deliveryOptionsState
    }
}

export default connect(mapStateToProps, {
    createVariation,
    resetForms,
    fetchProducts,
    fetchDeliveryOptions
})(DashboardVariationCreate)