import admin from '../apis/admin'

import {
    RESET_FORMS,
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED,
    SIGNIN,
    SIGNIN_FAILED,
    SIGNOUT,
    FETCH_PUBLIC_PRODUCTS,
    FETCH_PUBLIC_PRODUCT,
    ADD_ITEM,
    ADD_VARIABLE_ITEM,
    ITEM_QTY_INCREMENT,
    ITEM_QTY_DECREMENT,
    CLEAR_CART,
    UPDATE_QUANTITY,
    UPDATE_VARIABLE_QUANTITY,
    REMOVE_ITEM,
    REMOVE_VARIABLE_ITEM,
    FETCH_PUBLIC_POSTS,
    FETCH_PUBLIC_POST,
    FETCH_PUBLIC_CATEGORIES,
    FETCH_PUBLIC_CATEGORY,
    FETCH_PUBLIC_BRANDS,
    FETCH_PUBLIC_BRAND,
    CUSTOMER_REGISTRATION,
    CUSTOMER_REGISTRATION_FAILED,
    CUSTOMER_LOGIN,
    CUSTOMER_LOGIN_FAILED,
    CUSTOMER_LOGOUT,
    FETCH_CUSTOMER_ACCOUNT_DETAILS,
    CHECKOUT,
    CHECKOUT_FAILED,
    GET_PAYMENT_STATUS,
    BOOKING,
    BOOKING_FAILED,
    PRODUCT_ENQUIRY,
    PRODUCT_ENQUIRY_FAILED,
    CUSTOMER_UPDATE, 
    CUSTOMER_UPDATE_FAILED,
    FETCH_CUSTOMER_ORDERS,
    FETCH_CUSTOMER_ORDER,
    GET_COUPON,
    GET_COUPON_FAILED,
    REMOVE_COUPON,
    NEWSLETTER_SIGNUP,
    NEWSLETTER_SIGNUP_FAILED,
    FOOTER_NEWSLETTER_SIGNUP,
    FOOTER_NEWSLETTER_SIGNUP_FAILED,
    DOWNLOAD_DOCUMENT_SUBMIT,
    DOWNLOAD_DOCUMENT_SUBMIT_FAILED,
    ACCEPT_COOKIE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_OTP,
    FORGOT_PASSWORD_OTP_FAILED,
    FORGOT_PASSWORD_RESET,
    FORGOT_PASSWORD_RESET_FAILED,
    CLEAR_CHECKOUT
} from './types'

import history from '../history'

import {
    clearCartIfExpired
} from '../utils'

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const acceptCookie = () => {
    return (dispatch) => {
        dispatch({
            type: ACCEPT_COOKIE
        })
    }
}

export const downloadDocument = (formValues, productSlug) => async dispatch => {
    try {

        const response = await admin.post('download/' + productSlug, formValues)

        dispatch({
            type: DOWNLOAD_DOCUMENT_SUBMIT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: DOWNLOAD_DOCUMENT_SUBMIT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const newsletterSignup = formValues => async dispatch => {
    try {

        const response = await admin.post('newsletter-signup', formValues)

        dispatch({
            type: NEWSLETTER_SIGNUP,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: NEWSLETTER_SIGNUP_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const footerNewsletterSignup = formValues => async dispatch => {
    try {

        const response = await admin.post('newsletter-signup', formValues)

        dispatch({
            type: FOOTER_NEWSLETTER_SIGNUP,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: FOOTER_NEWSLETTER_SIGNUP_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const checkout = (token, paymentMethod, formValues, orderId) => async dispatch => {
    try {

        const path = token ? "checkout-logged-in" : "checkout"

        const config = token ?  { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        } : null

        const response = await admin.post(`${path}/${paymentMethod}/${orderId ? orderId : 'null'}`, formValues, config)

        dispatch({
            type: CHECKOUT,
            payload: response.data
        })

        history.push(`/checkout/payment/${paymentMethod}`)
        
    } catch (error) {
        dispatch({
            type: CHECKOUT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const getPaymentStatus = (orderId, paymentId) => async dispatch => {
    try {

        const response = await admin.get(`checkout/status?paymentId=${paymentId}&orderId=${orderId}`)

        dispatch({
            type: GET_PAYMENT_STATUS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Get payment status failed')
    }
}

export const getSwitchPaymentStatus = (orderId, resourcePath) => async dispatch => {
    try {

        const response = await admin.get(`checkout/switch-status?resourcePath=${resourcePath}&orderId=${orderId}`)

        dispatch({
            type: GET_PAYMENT_STATUS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Get payment status failed')
    }
}

export const customerRegistration = formValues => async dispatch => {
    try {

        const response = await admin.post('customer-account-register', formValues)

        dispatch({
            type: CUSTOMER_REGISTRATION,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_REGISTRATION_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const customerUpdate = (token, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.post('customer-account-update', formValues, config)

        dispatch({
            type: CUSTOMER_UPDATE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_UPDATE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const customerLogin = formValues => async dispatch => {
    try {

        const response = await admin.post('customer-account-login', formValues)

        dispatch({
            type: CUSTOMER_LOGIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_LOGIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const customerLogout = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        await admin.post('customer-account-logout', {}, config)

        dispatch({
            type: CUSTOMER_LOGOUT
        })
        
    } catch (error) {
        dispatch({
            type: CUSTOMER_LOGOUT
        })
    }
}

export const fetchCustomerAccountDetails = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get('customer-account-details', config)

        dispatch({
            type: FETCH_CUSTOMER_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch customer account details failed')
    }
}

export const fetchCustomerOrders = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get('customer-orders', config)

        dispatch({
            type: FETCH_CUSTOMER_ORDERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch customer orders failed')
    }
}

export const fetchCustomerOrder = (token, orderId) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await admin.get('customer-orders/' + orderId, config)

        dispatch({
            type: FETCH_CUSTOMER_ORDER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch customer order failed')
    }
}

export const sendContactMessage = (formValues, branch) => async dispatch => {
    try {

        const response = await admin.post('contact/' + branch, formValues)

        dispatch({
            type: SEND_CONTACT_MESSAGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SEND_CONTACT_MESSAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const booking = formValues => async dispatch => {
    try {

        const response = await admin.post('booking', formValues)

        dispatch({
            type: BOOKING,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: BOOKING_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const productEnquiry = (formValues, productSlug) => async dispatch => {
    try {

        const response = await admin.post(`product-enquiry/${productSlug}`, formValues)

        dispatch({
            type: PRODUCT_ENQUIRY,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: PRODUCT_ENQUIRY_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signIn = formValues => async dispatch => {
    try {

        const response = await admin.post('login', formValues)

        dispatch({
            type: SIGNIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signOut = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/logout', {}, config)

        console.log('signed out')

        dispatch({
            type: SIGNOUT
        })
        
    } catch (error) {

        console.log('sign out failed')
        
        dispatch({
            type: SIGNOUT
        })
    }
}

//public

export const fetchPublicProducts = string => async dispatch => {
    try {
        
        const response = await admin.get(`products${string}`)

        dispatch({
            type: FETCH_PUBLIC_PRODUCTS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch products failed')      
    }
}

export const fetchPublicProduct = slug => async dispatch => {
    try {
        
        const response = await admin.get(`products/${slug}`)

        dispatch({
            type: FETCH_PUBLIC_PRODUCT,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch Product Failed')       
        history.push('/not-found')   
    }
}

export const fetchPublicPosts = () => async dispatch => {
    try {
        
        const response = await admin.get(`posts`)

        dispatch({
            type: FETCH_PUBLIC_POSTS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch posts failed')        
    }
}

export const fetchPublicPost = slug => async dispatch => {
    try {
        
        const response = await admin.get(`posts/${slug}`)

        dispatch({
            type: FETCH_PUBLIC_POST,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch post Failed')        
    }
}

export const fetchPublicCategories = () => async dispatch => {
    try {
        
        const response = await admin.get(`categories`)

        dispatch({
            type: FETCH_PUBLIC_CATEGORIES,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch categories failed')        
    }
}

export const fetchPublicCategory = slug => async dispatch => {
    try {
        
        const response = await admin.get(`categories/${slug}`)

        dispatch({
            type: FETCH_PUBLIC_CATEGORY,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch category failed')        
    }
}

export const fetchPublicBrands = () => async dispatch => {
    try {
        
        const response = await admin.get(`brands`)

        dispatch({
            type: FETCH_PUBLIC_BRANDS,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch brands failed')        
    }
}

export const fetchPublicBrand = slug => async dispatch => {
    try {
        
        const response = await admin.get(`brands/${slug}`)

        dispatch({
            type: FETCH_PUBLIC_BRAND,
            payload: response.data.data
        })
        
    } catch (error) {
        console.log('Fetch brand failed')        
    }
}

export const addItem = (slug, qty) => async dispatch => {
    const response = await admin.get(`/products/${slug}`)

    clearCartIfExpired()

    dispatch({
        type: ADD_ITEM,
        payload: response.data.data,
        qty
    })
}

export const addVariableItem = (slug, qty, variationId) => async dispatch => {
    const response = await admin.get(`/products/${slug}/${variationId}`)

    clearCartIfExpired()

    dispatch({
        type: ADD_VARIABLE_ITEM,
        payload: response.data.data,
        qty
    })
}

export const itemQtyIncrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: ITEM_QTY_INCREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const itemQtyDecrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: ITEM_QTY_DECREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const clearCart = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CART
        })
    }
}

export const updateQuantity = (id, qty) => {

    clearCartIfExpired()

    return (dispatch) => {
        dispatch({
            type: UPDATE_QUANTITY,
            id,
            qty
        })
    }
}

export const updateVariableQuantity = (id, qty) => {

    clearCartIfExpired()

    return (dispatch) => {
        dispatch({
            type: UPDATE_VARIABLE_QUANTITY,
            id,
            qty
        })
    }
}

export const removeItem = (id) => {

    clearCartIfExpired()

    return (dispatch) => {
        dispatch({
            type: REMOVE_ITEM,
            id
        })
    }
}

export const removeVariableItem = (id) => {

    clearCartIfExpired()
    
    return (dispatch) => {
        dispatch({
            type: REMOVE_VARIABLE_ITEM,
            id
        })
    }
}

export const getCoupon = code => async dispatch => {
    
    try {

        const response = await admin.get('coupon/' + code)

        dispatch({
            type: GET_COUPON,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: GET_COUPON_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const removeCoupon = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_COUPON
        })
    }
}

export const clearCheckout = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CHECKOUT
        })
    }
}


export const forgotPassword = formValues => async dispatch => {
    try {

        const response = await admin.post('password/email', formValues)

        dispatch({
            type: FORGOT_PASSWORD,
            payload: response.data
        })

        history.push('/forgot-password/otp')
        
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const forgotPasswordOtp = formValues => async dispatch => {
    try {

        const response = await admin.post('password/code/check', formValues)

        dispatch({
            type: FORGOT_PASSWORD_OTP,
            payload: response.data
        })

        history.push('/forgot-password/reset')
        
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_OTP_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const forgotPasswordReset = formValues => async dispatch => {
    try {

        const response = await admin.post('password/reset', formValues)

        dispatch({
            type: FORGOT_PASSWORD_RESET,
            payload: response.data
        })

        history.push('/account')
        
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_RESET_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}