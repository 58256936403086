import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'
import Recaptcha from 'react-recaptcha'

class NewsletterFooterForm extends React.Component {

    state= {
        message: '',
        button: 'Send',
        capchaLoaded: null,
        capchaVerified: null
    }

    renderErrors = () => {
        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }

        return Object.values(this.props.errors).map((item, index) => {   
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {

        if(this.state.capchaLoaded && !this.state.capchaVerified){
            console.log('Robot filter denied')
            return
        }

        this.setState({
            button: 'Sending'
        })

        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors && this.state.button === 'Sending')
        this.setState({ button: 'Send' })

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={`_newsletter-form ${this.props.show ? '' : '_hide'}`}>
                {this.props.errors && this.renderErrors()}
                <div className="_form-row">
                    <Field name="fullname" type="text" component={Input} label="Name and Surname*" />
                    <Field name="email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6LfwzfEhAAAAAI2NdIoq-L9mOVUml-1aWGbn9mgg"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                            elementID="g_recaptcha-footer"
                            theme="dark"
                        />
                    </div>
                </div>
                <br />
                <button className={`_button _button-submit subscibed ${this.state.button === 'Sending' ? '_sending' : ''}`}>Subscribe</button>
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter email address"
    }

    if(!formValues.fullname){
        errors.fullname = "You must enter your name and surname"
    }

    return errors
}

export default reduxForm({
    form: 'newsletterfooter',
    validate
})(NewsletterFooterForm)