import React from "react";
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
margin: 30px 0 15px 0;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 15px 0;
`;

const Filter = ({ filterText, onFilter, onClear }) => (
  <>
    <Input
      id="search"
      className="_form-element"
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton onClick={onClear} className="_button _button-3">Clear</ClearButton>
  </>
);

export default Filter;
