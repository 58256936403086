import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'

class VirtualTour extends React.Component {

  render(){

    return (
      <>
        <Seo
          title="Virtual Tour"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <section className='_section _section-virtual-tour'>
            <iframe src='https://my.deltabusinessdesign.com/tour/beauty-fires' height='600' width='800' allowfullscreen></iframe>
          </section>
        </Layout>
      </>
    )
  }
}

export default VirtualTour