import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import {Link} from 'react-router-dom'
import SimpleHeading from './ui/SimpleHeading'
import {formatPrice} from '../utils'
import {
  clearCart
} from '../actions'
import {connect} from 'react-redux'
import CartItem from './cart/CartItem'
import VariableCartItem from './cart/VariableCartItem'
import CouponForm from './cart/CouponForm'
import {ViewTermsLink, ViewShippingPolicyLink} from './views/TermsLink'

class ShoppingCart extends React.Component {

  state = {}

  clearCart = () => {
    setTimeout(() => {
      this.props.clearCart()
    }, 300);
  }

  renderCartItems = itemsArray => {
    return itemsArray.map((item) => {
      return (
        <CartItem data={item} key={item.id} />
      )
    })
  }

  renderVariableCartItems = itemsArray => {
    return itemsArray.map((item) => {
      return (
        <VariableCartItem data={item} key={item.id} />
      )
    })
  }

  submitCoupon = code => {
    console.log(code)
  }

  renderShoppingCart = () => {

    const itemsArray = Object.values(this.props.cartData.items)
    const variableItemsArray = Object.values(this.props.cartData.variableItems)

    if(!itemsArray.length > 0 && !variableItemsArray.length > 0)
    return (
      <div className='_panel' style={{textAlign: 'center'}}>
        <p className='_large-text-par'>{this.props.cartData.expired ? 'Your cart expired' : 'Your cart is empty'}</p>
        <br />
        <br />
        <Link to="/products" className='_button'>Shop Now</Link>
      </div>
    )

    return (
      <>
        <table className='_table _table-shopping-cart'>
          <thead>
            <tr>
              <th className='_remove'></th>
              <th className='_product-image'></th>
              <th className='_product-name'>Product</th>
              <th className='_price'>Price</th>
              <th className='_qty'>Quantity</th>
              <th className='_subtotal'>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {this.renderCartItems(itemsArray)}
            {this.renderVariableCartItems(variableItemsArray)}
          </tbody>
        </table>
        <table className='_table-after-shopping-cart'>
          <tbody>
            <tr>
              <td>
                <CouponForm />
              </td>
              <td className='_clear-cart'>
                <button className='_button _button-3' onClick={this.clearCart}>Clear Cart</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className='_border' />
        <div className='_flex-box'>
          <div>
            <ViewTermsLink />
            <ViewShippingPolicyLink />
          </div>
          <div className='_cart-totals'>
            <table className='_table-cart-totals'>
              <tbody>
                <tr>
                  <td>
                    <p>Subtotal</p>
                  </td>
                  <td className='_total'>
                    <p><strong>R{formatPrice(this.props.cartData.subTotalCost)}</strong></p>
                  </td>
                </tr>
                {this.props.cartData.discount !== 0 && (
                  <tr>
                    <td>
                      <p>
                        Discount
                      </p>
                    </td>
                    <td className='_total'>
                      <p><strong>- R{formatPrice(this.props.cartData.discount)}</strong></p>
                    </td>
                  </tr>
                )}
                {this.props.cartData.deliveryCost !== 0 && (
                  <tr>
                    <td>
                      <p>
                        Delivery
                      </p>
                    </td>
                    <td className='_total'>
                      <p><strong>R{formatPrice(this.props.cartData.deliveryCost)}</strong></p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <p>Total</p>
                  </td>
                  <td className='_total'>
                    <p><strong>R{formatPrice(this.props.cartData.totalCost)}</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <Link to="/checkout" className='_button _button-2 _checkout'>Checkout</Link>
          </div>
        </div>
      </>
    )
  }

  render(){

    return (
      <>
        <Seo
          title="Shopping Cart"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            Shopping Cart
          </Breadcrums>
          <SimpleHeading>Shopping Cart</SimpleHeading>
          <section className='_section _section-cart'>
            <div className='_grid'>
              <div className='_column'>
                {this.renderShoppingCart()}
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    cartData: state.cartState
  }
}

export default connect(mapStateToProps, {
  clearCart
})(ShoppingCart)