import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchCoupon,
    updateCoupon
} from '../../../actions/coupons'
import Form from '../../forms/CouponEditForm'

class DashboardCouponEdit extends React.Component {

    componentDidMount(){
        this.props.fetchCoupon(this.props.token, this.props.match.params.couponId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateCoupon(this.props.token, this.props.match.params.couponId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/coupons" />
        }

        return(
            <DashboardLayout 
                heading="Edit Coupon" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/coupons">Coupons</Link> / {this.props.match.params.couponId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/coupons" className='_button _button-3'>Back</Link>
                </div>

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.couponsState[ownProps.match.params.couponId],
        data: state.couponUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchCoupon,
    updateCoupon,
    resetForms
})(DashboardCouponEdit)