import {
    FETCH_PUBLIC_PRODUCTS,
} from '../actions/types'
import _ from 'lodash'

const initialState = {}

const publicProductsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PRODUCTS:
            return {
                data: {..._.mapKeys(action.payload.data, 'id')},
                links: action.payload.links,
                meta: action.payload.meta
            }
        default:
            return state
    }
}

export default publicProductsReducer