import _ from 'lodash'
import {
  FETCH_PUBLIC_POSTS,
  FETCH_PUBLIC_POST
} from '../actions/types'

const initialState = {}

const publicPostsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_POSTS:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        case FETCH_PUBLIC_POST:
            return {
                ...state, [action.payload.slug]: action.payload
            }
        default:
            return state
    }
}

export default publicPostsReducer