import {
    PRODUCT_ENQUIRY,
    PRODUCT_ENQUIRY_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const productEnquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_ENQUIRY:
            return {
                success: action.payload
            }
        case PRODUCT_ENQUIRY_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default productEnquiryReducer