import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    fetchProducts
} from '../../../actions/products'
import {
    fetchDocument,
    updateDocument
} from '../../../actions/documents'
import Form from '../../forms/DocumentEditForm'

class DashboardDocumentEdit extends React.Component {

    componentDidMount(){
        this.props.fetchDocument(this.props.token, this.props.match.params.productId, this.props.match.params.documentId)
        this.props.fetchProducts(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateDocument(this.props.token, this.props.match.params.productId, this.props.match.params.documentId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to={`/dashboard/products/${this.props.match.params.productId}/documents`} />
        }

        return(
            <DashboardLayout 
                heading="Edit Document" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/documents`}>Documents</Link> / {this.props.match.params.documentId} / Edit
                </>)}
            >
                <div className='_button-group'>
                    <Link to={`/dashboard/products/${this.props.match.params.productId}/documents`} className='_button _button-2'>Back</Link>
                </div>

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                        products={this.props.products ? this.props.products : null}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.documentsState[ownProps.match.params.productId] && state.documentsState[ownProps.match.params.productId][ownProps.match.params.documentId],
        data: state.documentUpdateState,
        products: Object.values(state.productsState)
    }
}

export default connect(mapStateToProps, {
    fetchDocument,
    updateDocument,
    resetForms,
    fetchProducts
})(DashboardDocumentEdit)