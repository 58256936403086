import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input,
    Select
} from './fields'
import {formatPrice} from '../../utils'

class VariationEditForm extends React.Component {

    renderDeliveryOptions = () => {
        return this.props.deliveryOptions.map(({id, title, price}) => {
            return <option key={id} value={id}>{title} - R {formatPrice(price)}</option>
        })
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="title" 
                        label="Title*" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="price" 
                        label="Price*" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        name="delivery_option_id" 
                        label="Delivery Options" 
                        component={Select}
                    >
                        <option value="">Please select a delivery option</option>
                        {this.renderDeliveryOptions()}
                    </Field>
                </div>

                <div className="_form-row">
                    <Field 
                        type="number"
                        name="order_number" 
                        label="Priority (optional)" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Update</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.title){
      errors.title = "you must enter a title"
    }

    if(!formValues.price){
      errors.price = "you must enter a price"
    }
    
    return errors
}

export default reduxForm({
    form: 'variationedit',
    validate
})(VariationEditForm)