import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {formatPrice} from '../utils'


class PaymentCopyAndPay extends React.Component {

  render(){

    if(!this.props.data.response_data)
    return <Redirect to="/checkout" />

    const responseData = JSON.parse(this.props.data.response_data)
    const checkoutId = responseData.id
    const shopperResultUrl = process.env.REACT_APP_SHOPPER_RESULT_URL
    const host = process.env.REACT_APP_PAYMENT_GATEWAY_HOST

    return (
      <>
        <Seo
          title="Pay With Card"
          description={null}
          image={null}
        >
          <script src={`${host}/v1/paymentWidgets.js?checkoutId=${checkoutId}`}></script>
          <meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
        <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/cart" rel="index up up up">Shopping Cart</Link>&nbsp;/&nbsp;
            <Link to="/checkout" rel="index up up up">Checkout</Link>&nbsp;/&nbsp;
            Pay With Card
          </Breadcrums>
          <SimpleHeading>Pay With Card</SimpleHeading>
          <section className='_section _section-payment'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel' style={{textAlign:'center'}}>
                  <p>This is the card payment process. When this form is completed and payment has been made you will be redirected to an order confirmation page.</p>
                  <h2 style={{color: '#fff', fontWeight: '300'}}>Total: <strong>R {formatPrice(this.props.cart.totalCost)}</strong></h2><br />
                  <form action={shopperResultUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.checkoutState,
    cart: state.cartState
  }
}

export default connect(mapStateToProps)(PaymentCopyAndPay)