const Breadcrums = props => {
  return (
    <section className='_section'>
      <nav className="_breadcrums">
        <p>{props.children}</p>
      </nav>
    </section>
  )
}

export default Breadcrums