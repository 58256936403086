import React from 'react'
import {Link} from 'react-router-dom'
import {
  fetchPublicBrands
} from '../../actions'
import {connect} from 'react-redux'

class MobileMenu extends React.Component {

  componentDidMount(){
    this.props.fetchPublicBrands()
    const links = document.querySelectorAll("._mobile-nav ._mobile-nav-links")
    links.forEach(link => {
      if(link.classList.contains('_with-dropdown-arrow'))
      return

      link.addEventListener("click", () => {
        this.props.onMenuToggle()
      })
    });
  }

  toggleDropdown = e => {
    e.preventDefault()
    e.target.nextElementSibling.classList.toggle('_hide')
    e.target.classList.toggle('_open')
  }

  renderBrandLinks = () => {
    return this.props.brands.map(({title, slug}, index) => {
      return (
        <li key={index}>
          <Link to={`/products?brand=${slug}`} className='_mobile-nav-links'>{title}</Link>
        </li>
      )
    })
  }

  render(){

    return (
      <nav className={`_nav _mobile-nav _mobile ${this.props.menuOpen ? '_show' : ''}`}>
        <ul>
          <li>
            <Link to="/products" className='_mobile-nav-links _with-dropdown-arrow' onClick={this.toggleDropdown}>Products</Link>
            <ul className='_dropdown _hide'>
              <li><div className='_border' /></li>
              <li>
                <Link to="/products?location=indoor" className='_mobile-nav-links'>Indoor</Link>
              </li>
              <li>
                <Link to="/products?location=outdoor" className='_mobile-nav-links'>Outdoor</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/products" className='_mobile-nav-links _with-dropdown-arrow' onClick={this.toggleDropdown}>Brands</Link>
            <ul className='_dropdown-brands _hide'>
              <li><div className='_border' /></li>
              {this.props.brands && this.renderBrandLinks()}
              <li><div className='_border' /></li>
            </ul>
          </li>
          <li>
            <Link to="/blog" className='_mobile-nav-links'><span>Blog</span></Link>
          </li>
          <li>
            <Link to="/contact/cape-town" className='_mobile-nav-links _with-dropdown-arrow' onClick={this.toggleDropdown}>Contact</Link>
            <ul className='_dropdown _hide'>
              <li><div className='_border' /></li>
              <li>
                <Link to="/contact/cape-town" className='_mobile-nav-links'>Cape Town</Link>
              </li>
              <li>
                <Link to="/contact/johannesburg" className='_mobile-nav-links'>Johannesburg</Link>
              </li>
              <li>
                <Link to="/contact/garden-route" className='_mobile-nav-links'>Garden Route</Link>
              </li>
            </ul>
          </li>
          <li>
            <br />
            <Link to="/booking" className='_button book-a-site-visit'>Book a site visit</Link>
            <br />
            <br />
            <br />
            <br />
          </li>
        </ul>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    brands: Object.values(state.publicBrandsState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicBrands
})(MobileMenu)