import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select,
    TextArea
} from './fields'
import Recaptcha from 'react-recaptcha'
import scrollToFirstError from './SmoothScrollToErrorFields.js'

class ProductEnquiryForm extends React.Component {

    state= {
        message: '',
        button: 'Send',
        capchaLoaded: null,
        capchaVerified: null
    }

    renderErrors = () => {
        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }

        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({ capchaLoaded: true })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({ capchaVerified: true })
    }

    onSubmit = formValues => {
        
        if(this.state.capchaLoaded && !this.state.capchaVerified){
            console.log('Robot filter denied')
            return
        }

        this.setState({
            button: 'Sending'
        })
        
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors && this.state.button === 'Sending')
        this.setState({ button: 'Send' })

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {'name' && <div name={`position-name`} />}
                {'surname' && <div name={`position-surname`} />}
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name*" />
                    <Field name="surname" type="text" component={Input} label="Surname*" />
                </div>
                {'email' && <div name={`position-email`} />}
                {'phone' && <div name={`position-phone`} />}
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address*" />
                    <Field name="phone" type="text" component={Input} label="Phone Number*" />
                </div>
                {'province' && <div name={`position-province`} />}
                {'city' && <div name={`position-city`} />}
                <div className="_form-row">
                    <Field 
                        name="province" 
                        label="Province*" 
                        component={Select} 
                    >
                        <option value="">Please select a province</option>
                        <option>Western Cape</option>
                        <option>Garden Route</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                        <option>International</option>
                    </Field>
                    <Field 
                        type="text"
                        name="city" 
                        label="City / Town*" 
                        component={Input} 
                    />
                </div>
                {'address' && <div name={`position-address`} />}
                {'postal_code' && <div name={`position-postal_code`} />}
                <div className="_form-row">
                    <Field name="address" type="text" component={Input} label="Address*" />
                    <Field name="postal_code" type="text" component={Input} label="Postal Code*" />
                </div>

                <div className="_form-row">
                    <Field
                        name="comment" 
                        label="Message" 
                        component={TextArea}
                    />
                </div>
                {/* <div className='_border' style={{marginTop: '15px', marginBottom: '30px'}} /> */}
                <p>Our sales representatives are available to schedule an in-store or virtual meeting to answer your questions and assess your needs. We look forward to assisting you.</p>
                <div className='_border' style={{marginTop: '30px', marginBottom: '30px'}} />
                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            elementID="g_recaptcha-booking"
                            sitekey="6LfwzfEhAAAAAI2NdIoq-L9mOVUml-1aWGbn9mgg"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                            theme="dark"
                        />
                    </div>
                </div>
                <br />
                 
                <div className="_form-group">
                    <button className={`_button _button-submit enquiry-submitted ${this.state.button === 'Sending' ? '_sending' : ''}`}>SUBMIT ENQUIRY</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your name"
    }
    if(!formValues.surname){
        errors.surname = "You must enter your surname"
    }
    if(!formValues.email){
        errors.email = "You must enter your email address"
    }
    if(!formValues.phone){
        errors.phone = "You must enter your phone number"
    }
    if(!formValues.province){
        errors.province = "You must select your province"
    }
    if(!formValues.city){
        errors.city = "You must enter your city"
    }
    if(!formValues.address){
        errors.address = "You must enter your address"
    }
    if(!formValues.postal_code){
        errors.postal_code = "You must enter your postal code"
    }
    // if(!formValues.accept_privacy){
    //   errors.accept_privacy = "You must accept our privacy policy"
    // }

    return errors
}

export default reduxForm({
    form: 'enquiry',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(ProductEnquiryForm)