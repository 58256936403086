import {
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_FAILED,
  RESET_FORMS
} from '../../actions/types'

const initialState = {}

const customerAccountUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_UPDATE:
            return {
                success: true
            }
        case CUSTOMER_UPDATE_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default customerAccountUpdateReducer