import {
  FETCH_ACCOUNT_DETAILS,
  SIGNOUT
} from '../../actions/types'

const initialState = {}

const accountReducer = (state = initialState, action) => {
  switch(action.type){
      case FETCH_ACCOUNT_DETAILS:
          return {
              details: action.payload
          }
      case SIGNOUT:
        return initialState
      default:
          return state
  }
}

export default accountReducer