import {
    CHECKOUT,
    CHECKOUT_FAILED,
    CLEAR_CART,
    CLEAR_CHECKOUT
} from '../actions/types'

const initialState = {}

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKOUT:
            return action.payload

        case CHECKOUT_FAILED:
            return action.payload
        
        case CLEAR_CHECKOUT:
            return initialState   
             
        case CLEAR_CART:
            return initialState
            
        default:
            return state
    }
}

export default checkoutReducer