import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
  fetchCustomerOrders
} from '../../actions'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import {Link, Redirect} from 'react-router-dom'
import AccountLayout from './AccountLayout'
import {readableDate, formatPrice} from '../../utils'

class AccountDashboardOrders extends React.Component {

  componentDidMount(){
    this.props.fetchCustomerOrders(this.props.auth.token)
  }

  renderOrderItems = () => {
    return this.props.orders.slice(0).reverse().map(({id, status, created_at, billing_total}) => {
      return (
        <tr  key={id}>
          <td>#{id}</td>
          <td>{readableDate(created_at)}</td>
          <td>{status}</td>          
          <td>R{formatPrice(billing_total)}</td>
          <td>
            <Link to={`/account/my-orders/${id}`} className="_button ">View</Link>
          </td>
        </tr>
      )
    })
  }

  renderOrdersTable = () => {
    if(this.props.orders.length === 0)
    return <>No orders</>

    return (
      <table className='_customer-orders-table'>
        <thead>
          <tr>
            <th>Order</th>
            <th>Date</th>
            <th>Status</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.renderOrderItems()}
        </tbody>
      </table>
    )
  }

  renderContent = () => {
    if(!this.props.orders)
    return <></>

    return (
      <>
        <h3>MY ORDERS</h3>
        {this.renderOrdersTable()}
      </>
    )
  }

  render(){

    if(!this.props.auth.token)
    return (
      <Redirect to="/account" />
    )

    return (
      <>
        <Seo
          title="My Orders | My Account"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/account/dashboard" rel="index up up up">my Account</Link> / My Orders
          </Breadcrums>
          <SimpleHeading>My Account</SimpleHeading>
          <AccountLayout>
            {this.renderContent()}
          </AccountLayout>          
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.customerAuthState,
      orders: Object.values(state.customerOrdersState)
  }
}

export default connect(mapStateToProps, {
  fetchCustomerOrders
})(AccountDashboardOrders)