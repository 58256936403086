import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    createPost
} from '../../../actions/posts'

const Form = React.lazy(() => import('../../forms/PostCreateForm'))

class DashboardPostCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createPost(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/posts" />
        }

        return(
            <DashboardLayout 
                heading="Create Post" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/posts">Post</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/posts" className='_button _button-3'>Back</Link>
                </div>
                
                <Suspense fallback={<></>}>
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                    />
                </Suspense>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.postCreateState
    }
}

export default connect(mapStateToProps, {
    createPost,
    resetForms
})(DashboardPostCreate)