import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
  fetchCustomerOrder
} from '../../actions'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import {Link, Redirect} from 'react-router-dom'
import AccountLayout from './AccountLayout'
import {readableDate, formatPrice} from '../../utils'
import {BASE_PATH} from '../../paths'

class AccountDashboardOrderDetails extends React.Component {

  componentDidMount(){
    this.props.fetchCustomerOrder(this.props.auth.token, this.props.match.params.orderId)
  }

  renderOrderItems = items => {

    return items.map(({id,title,preview_image,quantity,price,total}) => {
      return (
        <tr key={id}>
          <td>
            <img src={BASE_PATH+preview_image} alt="" style={{width: '80px'}} />
          </td>
          <td>{quantity} x {title} - R{formatPrice(price)} each</td>
          <td>R{formatPrice(total)}</td>
        </tr>
      )
    })
  }

  renderContent = () => {
    if(!this.props.order)
    return <></>

    const { id, status, billing_name, billing_surname, billing_email, billing_phone, billing_province, billing_city, billing_address, billing_postal_code, billing_subtotal, billing_delivery_cost, billing_total, billing_discount, created_at, order_products } = this.props.order

    return (
      <>
        <h3>ORDER STATUS</h3>
        <p>Order <strong>#{id}</strong> was placed on <strong>{readableDate(created_at)}</strong> and is currently <strong>{status}</strong>.</p>
        <h3>ORDER DETAILS</h3>
        {order_products && (
          <table className='_customer-orders-table'>
            {/* <thead>
              <tr>
                <th></th>
                <th>Product</th>
                <th>Total</th>
              </tr>
            </thead> */}
            <tbody>
              {this.renderOrderItems(order_products)}
            </tbody>
          </table>
        )}
        <table className='_customer-orders-table'>
          <tbody>
            
            <tr>
              <td>
                Subtotal
              </td>
              <td>
                R{formatPrice(billing_subtotal)}
              </td>
            </tr>
            <tr>
              <td>
                Delivery
              </td>
              <td>
                R{formatPrice(billing_delivery_cost)}
              </td>
            </tr>
            <tr>
              <td>
                Discount
              </td>
              <td>
                R{formatPrice(billing_discount)}
              </td>
            </tr>
            <tr>
              <td>
                Total
              </td>
              <td>
                R{formatPrice(billing_total)}
              </td>
            </tr>
          </tbody>
        </table>

        <h3>BILLING DETAILS</h3>
        <p>
          {billing_name} {billing_surname}<br />
          {billing_address}<br />
          {billing_city}<br />
          {billing_province}<br />
          {billing_postal_code}<br />
          {billing_phone}<br />
          {billing_email}<br />
        </p>
      </>
    )
  }

  render(){

    if(!this.props.auth.token)
    return (
      <Redirect to="/account" />
    )

    return (
      <>
        <Seo
          title="My Orders | My Account"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/account/dashboard" rel="index up up up">my Account</Link>&nbsp;/&nbsp;
            <Link to="/account/my-orders" rel="index up up up">My Orders</Link> / Order Number: #{this.props.match.params.orderId}
          </Breadcrums>
          <SimpleHeading>My Account</SimpleHeading>
          <AccountLayout>
            {this.renderContent()}
          </AccountLayout>          
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      auth: state.customerAuthState,
      order: state.customerOrdersState[ownProps.match.params.orderId]
  }
}

export default connect(mapStateToProps, {
  fetchCustomerOrder
})(AccountDashboardOrderDetails)