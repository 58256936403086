import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Link} from 'react-router-dom'

class PageNotFound extends React.Component {

  onSubmit = formValues => {
    this.props.signIn(formValues)
  }

  render(){

    return (
      <>
        <Seo
          title="404 Page Not Found"
          description={null}
          image={null}
        ><meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            404 Page Not Found
          </Breadcrums>
          <SimpleHeading>404 Page Not Found</SimpleHeading>
          <section className='_section _section-not-found'>
            <div className='_grid'>
              <div className='_column'>   
                <div className='_panel' style={{textAlign: 'center'}}>
                  <p className='_large-text-par' ><span style={{fontWeight: '300'}}>Whoops! The page you are looking for doesn't exist.</span></p>
                  <Link to="/" className="_button">Go To Home Page</Link>
                </div>
              </div>    
            </div>    
          </section>
        </Layout>
      </>
    )
  }
}
export default PageNotFound