import {Link} from 'react-router-dom'

const BlogItem = props => {
  return (
    <div className='_blog-item'>
      <img src={props.image} alt={props.headline} width="740" height="548" />
      <h3 className='_secondary-heading'>{props.headline}</h3>
      <div dangerouslySetInnerHTML={{__html: props.summary}} />
      <Link to={`/blog/${props.slug}`} className="_button">READ MORE</Link>
    </div>
  )
}

export default BlogItem