import React from 'react'
import {Link} from 'react-router-dom'
import MegaDropdown from './MegaDropdown'
import {
  fetchPublicBrands
} from '../../actions'
import {connect} from 'react-redux'
import {BASE_PATH} from '../../paths'
import {scrollToPosition} from '../../utils'
import img1 from '../../assets/img/indoor-living-menu@2x.jpg'
import img2 from '../../assets/img/outdoor-living-menu@2x.jpg'

class LeftNav extends React.Component {

  componentDidMount(){
    this.props.fetchPublicBrands()
  }
  
  renderBrandLinks = () => {
    return this.props.brands.map(({slug, title, logo}) => {
      return (
        <Link to={`/products?brand=${slug}`} className='_flex-item _nav-link-item' key={slug}  onClick={() => scrollToPosition("#pagebanner", 300)}>
          <img src={BASE_PATH+logo} alt={title} className='_nav-link-item' width="200" height="160" />
        </Link>
      )
    })
  }

  render(){
  
    return (
      <nav className='_nav _header-nav _left-nav _flex-item _widescreen'>
        <ul>
          <li>
            <Link to="/products" className='_nav-link _with-dropdown-arrow'>Products</Link>
            <MegaDropdown>
              <div className='_grid _grid-4'>
                <div className='_column'>
                  <Link to="/products?location=indoor" className='_nav-link-item'><h2>INDOOR LIVING</h2></Link>
                  <ul>
                    <li><Link className='_nav-link-item' to="/products?location=indoor&category=braais">BRAAIS</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=indoor&category=fireplaces">FIREPLACES</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=indoor&category=fire-pits">FIRE PITS</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=indoor&category=pizza-ovens">PIZZA OVENS</Link></li>
                  </ul>
                </div>
                <div className='_column'>
                  <img src={img1} alt="Indoor Living" width="540" height="300" />
                </div>
                <div className='_column'>
                  <Link to="/products?location=outdoor" className='_nav-link-item'><h2>OUTDOOR LIVING</h2></Link>
                  <ul>
                    <li><Link className='_nav-link-item' to="/products?location=outdoor&category=braais">BRAAIS</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=outdoor&category=fireplaces">FIREPLACES</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=outdoor&category=fire-pits">FIRE PITS</Link></li>
                    <li><Link className='_nav-link-item' to="/products?location=outdoor&category=pizza-ovens">PIZZA OVENS</Link></li>
                  </ul>
                </div>
                <div className='_column'>
                  <img src={img2} alt="Outdoor Living" width="540" height="300" />
                </div>
              </div>
            </MegaDropdown>
          </li>
          <li>
            <div className='_nav-link _with-dropdown-arrow' onClick={e => e.preventDefault()}>Brands</div>
            <MegaDropdown>
              <div className='_grid'>
                <div className='_column'>
                  <h2>BRANDS</h2>
                  <div className='_flex-panel'>
                    {this.props.brands.length > 0 && this.renderBrandLinks()}
                  </div>
                </div>
              </div>
            </MegaDropdown>
          </li>
          <li>
            <Link to="/blog" className='_nav-link'>Blog</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    brands: Object.values(state.publicBrandsState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicBrands
})(LeftNav)