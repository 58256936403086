import _ from 'lodash'
import {
    FETCH_TYPES,
    CREATE_TYPE,
    FETCH_TYPE,
    UPDATE_TYPE,
    DELETE_TYPE,
    RESTORE_TYPE
} from '../../actions/types'

const initialState = {}

const typesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_TYPES:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_TYPE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_TYPE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_TYPE:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_TYPE:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_TYPE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default typesReducer