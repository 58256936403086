import React from 'react'
import {
  getCoupon,
  removeCoupon,
  resetForms
} from '../../actions'
import {connect} from 'react-redux'
import Popup from '../ui/Popup'

class CouponForm extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      value: this.props.coupon ? this.props.coupon.code : '',
      popupVisible: false,
      popupVisible2: true
    }
  }

  componentWillUnmount(){
    this.props.resetForms()
  }

  renderErrors = () => {
    if(!this.props.errors){
      return <></>
    }

    if(typeof this.props.errors === 'string'){
      return (
          <div className="_error-group">
              <div className="_error-message">{this.props.errors}</div>
          </div>
      )
    }

    return Object.values(this.props.errors).map((item, index) => {                
        return (
            <div className="_error-group" key={index}>
                <div className="_error-message">{item[0]}</div>
            </div>
        )
    })
}

  onChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  removeCoupon = () => {
    this.togglePopup()
    setTimeout(() => {
      this.props.removeCoupon()
      this.setState({value: ''})
    }, 300);
  }

  togglePopup = () => {
    this.setState({popupVisible: !this.state.popupVisible})
  }

  togglePopup2 = () => {
    this.setState({popupVisible2: !this.state.popupVisible2})
    this.props.resetForms()
  }

  onSubmit = () => {
    this.props.getCoupon(this.state.value)
  }

  renderSuccess = () => {
    return (
      <Popup show={this.state.popupVisible2} onClick={this.togglePopup2} heading="Message">
        <p className='_secondary-heading'>Coupon has been added successfully</p>
        <div className='_popup-buttons'>
          <button className='_button' onClick={this.togglePopup2}>OK</button>
        </div>
      </Popup>
    )
  }

  renderCoupon = () => {
    if(!this.props.coupon)
    return (
      <>
      <p>Have a coupon?</p>
      <div className='_coupon'>
        <input type="text" placeholder="Enter your coupon code or gift card" value={this.state.value} onChange={e => this.onChange(e)} />
        <button className='_button _button-2' onClick={this.onSubmit}>APPLY</button>
      </div>
      </>
    )

    return (
      <div className='_coupon'>
        Coupon:&nbsp;<strong>{this.props.coupon.code}</strong>
        <button className='_button _button-3' onClick={this.removeCoupon} style={{
          display: 'flex',
          width: 'auto',
          marginLeft: '10px'
        }}>
          <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="20" fill="#82828a"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/></svg>&nbsp;
          Remove&nbsp;Coupon
        </button>
      </div>
    )
  }
  
  render(){
    return (
      <>
        <Popup show={this.state.popupVisible} onClick={this.togglePopup} heading="Message">
          <p className='_secondary-heading'>Coupon removed</p>
          <div className='_popup-buttons'>
            <button className='_button' onClick={this.togglePopup}>OK</button>
          </div>
        </Popup>
        {this.renderCoupon()}
        <br />
        {this.renderErrors()}
        {this.props.success && this.renderSuccess()}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    coupon: state.couponState.details,
    success: state.couponState.success,
    errors: state.couponState.errors
  }
} 

export default connect(mapStateToProps, {
  getCoupon,
  removeCoupon,
  resetForms
})(CouponForm)