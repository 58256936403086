import React, {Suspense} from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'

// views
// import HeroSlider from './views/HeroSlider'
// import IndoorOutdoorGateway from './views/IndoorOutdoorGateway'
import OurBrandPhilosophy from './views/OurBrandPhilosophy'
import CategoryItem from './views/CategoryItem'
// import Testimonials from './views/Testimonials'
// import BlogSection from './views/BlogSection'
import SocialSection from './views/SocialSection'

//ui
import MessageSection from './ui/MessageSection'
// import ParallaxImageSection from './ui/ParallaxImageSection'
import {connect} from 'react-redux'
import {
  fetchPublicCategories
} from '../actions'
import {BASE_PATH} from '../paths'

const HeroSlider = React.lazy(() => import('./views/HeroSlider'))
const IndoorOutdoorGateway = React.lazy(() => import('./views/IndoorOutdoorGateway'))
const ParallaxImageSection = React.lazy(() => import('./ui/ParallaxImageSection'))
const Testimonials = React.lazy(() => import('./views/Testimonials'))
const BlogSection = React.lazy(() => import('./views/BlogSection'))

class Home extends React.Component {

  componentDidMount(){
    this.props.fetchPublicCategories()
  }

  renderCategoryItems = () => {
    return this.props.categories.map(({title,slug,preview_image},index) => {
      return (
        <div className='_column' key={index}>
          <CategoryItem name={title} link={`/products?category=${slug}`} image={BASE_PATH+preview_image} />
        </div>
      )
    })
  }

  render(){

    const description = `Beauty Fires offers the best prices on our range of world-class, internationally and locally
    manufactured, indoor and outdoor fireplaces and lifestyle cooking products.`

    const jsonLD = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": `${window.location.host}/#website`,
          "url": window.location.host,
          "name": "Beauty Fires",
          "inLanguage": "en-US",
          "potentialAction": [
            {
              "@type": "SearchAction",
              "target": `${window.location.host}/?s={search_term_string}`,
              "query-input": "required name=search_term_string"
            }
          ]
        },
        {
          "@type": "ImageObject",
          "@id": `${window.location.host}/#primaryimage`,
          "inLanguage": "en-US",
          "url": `${window.location.host}/aero-suspended-fireplace.jpg`,
          "width": 1920,
          "height": 1037,
          "caption": "Aero Suspended Fireplace"
        },
        {
          "@type": "WebPage",
          "@id": `${window.location.host}/#webpage`,
          "url": window.location.host,
          "name": "Beauty Fires | Modern Fireplaces | Fireplaces South Africa",
          "isPartOf": {
            "@id": `${window.location.host}/#website`
          },
          "inLanguage": "en-US",
          "primaryImageOfPage": {
            "@id": `${window.location.host}/#primaryimage`
          },
          "description": description,
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                window.location.host
              ]
            }
          ]
        }
      ]
    }

    return (
      <>
        <Seo
          title="Home"
          description={description}
          image={`${window.location.host}/aero-suspended-fireplace.jpg`}
          keywords="fireplace, closed combustion fireplace, gas fireplace, bioethanol fireplace, eco-friendly fireplace,
          built-in fireplace, insert fireplace, freestanding fireplace, wood stove, pizza oven, braais, wood
          braais, gas braais, insert braais, drop-in braais, best braais, luxury fireplaces, modern fireplaces,
          contemporary fireplaces"
          jsonLD={jsonLD}
        />
        <ScrollToTop />
        <Layout>
            <Suspense fallback={<div></div>}>
              <a href="#message" className='_skip-link'>Skip to message</a>
              <HeroSlider />
            </Suspense>
            <MessageSection>
              <a href="#locations" className='_skip-link'>Skip to locations</a>
              <p className='_large-text-par' style={{marginBottom: '30px'}}>
                <span className='_light-italic'>“Our passion is to create</span> <span className='_gold'>warmth and memories</span><span className="_light-italic">.</span><br /> <span className="_light-italic">We believe in creating a lifestyle filled with </span><span className='_gold'>family and friends</span><span className="_light-italic">.”</span>
              </p>
            </MessageSection>
            <Suspense fallback={<div></div>}>
              <a href="#philosophy" className='_skip-link'>Skip to philosophy</a>
              <IndoorOutdoorGateway />
            </Suspense>
            <OurBrandPhilosophy />
            <Suspense fallback={<div></div>}>
              <ParallaxImageSection />
            </Suspense>
            <section className='_section _section-our-products'>
              <div className='_grid'>
                <div className='_column' style={{textAlign: 'center'}}>
                  <h2 className='_heading'><span className='_gold'>Our Products</span> <span className='_light-italic'>Options Available</span></h2>
                 <p>World-class products to suit your unique requirements. We offer only the highest quality products that your family will enjoy for years to come.</p>
                </div>
              </div>
              <div className='_grid _grid-4' style={{marginTop: '30px'}}>
                {this.props.categories.length > 0 && this.renderCategoryItems()}
              </div>
            </section>
            <Suspense fallback={<div></div>}>
              <Testimonials />
            </Suspense>
            <Suspense fallback={<div></div>}>
              <BlogSection />
            </Suspense>
            <SocialSection />
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: Object.values(state.publicCategoriesState)
  }
}

export default connect(mapStateToProps, {
  fetchPublicCategories
})(Home)