import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './authReducer'

import couponReducer from './couponReducer'

import userCreateReducer from './users/userCreateReducer'
import userUpdateReducer from './users/userUpdateReducer'
import usersReducer from './users/usersReducer'

import brandsReducer from './brands/brandsReducer'
import brandsBinReducer from './brands/brandsBinReducer'
import brandCreateReducer from './brands/brandCreateReducer'
import brandUpdateReducer from './brands/brandUpdateReducer'

import categoriesReducer from './categories/categoriesReducer'
import categoriesBinReducer from './categories/categoriesBinReducer'
import categoryCreateReducer from './categories/categoryCreateReducer'
import categoryUpdateReducer from './categories/categoryUpdateReducer'

import productsReducer from './products/productsReducer'
import productsBinReducer from './products/productsBinReducer'
import productCreateReducer from './products/productCreateReducer'
import productUpdateReducer from './products/productUpdateReducer'

import variationsReducer from './variations/variationsReducer'
import variationsBinReducer from './variations/variationsBinReducer'
import variationCreateReducer from './variations/variationCreateReducer'
import variationUpdateReducer from './variations/variationUpdateReducer'

import typesReducer from './product-types/typesReducer'
import typesBinReducer from './product-types/typesBinReducer'
import typeCreateReducer from './product-types/typeCreateReducer'
import typeUpdateReducer from './product-types/typeUpdateReducer'

import imagesReducer from './images/imagesReducer'
import imagesBinReducer from './images/imagesBinReducer'
import imageCreateReducer from './images/imageCreateReducer'
import imageUpdateReducer from './images/imageUpdateReducer'

import ordersReducer from './orders/ordersReducer'

import accountReducer from './account/accountReducer'
import accountUpdateReducer from './account/accountUpdateReducer'

import postsReducer from './posts/postsReducer'
import postsBinReducer from './posts/postsBinReducer'
import postCreateReducer from './posts/postCreateReducer'
import postUpdateReducer from './posts/postUpdateReducer'

import documentsReducer from './documents/documentsReducer'
import documentsBinReducer from './documents/documentsBinReducer'
import documentCreateReducer from './documents/documentCreateReducer'
import documentUpdateReducer from './documents/documentUpdateReducer'

import couponsReducer from './coupons/couponsReducer'
import couponsBinReducer from './coupons/couponsBinReducer'
import couponCreateReducer from './coupons/couponCreateReducer'
import couponUpdateReducer from './coupons/couponUpdateReducer'

import deliveryOptionsReducer from './delivery-options/deliveryOptionsReducer'
import deliveryOptionsBinReducer from './delivery-options/deliveryOptionsBinReducer'
import deliveryOptionCreateReducer from './delivery-options/deliveryOptionCreateReducer'
import deliveryOptionUpdateReducer from './delivery-options/deliveryOptionUpdateReducer'

import contactReducer from './contactReducer'
import bookingReducer from './bookingReducer'
import productEnquiryReducer from './productEnquiryReducer'
import publicProductsReducer from './publicProductsReducer'
import publicProductReducer from './publicProductReducer'
import publicPostsReducer from './publicPostsReducer'
import publicCategoriesReducer from './publicCategoriesReducer'
import publicBrandsReducer from './publicBrandsReducer'
import cartReducer from './cartReducer'
import checkoutReducer from './checkoutReducer'
import paymentStatusReducer from './paymentStatusReducer'
import newsletterSignupReducer from './newsletterSignupReducer'
import footerNewsletterSignupReducer from './footerNewsletterSignupReducer'
import downloadDocumentReducer from './downloadDocumentReducer'

//customer
import customerAuthReducer from './customerAuthReducer'
import customerAccountReducer from './customer/customerAccountReducer'
import customerAccountUpdateReducer from './customer/customerAccountUpdateReducer'
import customerOrdersReducer from './customer/customerOrdersReducer'

import cookieReducer from './cookieReducer'
import forgotPasswordReducer from './forgotPasswordReducer'
import forgotPasswordOtpReducer from './forgotPasswordOtpReducer'
import forgotPasswordResetReducer from './forgotPasswordResetReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState', 'customerAuthState', 'cartState', 'checkoutState', 'couponState', 'newsletterSignupState', 'cookieState', 'paymentStatusState']
}

const rootReducer = combineReducers({
    customerAuthState: customerAuthReducer,
    customerAccountState: customerAccountReducer,
    customerAccountUpdateState: customerAccountUpdateReducer,
    customerOrdersState: customerOrdersReducer,
    contactState: contactReducer,
    couponState: couponReducer,
    cartState: cartReducer,
    checkoutState: checkoutReducer,
    paymentStatusState: paymentStatusReducer,
    authState: authReducer,
    accountState: accountReducer,
    accountUpdateState: accountUpdateReducer,
    userUpdateState: userUpdateReducer,
    userCreateState: userCreateReducer,
    usersState: usersReducer,
    productsState: productsReducer,
    productsBinState: productsBinReducer,
    productCreateState: productCreateReducer,
    productUpdateState: productUpdateReducer,
    categoriesState: categoriesReducer,
    categoriesBinState: categoriesBinReducer,
    categoryCreateState: categoryCreateReducer,
    categoryUpdateState: categoryUpdateReducer,
    brandsState: brandsReducer,
    brandsBinState: brandsBinReducer,
    brandCreateState: brandCreateReducer,
    brandUpdateState: brandUpdateReducer,
    typesState: typesReducer,
    typesBinState: typesBinReducer,
    typeCreateState: typeCreateReducer,
    typeUpdateState: typeUpdateReducer,
    postsState: postsReducer,
    postsBinState: postsBinReducer,
    postCreateState: postCreateReducer,
    postUpdateState: postUpdateReducer,
    documentsState: documentsReducer,
    documentsBinState: documentsBinReducer,
    documentCreateState: documentCreateReducer,
    documentUpdateState: documentUpdateReducer,
    couponsState: couponsReducer,
    couponsBinState: couponsBinReducer,
    couponCreateState: couponCreateReducer,
    couponUpdateState: couponUpdateReducer,
    deliveryOptionsState: deliveryOptionsReducer,
    deliveryOptionsBinState: deliveryOptionsBinReducer,
    deliveryOptionCreateState: deliveryOptionCreateReducer,
    deliveryOptionUpdateState: deliveryOptionUpdateReducer,
    ordersState: ordersReducer,
    imagesState: imagesReducer,
    imagesBinState: imagesBinReducer,
    imageCreateState: imageCreateReducer,
    imageUpdateState: imageUpdateReducer,
    variationsState: variationsReducer,
    variationsBinState: variationsBinReducer,
    variationCreateState: variationCreateReducer,
    variationUpdateState: variationUpdateReducer,
    publicProductsState: publicProductsReducer,
    publicProductState: publicProductReducer,
    publicPostsState: publicPostsReducer,
    publicCategoriesState: publicCategoriesReducer,
    publicBrandsState: publicBrandsReducer,
    bookingState: bookingReducer,
    productEnquiryState: productEnquiryReducer,
    newsletterSignupState: newsletterSignupReducer,
    footerNewsletterSignupState: footerNewsletterSignupReducer,
    downloadDocumentState: downloadDocumentReducer,
    cookieState: cookieReducer,
    forgotPasswordState: forgotPasswordReducer,
    forgotPasswordOtpState: forgotPasswordOtpReducer,
    forgotPasswordResetState: forgotPasswordResetReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)