import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import {Link} from 'react-router-dom'
import SimpleHeading from './ui/SimpleHeading'
import CheckoutForm from './forms/CheckoutForm'
import {connect} from 'react-redux'
import {
  checkout,
  fetchCustomerAccountDetails
} from '../actions'
import CouponForm from './cart/CouponForm'

class Checkout extends React.Component {

  componentDidMount(){
    this.props.auth.token && this.props.fetchCustomerAccountDetails(this.props.auth.token)
  }

  onSubmit = (formValues, paymentMethod = 'pay-with-card') => {
    const orderId = this.props.submit.order_id ? this.props.submit.order_id : null
    this.props.checkout(this.props.auth.token, paymentMethod, formValues, orderId)
  }

  render(){

    return (
      <>
        <Seo
          title="Shopping Cart"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/cart" rel="index up up up">Shopping Cart</Link>&nbsp;/&nbsp;
            Checkout
          </Breadcrums>
          <SimpleHeading>Checkout</SimpleHeading>
          <section className='_section _section-checkout'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_form-panel'>
                  <CouponForm />
                  <CheckoutForm 
                    onSubmit={this.onSubmit} 
                    cart={this.props.cart} 
                    auth={this.props.auth} 
                    initialValues={this.props.customerDetails}
                    errors={this.props.submit.errors ? this.props.submit.errors : null}
                  />
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    submit: state.checkoutState,
    cart: state.cartState,
    auth: state.customerAuthState,
    customerDetails: state.customerAccountState.details 
  }
}

export default connect(mapStateToProps, {
  checkout,
  fetchCustomerAccountDetails
})(Checkout)