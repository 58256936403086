import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import AuthForm from './forms/AuthForm'
import {
  signIn
} from '../actions'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'

class Signin extends React.Component {

  onSubmit = formValues => {
    this.props.signIn(formValues)
  }

  render(){

    if(this.props.auth.token){
      return <Redirect to="/dashboard" />
    }

    return (
      <>
        <Seo
          title="Admin Sign In"
          description={null}
          image={null}
        ><meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Admin Dashboard Signin
          </Breadcrums>
          <SimpleHeading>Admin Sign In</SimpleHeading>
          <section className='_section _section-admin-signin'>
            <div className='_grid'>
              <div className='_column'>   
                <div className='_panel'>
                  <h3>Sign In to the Admin Dashboard.</h3>
                  <br />
                  <AuthForm 
                      onSubmit={this.onSubmit} 
                      errors={this.props.auth.errors ? this.props.auth.errors : null}
                  />
                </div>
              </div>    
            </div>    
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.authState
  }
}

export default connect(mapStateToProps, {
  signIn
})(Signin)