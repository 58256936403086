import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

class PaymentSwitch extends React.Component {

  render(){

    if(!this.props.data.response_data)
    return <Redirect to="/checkout" />

    const responseData = JSON.parse(this.props.data.response_data)
    const errorCode = '200.300.404'

    if(responseData.result.code === errorCode)
    return <Redirect to="/checkout" />

    const {url, method, parameters} = responseData.redirect
    const amount = parameters[0].value
    const connector = parameters[1].value
    const currency = parameters[2].value
    const transaction = parameters[3].value
    const PaymentMethod = responseData.paymentBrand === 'EFTSECURE' ? 'EFT SECURE' : responseData.paymentBrand

    return (
      <>
        <Seo
          title={`${PaymentMethod} | Checkout Payment`}
          description={null}
          image={null}
        >
          <meta name="robots" content="noindex, nofollow" />
        </Seo>
        <ScrollToTop />
        <Layout>
        <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/cart" rel="index up up up">Shopping Cart</Link>&nbsp;/&nbsp;
            <Link to="/checkout" rel="index up up up">Checkout</Link>&nbsp;/&nbsp;
            {PaymentMethod}
          </Breadcrums>
          <SimpleHeading>{PaymentMethod}</SimpleHeading>
          <section className='_section _section-payment'>
            <div className='_grid'>
              <div className='_column'>
                <div className='_panel' style={{textAlign:'center'}}>

                  <p>This is the {PaymentMethod} payment process. After the payment has been completed you will be redirected to an order confirmation page.</p><br />

                  <form name="eftForm" action={url} method={method} acceptCharset="utf-8">
                    <input type="hidden" name="amount" value={amount} />
                    <input type="hidden" name="connector" value={connector} />
                    <input type="hidden" name="currency" value={currency} />
                    <input type="hidden" name="transaction" value={transaction} />
                    <input type="submit" value="Next" className='_button' style={{width: '145px'}} />
                  </form>

                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.checkoutState
  }
}

export default connect(mapStateToProps)(PaymentSwitch)