import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {connect} from 'react-redux'
import {
    booking,
    resetForms
} from '../actions'
import Form from './forms/BookingForm'
import Breadcrums from './ui/Breadcrums'
import PageBanner from './ui/PageBanner'
import {Redirect} from 'react-router-dom'

import banner from '../assets/img/book-a-site-visit@2x.jpg'

class Booking extends React.Component {

  constructor(props){
    super(props)

    let urlSearchParams = new URLSearchParams(window.location.search)
    this.productTitle = urlSearchParams.get('enquire')
  }

  componentWillUnmount(){
      this.props.resetForms()
  }

  componentDidUpdate(){
    if(this.props.send.errors){
      setTimeout(() => {
          this.props.resetForms()
      }, 7000)
    }
  }

  onSubmit = formValues => {
      this.props.booking(formValues)
  }

  render(){

    if(!this.props.send.success)
    return (
      <>
        <Seo
          title="Schedule A Meeting"
          description="Beauty Fires’ sales representatives are available to schedule an in-store or virtual meeting to answer
          your questions and assess your needs. We look forward to assisting you."
          image={banner}
          keywords="schedule a meeting, contact a sales representative, in-store meetings, virtual meetings, book a meeting"
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Schedule A Meeting
          </Breadcrums>

          <PageBanner 
            image={banner}
            heading="SCHEDULE A MEETING WITH A SALES ASSOCIATE"
          >
            <div className='_horline' />
          </PageBanner>
          
          <section className='_section _section-contact'>
            <div className='_grid'>
              <div className='_column'>
                
                <div className='_form-panel'>
                  <h1 className='_page-heading'>MEETING INFORMATION</h1>
                  <Form
                      initialValues={{comment: this.productTitle ? `I have an inquiry about the ${this.productTitle}.` : null}}
                      onSubmit={this.onSubmit} 
                      errors={this.props.send.errors ? this.props.send.errors : null}
                  />
                </div> 
              </div>
            </div>
          </section>
        </Layout>
      </>
    )

    return <Redirect to="/booking/thankyou" />
  }
}

const mapStateToProps = state => {
  return {
      send: state.bookingState
  }
}

export default connect(mapStateToProps, {
  booking,
  resetForms
})(Booking)