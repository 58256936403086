import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {connect} from 'react-redux'
import {
    resetForms,
    customerRegistration,
    customerLogin
} from '../actions'
import AccountLoginForm from './forms/AccountLoginForm'
import AccountRegisterForm from './forms/AccountRegisterForm'
import Breadcrums from './ui/Breadcrums'
import SimpleHeading from './ui/SimpleHeading'
import {Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'

class Account extends React.Component {

  componentWillUnmount(){
      this.props.resetForms()
  }

  componentDidUpdate(){
      if(!this.props.auth.errors)
      return

      setTimeout(() => {
          this.props.resetForms()
      }, 7000)
  }

  onSubmitLogin = formValues => {
      this.props.customerLogin(formValues)
  }

  onSubmitRegister = formValues => {
    this.props.customerRegistration(formValues)
  }

  render(){

    if(!this.props.auth.token){
      return (
        <>
          <Seo
            title="Log In / Register"
            description={null}
            image={null}
          />
          <ScrollToTop />
          <Layout>
            <Breadcrums>
              <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
              My Account
            </Breadcrums>
            <SimpleHeading>Log In / Register</SimpleHeading>
                      
            <section className='_section _section-my-account'>
              <div className='_grid _grid-2'>
                <div className='_column'>
                  
                  <div className='_login'>
                    <h3>LOG IN</h3>
                    <AccountLoginForm
                        onSubmit={this.onSubmitLogin} 
                        errors={this.props.auth.errorsLogin ? this.props.auth.errorsLogin : null}
                    />
                    <p className='_forgot-password' style={{textDecoration: 'underline'}}><Link to="/forgot-password">Forgot my password?</Link></p>
                  </div>
                </div>
                <div className='_column'>
                  
                  <div className='_register'>
                    <h3>REGISTER</h3>
                    <AccountRegisterForm
                        onSubmit={this.onSubmitRegister} 
                        errors={this.props.auth.errorsregister ? this.props.auth.errorsregister : null}
                    />
                  </div>
                </div>
              </div>
            </section>
          </Layout>
        </>
      )
    }

    return (
      <Redirect to="/account/dashboard" />
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.customerAuthState
  }
}

export default connect(mapStateToProps, {
  resetForms,
  customerRegistration,
  customerLogin
})(Account)