import React, {Suspense} from 'react'
// import FlocklerEmbed from '../../FlocklerEmbed'

const FlocklerEmbed = React.lazy(() => import('../../FlocklerEmbed'))

class SocialSection extends React.Component {

  render(){

    return(
      <section className='_section _section-social-feed'>
        <div className='_grid'>
          <div className='_column'>
            <h2 className='_heading'><span className='_gold'>Social</span> <span className='_light-italic'>Feed</span></h2>
            <Suspense fallback={<div></div>}>
                <FlocklerEmbed
                uuidFlockler="182ea4e358805f1e0deffff69a356b3e"
                embedCodeId="182ea46f38006d611c31c51fba34475c/182ea4e358805f1e0deffff69a356b3e"
                />
            </Suspense>
          </div>
        </div>
      </section>
    )
  }
}

export default SocialSection