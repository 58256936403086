const CustomEmailShareButton = props => {
  return (
    <a href={`mailto:?subject=${props.subject}&body=${props.body}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 30">
        <path id="iconmonstr-email-10" d="M15,.02a15,15,0,1,0,15,15,15,15,0,0,0-15-15Zm8.737,8.725L15,15.827,6.261,8.745H23.737Zm.012,12.5H6.25V10.614L15,17.7l8.75-7.09Z" transform="translate(0 -0.02)" fill="#fafafa"/>
      </svg>
    </a>
  )
}

export default CustomEmailShareButton