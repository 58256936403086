import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

const UserCartNav = props => {
  return (
    <nav className='_nav _user-cart-nav'>
      <ul>
        <li>
          <Link to="/account">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path id="user-icon" d="M9,1.5A3.75,3.75,0,1,1,5.25,5.251,3.754,3.754,0,0,1,9,1.5ZM9,0a5.25,5.25,0,1,0,5.25,5.251A5.25,5.25,0,0,0,9,0Zm4.777,10.015a6.793,6.793,0,0,1-1.244.976A9.586,9.586,0,0,1,16.262,16.5H1.722a9.278,9.278,0,0,1,3.73-5.518A6.756,6.756,0,0,1,4.208,10,11.031,11.031,0,0,0,0,18H18A11.268,11.268,0,0,0,13.777,10.015Z" fill="#F0F0F0"/>
            </svg>
          </Link>
        </li>
        <li>
          <Link to="/cart">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
              <path id="cart-icon" d="M24,3l-.743,2H21.328L17.854,17H4.615L0,6H16.812l-.564,2H3.008l2.937,7H16.373L19.805,3ZM8.5,18A1.5,1.5,0,1,0,10,19.5,1.5,1.5,0,0,0,8.5,18Zm6.9-7-1.9,7A1.5,1.5,0,1,0,15,19.5,1.5,1.5,0,0,0,13.5,18Z" transform="translate(0 -3)" fill="#F0F0F0"/>
            </svg>
            {
              props.totalQuantity > 0 && (
                <div className='_cart-items-quantity'>{props.totalQuantity}</div>
              )
            }
            
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const mapStateToProps = state => {
  return{
    totalQuantity: state.cartState.totalQuantity
  }
}

export default connect(mapStateToProps)(UserCartNav)