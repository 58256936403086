import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {Link, Redirect} from 'react-router-dom'
import SocialIcons from '../ui/SocialIcons'
import NewsletterForm from '../forms/NewsletterFooterForm'
import {
  fetchPublicBrands,
  footerNewsletterSignup,
  resetForms
} from '../../actions'
import {connect} from 'react-redux'
import {BASE_PATH} from '../../paths'
import {scrollToPosition} from '../../utils'
import logo from '../../assets/img/Icons/beautyfires-logo-footer.svg'

class Footer extends React.Component {

  componentDidMount(){
    this.props.fetchPublicBrands()
  }

  componentWillUnmount(){
    this.props.resetForms()
  }

  renderSlickItems = () => {
    return this.props.brands.map(({title, logo, slug}, index) => {
      return (
        <div key={index}>
          <Link to={`/products?brand=${slug}`} className="_slick-item" onClick={() => scrollToPosition("#pagebanner", 300)}>
            <img src={BASE_PATH+logo} alt={title} width="200" height="160" />
          </Link>
        </div>
      )
    })
  }

  onSubmit = formValues => {
    this.props.footerNewsletterSignup(formValues)
  }

  render(){

    if(this.props.subscribed.success){
      return <Redirect to="/newsletter-subscription/thankyou" />
    }

    const date = new Date()
    const year = date.getFullYear()

    const settings = {
      infinite: true,
      autoplaySpeed: 5000,
      speed: 2000,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      easing: 'cubic-bezier(0.85, 0, 0.15, 1)',
      pauseOnHover: false,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
              slidesToShow: 4,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
            }
        }
      ],
    }

    return (
      <footer>
        <div className='_grid'>
          <div className='_column'>
            <Slider {...settings}>
              {this.props.brands.length > 0 && this.renderSlickItems()}
            </Slider>
          </div>
        </div>
        <div className='_grid'>
          <div className='_column'>
            <div className='_flex-panel'>
              <div className='_flex-item'>
                <img className='_logo' src={logo} alt="" width="200" height="49" />
              </div>
              <div className='_flex-item'>
                <h3>HELPFUL LINKS</h3>
                <ul>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li>
                    <Link to="/products">PRODUCTS</Link>
                  </li>
                  <li>
                    <Link to="/blog">BLOG</Link>
                  </li>
                  <li>
                    <Link to="/booking" className='book-a-site-visit'>BOOK A SITE VISIT</Link>
                  </li>
                  <li>
                    <Link to="/contact/cape-town">CONTACT US</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">TERMS & CONDITIONS</Link>
                  </li>
                  <li>
                    <Link to="/shipping-policy">SHIPPING POLICY</Link>
                  </li>
                </ul>
              </div>
              <div className='_flex-item _flex-item-flex-panel'>
                <div>
                  <h3>CAPE TOWN</h3>
                  <ul>
                    <li>
                      <a href="rel:+27214619821">+27 21 461 9821</a>
                    </li>
                    <li>
                      <a href="rel:+27611028660">+27 61 102 8660</a>
                    </li>
                    <li>
                      <a href="mailto:salescpt@beautyfires.com">salescpt@beautyfires.com</a>
                    </li>
                  </ul>
                </div>
                <br />
                <div>
                  <h3>JOHANNESBURG</h3>
                  <ul>
                    <li>
                      <a href="rel:+27611029494">+27 61 102 9494</a>
                    </li>
                    <li>
                      <a href="mailto:salesjhb@beautyfires.com">salesjhb@beautyfires.com</a>
                    </li>
                  </ul>
                </div>
                <br />
                <div>
                  <h3>GARDEN ROUTE</h3>
                  <ul>
                    <li>
                      <a href="rel:+27726503681">+27 44 868 0138</a>
                    </li>
                    <li>
                      <a href="mailto:salesgr@beautyfires.com">salesgr@beautyfires.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='_flex-item _subcribe'>
                <h3>SUBSCRIBE TO OUR BLOG</h3>
                <p>Subscribe to our <strong>BLOG</strong> for exclusive news and articles and enjoy a <strong>5% DISCOUNT VOUCHER</strong> when purchasing in-store.</p>
                <NewsletterForm onSubmit={this.onSubmit} show={true} />
              </div>
            </div>
          </div>
        </div>
        <div className='_grid'>
          <div className='_column'>
            <div className='_footer-bottom'>
              <SocialIcons />
              <p className='_small'>All original content © {year} <strong>BEAUTY FIRES</strong><br />
              Powered by <strong><a href="https://optimalonline.co.za" target="_blank" rel="noreferrer">OPTIMAL ONLINE</a></strong></p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = state => {
  return {
    brands: Object.values(state.publicBrandsState),
    subscribed: state.footerNewsletterSignupState
  }
}

export default connect(mapStateToProps, {
  fetchPublicBrands,
  footerNewsletterSignup,
  resetForms
})(Footer)