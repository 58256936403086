import {Link} from 'react-router-dom'
import logo from '../../assets/img/Icons/beautyfires-logo.svg'

const Logo = () => {
  return (
    <Link to="/" className="_logo">
      <img src={logo} alt="Beauty Fires" width="68" height="79" />
    </Link>
  )
}

export default Logo