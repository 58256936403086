import admin from '../apis/admin'

import {
  FETCH_DOCUMENTS,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_FAILED,
  FETCH_DOCUMENT,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_FAILED,
  DELETE_DOCUMENT,
  RESTORE_DOCUMENT,
  FETCH_DOCUMENTS_BIN,
  FORCE_DELETE_DOCUMENT,
} from './types'

export const fetchDocuments = (token, productId) => async dispatch => {
    
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/documents`, config)

      dispatch({
          type: FETCH_DOCUMENTS,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch documents failed')
  }
}

export const createDocument = (token, productId, formValues) => async dispatch => {
  
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/documents/create`, formValues, config)

      dispatch({
          type: CREATE_DOCUMENT,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_DOCUMENT_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchDocument = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/documents/${id}`, config)

      dispatch({
          type: FETCH_DOCUMENT,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Fetch document failed')
  }
}

export const updateDocument = (token,  productId, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/documents/${id}/update`, formValues, config)

      dispatch({
          type: UPDATE_DOCUMENT,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_DOCUMENT_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteDocument = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/documents/${id}/delete`, {}, config)

      dispatch({
          type: DELETE_DOCUMENT,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Delete document failed')        
  }
}

export const fetchDocumentsBin = (token, productId) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get(`admin/products/${productId}/documents/bin`, config)

      dispatch({
          type: FETCH_DOCUMENTS_BIN,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('fetch documents bin failed')
  }
}

export const restoreDocument = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post(`admin/products/${productId}/documents/${id}/restore`, {}, config)

      dispatch({
          type: RESTORE_DOCUMENT,
          payload: response.data,
          productId
      })
      
  } catch (error) {
      console.log('Document restore failed')
  }
}

export const forceDeleteDocument = (token, productId, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post(`admin/products/${productId}/documents/${id}/delete`, {}, config)

      dispatch({
          type: FORCE_DELETE_DOCUMENT,
          payload: id,
          productId,
          id
      })
      
  } catch (error) {
      console.log('Force delete document failed')        
  }
}