const SimpleHeading = props => {
  return (
    <section className='_section'>
      <div className='_grid _grid-fluid'>
        <div className='_column'>
          <div className="_simple-heading">
            <h1>{props.children}</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SimpleHeading