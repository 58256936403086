import React from 'react'
import {connect} from 'react-redux'
import {
  updateQuantity
} from '../../actions'
import Popup from '../ui/Popup'

class QuantityForm extends React.Component {

  constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      quantity: this.props.quantity,
      popupVisible: false
    }
  }

  handleChange(e){
    if(!e.target.value)
    return
    
    let newQty = parseInt(e.target.value)
    this.setState({quantity: newQty})

    setTimeout(() => {
      this.props.updateQuantity(this.props.id, newQty)
      // this.showPopup()
    }, 500);
  }

  closePopup = () => {
    this.setState({popupVisible: false})
  }

  showPopup = () => {
    this.setState({popupVisible: true})
  }

  render(){
    return(
      <>
        <Popup show={this.state.popupVisible} onClick={this.closePopup} heading="Message">
            <p className='_secondary-heading'>Your cart has been updated</p>
            <div className='_popup-buttons'>
              <button className='_button' onClick={this.closePopup}>OK</button>
            </div>
          </Popup>
        <input 
          className='_cart-qty-input' 
          type="number" 
          value={this.state.quantity} 
          onChange={this.handleChange} 
          min="1" 
        />
      </>
    )
  }
}

export default connect(null, {
  updateQuantity
})(QuantityForm)
