import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {connect} from 'react-redux'
import {
    sendContactMessage,
    resetForms
} from '../actions'
import Form from './forms/ContactForm'
import Breadcrums from './ui/Breadcrums'
import { Redirect } from 'react-router-dom'

class Contact extends React.Component {

  componentWillUnmount(){
      this.props.resetForms()
  }

  componentDidUpdate(){
      if(!this.props.send.errors)
      return

      setTimeout(() => {
          this.props.resetForms()
      }, 7000)
  }

  renderContactBanner = () => {
    let branch = this.props.match.params.branch

    switch (branch) {      
      case 'johannesburg':
        return (
          <section className='_section _section-banner-contact'>
            <div className='_flex-box'>
              <div className='_item _map'>
                <iframe title="map-cape-town" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.040226167367!2d28.00877101554967!3d-26.03223981332757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e957107bb5f493d%3A0x6cab1df3b5342d6d!2sBeauty%20Fires!5e0!3m2!1sen!2sza!4v1661237531227!5m2!1sen!2sza" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>
              </div>
              <div className='_item _intro'>
                <h1 className='_page-heading _gold'>JOHANNESBURG</h1>
                <div className='_border' style={{ margin: '0 0 15px 0' }} />
                <table className='_contact-table'>
                  <tbody>
                    <tr>
                      <td>
                        <div className='_flex'>
                          <div className='_letter'>T:</div>
                          <div className='_text'>
                            <a href="tel:+27 61 102 9494">+27 61 102 9494</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>E:</div>
                          <div className='_text'>
                            <a href="mailto:salesjhb@beautyfires.com">salesjhb@beautyfires.com</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>A:</div>
                          <div className='_text'>
                            <a href="https://goo.gl/maps/N9r1zocAJgdGujet8" target="_blank" rel="noreferrer">Showroom is on Leslie, Corner of William Nicol Dr & Leslie Ave, Fourways, Sandton, 2191</a>
                          </div>
                        </div>
                        <div className='_border' style={{ margin: '30px 0 30px 0' }} />
                        <div className='_hours'>
                          <p className='_gold' style={{marginBottom: '0'}}><strong>WORKING HOURS:</strong></p>
                          <p style={{marginTop: '0'}}>Mon - Thurs: 8:30 am - 5 pm<br />
                          Fri: 8:30 am - 4 pm<br />
                          Sat: 9 am - 1 pm<br />
                          Sundays and Public Holidays: Closed</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )      
      case 'garden-route':
        return (
          <section className='_section _section-banner-contact'>
            <div className='_flex-box'>
              <div className='_item _map'>
                <iframe title="map-cape-town" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.434354978958!2d22.458724815726995!3d-33.95567373100543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dd605309121a905%3A0x4027549b005f8d3!2s66%20Courtenay%20St%2C%20Bodorp%2C%20George%2C%206529!5e0!3m2!1sen!2sza!4v1661237716767!5m2!1sen!2sza" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>
              </div>
              <div className='_item _intro'>
                <h1 className='_page-heading _gold'>GARDEN ROUTE</h1>
                <div className='_border' style={{ margin: '0 0 15px 0' }} />
                <table className='_contact-table'>
                  <tbody>
                    <tr>
                      <td>
                        <div className='_flex'>
                          <div className='_letter'>T:</div>
                          <div className='_text'>
                            <a href="tel:044 868 0138">044 868 0138</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>E:</div>
                          <div className='_text'>
                            <a href="mailto:salesgr@beautyfires.com">salesgr@beautyfires.com</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>A:</div>
                          <div className='_text'>
                            <a href="https://goo.gl/maps/uGEGQvj9pQrLXPxd7" target="_blank" rel="noreferrer">66 Courtney Street, Bodorp, George, 6529</a>
                          </div>
                        </div>
                        <div className='_border' style={{ margin: '30px 0 30px 0' }} />
                        <div className='_hours'>
                          <p className='_gold' style={{marginBottom: '0'}}><strong>WORKING HOURS:</strong></p>
                          <p style={{marginTop: '0'}}>Mon - Thurs: 8:00 am – 4:30 pm<br />
                          Fri: 8:00 am - 4 pm<br />
                          Sat: 9 am - 1 pm<br />
                          Sundays and Public Holidays: Closed</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )     
      default:
        return (
          <section className='_section _section-banner-contact'>
            <div className='_flex-box'>
              <div className='_item _map'>
                <iframe title="map-cape-town" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.396990277238!2d18.473869615725754!3d-33.9051808283805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5c3524e5420b%3A0x40869ad445c73633!2s80%20Marine%20Dr%2C%20Paarden%20Eiland%2C%20Cape%20Town%2C%207405!5e0!3m2!1sen!2sza!4v1661179280328!5m2!1sen!2sza" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>
              </div>
              <div className='_item _intro'>
                <h1 className='_page-heading _gold'>CAPE TOWN</h1>
                <div className='_border' style={{ margin: '0 0 15px 0' }} />
                <table className='_contact-table'>
                  <tbody>
                    <tr>
                      <td>
                        <div className='_flex'>
                          <div className='_letter'>T:</div>
                          <div className='_text'>
                            <a href="tel:+27 21 461 9821">+27 21 461 9821</a><br />
                            <a href="tel:+27 61 102 8660">+27 61 102 8660</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>E:</div>
                          <div className='_text'>
                            <a href="mailto:salescpt@beautyfires.com">salescpt@beautyfires.com</a>
                          </div>
                        </div>
                        <div className='_flex'>
                          <div className='_letter'>A:</div>
                          <div className='_text'>
                            <a href="https://goo.gl/maps/1va73kRaKYKVmKiz8" target="_blank" rel="noreferrer">80 Marine Drive, Paarden Island, Cape Town, 7405</a>
                          </div>
                        </div>
                        <div className='_border' style={{ margin: '30px 0 30px 0' }} />
                        <div className='_hours'>
                          <p className='_gold' style={{marginBottom: '0'}}><strong>WORKING HOURS:</strong></p>
                          <p style={{marginTop: '0', marginBottom: '0'}}>Mon - Thurs:  8:30 am - 5 pm<br />
                            Fri:  8:30 am - 4 pm<br />
                            Sat:  9 am - 1 pm<br />
                            Sundays and Public Holidays:  Closed</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )
    }
  }

  renderSeo = () => {
    
    let branch = this.props.match.params.branch

    switch (branch) {

      case 'johannesburg':
        return(
          <Seo
            title="Johannesburg | Contact Us"
            description="Beauty Fires’ sales representatives are available in Johannesburg to schedule an in-store or virtual meeting to answer your questions and assess your needs |T: 27 61 102 9494 |E: salesjhb@beautyfires.com"
            keywords="Beauty Fires Johannesburg, Contact Beauty Fires, Beauty Fires near me, Find Beauty Fires"
          />
        )

      case 'garden-route':
        return(
          <Seo
            title="Garden Route | Contact Us"
            description="Beauty Fires’ sales representatives are available in the Garden Route to schedule an in-store or virtual meeting to answer your questions and assess your needs |T: 27 44 868 0138 |E: salesgr@beautyfires.com"
            keywords="Beauty Fires Garden Route, Contact Beauty Fires, Beauty Fires near me, Find Beauty Fires"
          />
        )

      default:
        return(
          <Seo
            title="Cape Town | Contact Us"
            description="Beauty Fires’ sales representatives are available in Cape Town to schedule an in-store or virtual meeting to answer your questions and assess your needs |T: 27 21 461 9821 |E: salescpt@beautyfires.com"
            keywords="Beauty Fires Cape Town, Contact Beauty Fires, Beauty Fires near me, Find Beauty Fires"
          />
        )
    }
  }

  onSubmit = formValues => {
      this.props.sendContactMessage(formValues, this.props.match.params.branch ? this.props.match.params.branch : null)
  }

  render(){

    if(!this.props.send.success)
    return (
      <>
        {this.renderSeo()}
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            Contact Us
          </Breadcrums>

          {this.renderContactBanner()}
          
          <section className='_section _section-contact'>
            <div className='_grid'>
              <div className='_column'>
                
                <div className='_form-panel'>
                  <h1 className='_page-heading'>CONTACT FORM</h1>
                  <Form
                      onSubmit={this.onSubmit} 
                      errors={this.props.send.errors ? this.props.send.errors : null}
                      branch={this.props.match.params.branch ? this.props.match.params.branch : null}
                  />
                </div> 
              </div>
            </div>
          </section>
        </Layout>
      </>
    )

    return <Redirect to="/contact/thankyou" />
  }
}

const mapStateToProps = state => {
  return {
      send: state.contactState
  }
}

export default connect(mapStateToProps, {
  sendContactMessage,
  resetForms
})(Contact)