import _ from 'lodash'
import {
    FETCH_PRODUCTS_BIN,
    RESTORE_PRODUCT,
    FORCE_DELETE_PRODUCT
} from '../../actions/types'

const initialState = {}

const productsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PRODUCTS_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_PRODUCT:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_PRODUCT:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default productsBinReducer