import React from 'react'
import Layout from './layout/Layout'
import Seo from '../Seo'
import ScrollToTop from '../ScrollToTop'
import {connect} from 'react-redux'
import {
    productEnquiry,
    fetchPublicProduct,
    resetForms
} from '../actions'
import Form from './forms/ProductEnquiryForm'
import Breadcrums from './ui/Breadcrums'
import PageBanner from './ui/PageBanner'
import {Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'

import banner from '../assets/img/enquiry@2x.jpg'

class ProductEnquiry extends React.Component {

  componentDidMount(){
    this.props.fetchPublicProduct(this.props.match.params.slug)
  }

  componentWillUnmount(){
      this.props.resetForms()
  }

  componentDidUpdate(){
    if(this.props.send.errors){
      setTimeout(() => {
          this.props.resetForms()
      }, 7000)
    }
  }

  onSubmit = formValues => {
      this.props.productEnquiry(formValues, this.props.match.params.slug)
  }

  render(){

    if(!this.props.data)
    return <></>

    const { title, slug } = this.props.data

    if(!this.props.send.success)
    return (
      <>
        <Seo
          title={`${title} | Enquiry`}
          description="Beauty Fires’ sales representatives are available to schedule an in-store or virtual meeting to answer
          your questions and assess your needs. We look forward to assisting you."
          image={banner}
          keywords="schedule a meeting, contact a sales representative, in-store meetings, virtual meetings, book a meeting"
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <a href="/" rel="index up up up">Home</a>&nbsp;/&nbsp;
            <Link to="/products">Products</Link>&nbsp;/&nbsp;
            <Link to={`/products/${slug}`}>{title}</Link>&nbsp;/&nbsp;Enquire
          </Breadcrums>

          <PageBanner 
            image={banner}
            heading="CONTACT US REGARDING YOUR ENQUIRY"
          >
            <div className='_horline' />
          </PageBanner>
          
          <section className='_section _section-contact'>
            <div className='_grid'>
              <div className='_column'>
                
                <div className='_form-panel'>
                  <h1 className='_page-heading'>ENQUIRE NOW</h1>
                  <Form
                      initialValues={{comment: title ? `I have an enquiry about the ${title}.` : null}}
                      onSubmit={this.onSubmit} 
                      errors={this.props.send.errors ? this.props.send.errors : null}
                  />
                </div> 
              </div>
            </div>
          </section>
        </Layout>
      </>
    )

    return <Redirect to={`/products/${this.props.match.params.slug}/enquire/thankyou`} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      data: state.publicProductState[ownProps.match.params.slug],
      send: state.productEnquiryState
  }
}

export default connect(mapStateToProps, {
  productEnquiry,
  fetchPublicProduct,
  resetForms
})(ProductEnquiry)