import React, { useState, useEffect } from "react";
import history from '../../history'

const SearchProducts = () => {

  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(term);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [term]);

  useEffect(() => {
    const search = async () => {
      history.push(`/products?search=${debouncedTerm}`)
    };
    if (debouncedTerm) {
      search();
    }
  }, [debouncedTerm]);

  return (
    <div className="_search-container">
      <input 
        type="text" 
        className="_form-element _search" 
        placeholder="Search for products here" 
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      />
    </div>
  )
}

export default SearchProducts