import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    createCoupon
} from '../../../actions/coupons'
import Form from '../../forms/CouponCreateForm'

class DashboardCouponCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createCoupon(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/coupons" />
        }

        return(
            <DashboardLayout 
                heading="Create Coupon" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/coupons">Coupon</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/coupons" className='_button _button-3'>Back</Link>
                </div>

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.couponCreateState
    }
}

export default connect(mapStateToProps, {
    createCoupon,
    resetForms
})(DashboardCouponCreate)