const renderHeading = data => {
  if(data.logo){
    return <img src={data.logo} alt={data.heading ? data.heading : ""} width="200" height="160" />
  } else {
    return <h1 className='_page-heading'>{data.heading}</h1>
  }
}

const PageBanner = props => {
  return(
    <section className='_section _section-banner' id="pagebanner">
      <div className='_flex-box'>
        <div className='_item _image' style={{backgroundImage: `url(${props.image})`}} />
        <div className='_item _intro'>
          {renderHeading(props)}
          
          {props.children}
        </div>
      </div>
    </section>
  )
}

export default PageBanner