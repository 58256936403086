import {
    CREATE_POST,
    CREATE_POST_FAILED,
    RESET_FORMS
} from '../../actions/types'

const initialState = {}

const createPostReducer = (state = initialState, action) => {
    switch(action.type){
        case CREATE_POST:
            return {
                details: action.payload,
                success: 'Post created successfully',
            }
        case CREATE_POST_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default createPostReducer