import React from 'react'

const HamburgerIcon = props => {

  return (
    <button 
      className={`_hamburger-icon _mobile ${props.menuOpen ? '_close' : ''}`} 
      onClick={props.onMenuToggle}>
      <div>
        <span /><span />
      </div>
    </button>
  )
}

export default HamburgerIcon