import React, {Suspense} from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms
} from '../../../actions'
import {
    createType
} from '../../../actions/product-types'

const Form = React.lazy(() => import('../../forms/TypeCreateForm'))

class DashboardTypeCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createType(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/types" />
        }

        return(
            <DashboardLayout 
                heading="Create Type" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/types">Type</Link> / Create
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/types" className='_button _button-3'>Back</Link>
                </div>
                <Suspense fallback={<></>}>
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                    />
                </Suspense>
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.typeCreateState
    }
}

export default connect(mapStateToProps, {
    createType,
    resetForms
})(DashboardTypeCreate)