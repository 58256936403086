import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
  fetchCustomerAccountDetails,
  customerUpdate,
  resetForms
} from '../../actions'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import {Link, Redirect} from 'react-router-dom'
import AccountLayout from './AccountLayout'
import BillingUpdateForm from '../forms/BillingUpdateForm'

class AccountDashboardBilling extends React.Component {

  componentWillUnmount(){
    this.props.resetForms()
  }

  componentDidUpdate(){
    if(this.props.submit.errors){
      setTimeout(() => {
          this.props.resetForms()
      }, 7000)
    }
  }

  componentDidMount(){
    this.props.fetchCustomerAccountDetails(this.props.auth.token)
  }

  onSubmit = formValues => {
    this.props.customerUpdate(this.props.auth.token, formValues)
  }

  renderContent = () => {
    if(!this.props.details)
    return <></>

    if(this.props.submit.success)
    return (
      <>
        <h3>ACCOUNT BILLING</h3>
        <p>You have successfully updated your billing details</p>
      </>
    )

    return (
      <>
        <h3>BILLING DETAILS</h3>
        <BillingUpdateForm 
          initialValues={this.props.details}  
          onSubmit={this.onSubmit} 
          errors={this.props.submit.errors ? this.props.submit.errors : null}
        />
      </>
    )
  }

  render(){

    if(!this.props.auth.token)
    return (
      <Redirect to="/account" />
    )

    return (
      <>
        <Seo
          title="Billing Details | My Account"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/account/dashboard" rel="index up up up">my Account</Link> / Billing Details
          </Breadcrums>
          <SimpleHeading>My Account</SimpleHeading>
          <AccountLayout>
            {this.renderContent()}
          </AccountLayout>          
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.customerAuthState,
      details: state.customerAccountState.details,
      submit: state.customerAccountUpdateState
  }
}

export default connect(mapStateToProps, {
  fetchCustomerAccountDetails,
  customerUpdate,
  resetForms
})(AccountDashboardBilling)