import _ from 'lodash'
import {
    FETCH_IMAGES_BIN,
    RESTORE_IMAGE,
    FORCE_DELETE_IMAGE
} from '../../actions/types'

const initialState = {}

const imagesBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_IMAGES_BIN:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }  
        case RESTORE_IMAGE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload.id)
            }
        case FORCE_DELETE_IMAGE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default imagesBinReducer