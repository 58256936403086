import {
    NEWSLETTER_SIGNUP,
    NEWSLETTER_SIGNUP_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {
  details: null,
  success: null,
  errors: null
}

const newsletterSignupReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEWSLETTER_SIGNUP:
            return {
                details: action.payload,
                success: true,
                errors: null
            }
        case NEWSLETTER_SIGNUP_FAILED:
            return {
              ...state,
              errors: action.payload
            }
        case RESET_FORMS:
            return {
              ...state,
              details: null,
              errors: null,
            }
        default:
            return state
    }
}

export default newsletterSignupReducer