import admin from '../apis/admin'

import {
  FETCH_BRANDS,
  CREATE_BRAND,
  CREATE_BRAND_FAILED,
  FETCH_BRAND,
  UPDATE_BRAND,
  UPDATE_BRAND_FAILED,
  DELETE_BRAND,
  RESTORE_BRAND,
  FETCH_BRANDS_BIN,
  FORCE_DELETE_BRAND,
} from './types'

export const fetchBrands = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/brands', config)

      dispatch({
          type: FETCH_BRANDS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch brands failed')
  }
}

export const createBrand = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/brands/create', formValues, config)

      dispatch({
          type: CREATE_BRAND,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_BRAND_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchBrand = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/brands/' + id, config)

      dispatch({
          type: FETCH_BRAND,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch brand failed')
  }
}

export const updateBrand = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/brands/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_BRAND,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_BRAND_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteBrand = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/brands/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_BRAND,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete brand failed')        
  }
}

export const fetchBrandsBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/brands/bin', config)

      dispatch({
          type: FETCH_BRANDS_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch brands bin failed')
  }
}

export const restoreBrand = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/brands/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_BRAND,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Brand restore failed')
  }
}

export const forceDeleteBrand = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/brands/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_BRAND,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete brand failed')        
  }
}