import admin from '../apis/admin'

import {
  FETCH_COUPONS,
  CREATE_COUPON,
  CREATE_COUPON_FAILED,
  FETCH_COUPON,
  UPDATE_COUPON,
  UPDATE_COUPON_FAILED,
  DELETE_COUPON,
  RESTORE_COUPON,
  FETCH_COUPONS_BIN,
  FORCE_DELETE_COUPON,
} from './types'

export const fetchCoupons = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/coupons', config)

      dispatch({
          type: FETCH_COUPONS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch coupons failed')
  }
}

export const createCoupon = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/coupons/create', formValues, config)

      console.log(response)

      dispatch({
          type: CREATE_COUPON,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_COUPON_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchCoupon = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/coupons/' + id, config)

      dispatch({
          type: FETCH_COUPON,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch coupon failed')
  }
}

export const updateCoupon = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/coupons/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_COUPON,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_COUPON_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteCoupon = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/coupons/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_COUPON,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete coupon failed')        
  }
}

export const fetchCouponsBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/coupons/bin', config)

      dispatch({
          type: FETCH_COUPONS_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch coupons bin failed')
  }
}

export const restoreCoupon = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/coupons/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_COUPON,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Coupon restore failed')
  }
}

export const forceDeleteCoupon = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/coupons/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_COUPON,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete coupon failed')        
  }
}