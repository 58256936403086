import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select,
    TextArea,
    Checkbox2
} from './fields'
import {Redirect} from 'react-router-dom'
import { AcceptTermsLink } from '../views/TermsLink'
import {formatPrice} from '../../utils'
import scrollToFirstError from './SmoothScrollToErrorFields.js'

import paymentImg1 from '../../assets/img/Icons/iconmonstr-payment-25.svg'
import paymentImg2 from '../../assets/img/Icons/iconmonstr-payment-26.svg'
import paymentImg3 from '../../assets/img/Icons/iconmonstr-payment-27.svg'
import paymentImg6 from '../../assets/img/Icons/peach-payments.svg'

class CheckoutForm extends React.Component {

    state = {
        createAccount: false,
        paymentMethod: null
    }

    onSelectPaymentMethod = e => {
        this.setState({
            paymentMethod: e.target.value
        })
    }

    renderCartItems = cartItems => {
        return cartItems.map(({id, quantity, title, total}) => {
            return (
                <tr key={id}>
                    <td className='_product-name' style={{paddingRight:'15px'}}>{title} X {quantity}</td>
                    <td className='_subtotal' style={{whiteSpace:'nowrap'}}>R {formatPrice(total)}</td>
                </tr>
            )
        })
    }

    toggleCreateAccount = () => {
        this.setState({createAccount: !this.state.createAccount})
    }

    renderErrors = () => {

        if(typeof this.props.errors === 'string'){
            return (
                <div className="_error-group">
                    <div className="_error-message">{this.props.errors}</div>
                </div>
            )
        }
        
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {

        const subtotal = this.props.cart.subTotalCost
        const delivery = this.props.cart.deliveryCost
        const discount = this.props.cart.discount
        const total = this.props.cart.totalCost
        const data = new FormData()
        formValues.name && data.append('billing_name', formValues.name)
        formValues.surname && data.append('billing_surname', formValues.surname)
        formValues.email && data.append('billing_email', formValues.email)
        formValues.billing_phone && data.append('billing_phone', formValues.billing_phone)
        formValues.billing_province && data.append('billing_province', formValues.billing_province)
        formValues.billing_city && data.append('billing_city', formValues.billing_city)
        formValues.billing_street_address && data.append('billing_address', formValues.billing_street_address)
        formValues.billing_postal_code && data.append('billing_postal_code', formValues.billing_postal_code)
        formValues.create_account && data.append('create_account', formValues.create_account)
        formValues.password && data.append('password', formValues.password)
        formValues.password_confirmation && data.append('password_confirmation', formValues.password_confirmation)
        formValues.comments && data.append('comments', formValues.comments)
        data.append('billing_subtotal', subtotal)
        data.append('billing_delivery_cost', delivery)
        data.append('billing_discount', discount)
        data.append('billing_total', total)
        data.append('cart_items', JSON.stringify(this.props.cart.items))
        data.append('variable_cart_items', JSON.stringify(this.props.cart.variableItems))
        this.props.onSubmit(data, this.state.paymentMethod)
    }

    render(){

        if(!this.props.cart.items && !this.props.cart.variableItems)
        return <Redirect to="/cart" />

        const cartItems = Object.values(this.props.cart.items)
        const variableCartItems = Object.values(this.props.cart.variableItems)

        if(cartItems.length === 0 && variableCartItems.length === 0)
        return <Redirect to="/cart" />

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h3>BILLING INFORMATION</h3>
                {'name' && <div name={`position-name`} />}
                {'surname' && <div name={`position-surname`} />}
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name*" />
                    <Field name="surname" type="text" component={Input} label="Surname*" />
                </div>
                {'email' && <div name={`position-email`} />}
                {'billing_phone' && <div name={`position-billing_phone`} />}
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address*" />
                    <Field name="billing_phone" type="text" component={Input} label="Phone Number*" />
                </div>
                {'billing_province' && <div name={`position-billing_province`} />}
                {'billing_city' && <div name={`position-billing_city`} />}
                <div className="_form-row">
                    <Field 
                        name="billing_province" 
                        label="Province*" 
                        component={Select} 
                    >
                        <option value="">Please select a province</option>
                        <option>Western Cape</option>
                        <option>Garden Route</option>
                        <option>Eastern Cape</option>
                        <option>Free State</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                    </Field>
                    <Field 
                        type="text"
                        name="billing_city" 
                        label="City / Town*" 
                        component={Input} 
                    />
                </div>
                {'billing_street_address' && <div name={`position-billing_street_address`} />}
                {'billing_postal_code' && <div name={`position-billing_postal_code`} />}
                <div className="_form-row">
                    <Field name="billing_street_address" type="text" component={Input} label="Billing Address*" />
                    <Field name="billing_postal_code" type="text" component={Input} label="Postal Code*" />
                </div>

                <div className="_form-row">
                    <Field
                        name="comments" 
                        label="Comments" 
                        component={TextArea} 
                    />
                </div>

                {
                    !this.props.auth.token && (
                        <>
                            <br />
                            <div className="_form-row">
                                <Field name="create_account" component={Checkbox2} label="Create Account" onChange={this.toggleCreateAccount} />
                            </div>

                            <div className={`_create-account ${this.state.createAccount ? '_show' : ''}`}>
                                <div className="_form-row">
                                    <Field name="password" type="password" component={Input} label="Create Account Password*" />
                                </div>
                                <div className="_form-row">
                                    <Field name="password_confirmation" type="password" component={Input} label="Confirm Password*" />
                                </div>
                            </div>
                        </>
                    )
                }
                            
                {/* <div className='_checkout-notice'>
                    <h3 className='_gold' style={{marginTop: '0'}}>NOTICE</h3>
                    <p>Thank you for considering Beauty Fires for your project. Submitting this quote request is not a purchase obligation, instead it helps us understand better what your specific needs are.</p>
                    <p>Fireplace and braai installations can be very complex but don’t worry, we have you covered. One of our specialists will be in touch with you very soon to discuss your specific installation requirements. Our specialist will need some pictures of the area where you want to install your fireplace.</p>
                    <p style={{marginBottom: '0'}}>Armed with this info we can get your quote into your inbox as soon as possible. We look forward to helping you create memories with family and friends.</p>
                </div> */}

                <div className='_checkout-order'>
                  <h3>YOUR ORDER</h3>
                  <table className='_table-items'>
                    <thead>
                      <tr>
                        <th>PRODUCT</th>
                        <th>TOTAL PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        {this.renderCartItems(cartItems)}
                        {this.renderCartItems(variableCartItems)}
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className='_total'>
                            <td>
                                <p>SUBTOTAL</p>
                            </td>
                            <td>
                                <p style={{whiteSpace:'nowrap'}}>R {formatPrice(this.props.cart.subTotalCost)}</p>
                            </td>
                        </tr>
                        {this.props.cart.discount !== 0 && (<>
                            <tr>
                                <td className='_shipping'><p>DISCOUNT</p></td>
                                <td className='_price' style={{whiteSpace:'nowrap'}}>R {formatPrice(this.props.cart.discount)}</td>
                            </tr>
                        </>)}
                        
                        <tr>
                            <td className='_shipping'><p>DELIVERY</p></td>
                            <td className='_price' style={{whiteSpace:'nowrap'}}>R {formatPrice(this.props.cart.deliveryCost)}</td>
                        </tr>
                        <tr className='_total'>
                            <td>
                                <p>TOTAL</p>
                            </td>
                            <td>
                                <p style={{whiteSpace:'nowrap'}}>R {formatPrice(this.props.cart.totalCost)}</p>
                            </td>
                        </tr>
                    </tbody>
                  </table>
                  <br />
                  {'accept_terms' && <div name={`position-accept_terms`} />}
                  <div className="_form-row">
                    <Field name="accept_terms" component={Checkbox2} label={(<AcceptTermsLink />)} />
                  </div>
                  <br /><br />

                  <h3>PLEASE SELECT PAYMENT METHOD</h3>

                  <div className="_form-row">
                        <div className="_form-group-checkbox _checkbox-2">
                            <div className="_checkbox-row">
                                <div className="_item">
                                    <label>
                                        <input 
                                            name="payment" 
                                            type="radio" 
                                            value="pay-with-card" 
                                            onChange={this.onSelectPaymentMethod} 
                                            checked={this.state.paymentMethod === 'pay-with-card' ? true : false}
                                        />
                                        <span className="_checkbox"></span>
                                    </label>
                                    <div className='_payment-method'><p>Credit or Debit Card</p>
                                        <div className='_payment-icons'>
                                            <img src={paymentImg2} alt="visa" />
                                            <img src={paymentImg3} alt="mastercard" />
                                            <img src={paymentImg1} alt="amex" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>

                  <div className="_form-row">
                        <div className="_form-group-checkbox _checkbox-2">
                            <div className="_checkbox-row">
                                <div className="_item">
                                    <label>
                                        <input 
                                            name="payment" 
                                            type="radio" 
                                            value="secure-eft" 
                                            onChange={this.onSelectPaymentMethod} 
                                            checked={this.state.paymentMethod === 'secure-eft' ? true : false}
                                        />
                                        <span className="_checkbox"></span>
                                    </label>
                                    <div className='_payment-method'><p>EFT Secure with </p>
                                        <div className='_payment-icons'>
                                            <img src={paymentImg6} alt="Peach Payments" style={{width: '80px'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                  
                  {
                      this.props.errors && (
                          <div className="_form-row">
                              {this.renderErrors()}
                          </div>
                      )
                  }
                </div>

                <div className="_form-group _place-order">
                    <button className={`_button _button-2 _button-submit _button-place-order ${this.state.paymentMethod ? '_show' : ''}`}>PLACE ORDER</button>
                </div>
                
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your name"
    }

    if(!formValues.surname){
        errors.surname = "You must enter your surname"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }
    if(!formValues.billing_phone){
        errors.billing_phone = "You must enter your phone number"
    }
    if(!formValues.billing_province){
        errors.billing_province = "You must select your province"
    }
    if(!formValues.billing_city){
        errors.billing_city = "You must enter your city"
    }
    if(!formValues.billing_street_address){
        errors.billing_street_address = "You must enter your address"
    }
    if(!formValues.billing_postal_code){
        errors.billing_postal_code = "You must enter your postal code"
    }
    if(!formValues.accept_terms){
        errors.accept_terms = "You must accept our terms and conditions"
    }

    return errors
}

export default reduxForm({
    form: 'checkout',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(CheckoutForm)