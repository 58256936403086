import admin from '../apis/admin'

import {
  FETCH_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS_FAILES,
} from './types'

export const fetchAccountDetails = token => async dispatch => {
  try {

      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/account', config)

      dispatch({
          type: FETCH_ACCOUNT_DETAILS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch account details failed')
  }
}

export const updateAccountDetails = (token, formValues) => async dispatch => {
  try {

      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/account/update', formValues, config)

      dispatch({
          type: UPDATE_ACCOUNT_DETAILS,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_ACCOUNT_DETAILS_FAILES,
          payload: error.response ? error.response.data : null
      })
  }
}