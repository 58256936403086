import React from 'react'
import {connect} from 'react-redux'
import {
    customerLogout
} from '../../actions'
import {Link, Redirect} from 'react-router-dom'

class AccountLayout extends React.Component {

  Logout = e => {
    e.preventDefault()
    this.props.customerLogout(this.props.auth.token)
  }

  render(){

    if(!this.props.auth.token)
    return <Redirect to="/account" />

    return (
      <section className='_section _section-my-account'>
        <div className='_grid'>
          <div className='_column'>
            <div className='_flex-box'>
              <nav className='_customer-account-dashboard-nav'>
                <ul>
                  <li>
                    <Link to="/account/dashboard">Dashboard</Link>
                  </li>
                  <li><div className='_border' /></li>
                  <li>
                    <Link to="/account/my-orders">My Orders</Link>
                  </li>
                  <li><div className='_border' /></li>
                  <li>
                    <Link to="/account/billing">Billing Details</Link>
                  </li>
                  <li><div className='_border' /></li>
                  <li>
                    <Link to="/account/details">Account Details</Link>
                  </li>
                  <li><div className='_border' /></li>
                  <li>
                    <a href="/" onClick={e => this.Logout(e)}>Logout</a>
                  </li>
                </ul>
              </nav>
              <div className='_customer-account-dashboard-content'>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.customerAuthState
  }
}

export default connect(mapStateToProps, {
  customerLogout
})(AccountLayout)