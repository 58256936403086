import React from 'react'
import Layout from '../layout/Layout'
import Seo from '../../Seo'
import ScrollToTop from '../../ScrollToTop'
import {connect} from 'react-redux'
import {
  fetchCustomerAccountDetails
} from '../../actions'
import Breadcrums from '../ui/Breadcrums'
import SimpleHeading from '../ui/SimpleHeading'
import {Link, Redirect} from 'react-router-dom'
import AccountLayout from './AccountLayout'

class AccountDashboard extends React.Component {

  componentDidMount(){
    this.props.fetchCustomerAccountDetails(this.props.auth.token)
  }

  renderContent = () => {
    if(!this.props.details)
    return <></>

    const {name, surname} = this.props.details

    return (
      <div className='_details'>
        <p> Welcome <strong>{name} {surname ? surname : ''}</strong></p>
        <p>From your account dashboard you can view your <Link to="/account/dashboard/my-orders">orders</Link>, and edit your <Link to="/account/billing">billing</Link> and <Link to="/account/details">account details</Link>.</p>
      </div>
    )
  }

  render(){

    if(!this.props.auth.token)
    return (
      <Redirect to="/account" />
    )

    

    return (
      <>
        <Seo
          title="My Account"
          description={null}
          image={null}
        />
        <ScrollToTop />
        <Layout>
          <Breadcrums>
            <Link to="/" rel="index up up up">Home</Link>&nbsp;/&nbsp;
            <Link to="/account/dashboard" rel="index up up up">my Account</Link> / Dashboard
          </Breadcrums>
          <SimpleHeading>My Account</SimpleHeading>
          <AccountLayout>
            {this.renderContent()}
          </AccountLayout>          
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.customerAuthState,
      details: state.customerAccountState.details
  }
}

export default connect(mapStateToProps, {
  fetchCustomerAccountDetails
})(AccountDashboard)