import facebook from '../../assets/img/Icons/facebook-icon.svg'
import instagram from '../../assets/img/Icons/instagram-icon.svg'

const SocialIcons = () => {
  return(
    <div className='_social-icons'>
      <a href="https://www.facebook.com/beautyfiressa/" target="_blank" rel="noreferrer" style={{marginRight: '15px'}}>
        <img src={facebook} alt="f" width="30" height="30" />
      </a>
      <a href="https://www.instagram.com/beautyfiressa/" target="_blank" rel="noreferrer">
        <img src={instagram} alt="i" width="30" height="30" />
      </a>
    </div>
  )
}

export default SocialIcons