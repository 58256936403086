import admin from '../apis/admin'

import {
    FETCH_DELIVERY_OPTIONS,
    CREATE_DELIVERY_OPTION,
    CREATE_DELIVERY_OPTION_FAILED,
    FETCH_DELIVERY_OPTION,
    UPDATE_DELIVERY_OPTION,
    UPDATE_DELIVERY_OPTION_FAILED,
    DELETE_DELIVERY_OPTION,
    RESTORE_DELIVERY_OPTION,
    FETCH_DELIVERY_OPTIONS_BIN,
    FORCE_DELETE_DELIVERY_OPTION,
} from './types'

export const fetchDeliveryOptions = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/delivery-options', config)

      dispatch({
          type: FETCH_DELIVERY_OPTIONS,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch delivery options failed')
  }
}

export const createDeliveryOption = (token, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/delivery-options/create', formValues, config)

      console.log(response)

      dispatch({
          type: CREATE_DELIVERY_OPTION,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: CREATE_DELIVERY_OPTION_FAILED,
          payload: error.response ? error.response.data : null
      })
  }
}

export const fetchDeliveryOption = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/delivery-options/' + id, config)

      dispatch({
          type: FETCH_DELIVERY_OPTION,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Fetch delivery option failed')
  }
}

export const updateDeliveryOption = (token, id, formValues) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/delivery-options/' + id + '/update', formValues, config)

      dispatch({
          type: UPDATE_DELIVERY_OPTION,
          payload: response.data
      })
      
  } catch (error) {
      dispatch({
          type: UPDATE_DELIVERY_OPTION_FAILED,
          payload: error.response ? error.response.data : null
      })
      
  }
}

export const deleteDeliveryOption = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/delivery-options/' + id + '/delete', {}, config)

      dispatch({
          type: DELETE_DELIVERY_OPTION,
          payload: id
      })
      
  } catch (error) {
      console.log('Delete delivery option failed')        
  }
}

export const fetchDeliveryOptionsBin = token => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.get('admin/delivery-options/bin', config)

      dispatch({
          type: FETCH_DELIVERY_OPTIONS_BIN,
          payload: response.data
      })
      
  } catch (error) {
      console.log('fetch delivery options bin failed')
  }
}

export const restoreDeliveryOption = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      const response = await admin.post('admin/delivery-options/'+id+'/restore', {}, config)

      dispatch({
          type: RESTORE_DELIVERY_OPTION,
          payload: response.data
      })
      
  } catch (error) {
      console.log('Delivery option restore failed')
  }
}

export const forceDeleteDeliveryOption = (token, id) => async dispatch => {
  try {
      const config = { 
          headers: { 
              Authorization: `Bearer ${token}` 
          } 
      };
      
      await admin.post('admin/delivery-options/' + id + '/delete', {}, config)

      dispatch({
          type: FORCE_DELETE_DELIVERY_OPTION,
          payload: id
      })
      
  } catch (error) {
      console.log('Force delete delivery option failed')        
  }
}